import { FC, Fragment, PropsWithChildren, useEffect, useMemo, useRef } from 'react';
import classnames from 'classnames';

import buttonClick from '@hh.ru/analytics-js-events/build/xhh/common/main/button_click';
import {
    Button,
    Avatar,
    Cell,
    CellText,
    Link as MagritteLink,
    VSpacing as MagritteVSpacing,
    VSpacingContainer,
    Card,
    Title,
    useBreakpoint,
} from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import Column from 'bloko/blocks/column';
import Gap from 'bloko/blocks/gap';
import { H2 } from 'bloko/blocks/header';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import useFeatureEnabled from 'src/hooks/useFeatureEnabled';
import { useMagritte } from 'src/hooks/useMagritte';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { ArticleItemWithImage } from 'src/models/article.types';

import NewsBoxItem from 'src/components/NewsBox/NewsBoxItem';
import newsBoxAnalytics from 'src/components/NewsBox/analytics';

import styles from './news-box.less';

interface NewsBoxProps {
    items: ArticleItemWithImage[] | null;
    href: string;
    title: string;
    withSeparator?: boolean;
    isArticle?: boolean;
    isEmployerContext?: boolean;
    inDashboard?: boolean;
    gapTop?: boolean;
    view?: 'cell';
}

const TrlKeys = {
    viewAll: 'index.newsBox.viewAll',
};

const Features = {
    employerMainPageAnalyticsEnabled: 'employer_main_page_analytics_enabled',
};

const ColumnWrapper: FC<PropsWithChildren> = ({ children }) => (
    <Column xs="4" s="4" m="4" l="4">
        {children}
    </Column>
);

const NewsBox: TranslatedComponent<NewsBoxProps & PropsWithChildren> = ({
    title,
    href,
    items,
    withSeparator = false,
    isArticle = false,
    isEmployerContext = true,
    inDashboard = false,
    gapTop = false,
    view,
    trls,
}) => {
    const employerMainPageAnalyticsEnabled = useFeatureEnabled(Features.employerMainPageAnalyticsEnabled);
    const newsBoxRef = useRef(null);
    const isZp = useIsZarplataPlatform();
    const newsBoxItems = useMemo(() => items || [], [items]);
    const Wrapper = inDashboard || isZp ? Fragment : ColumnWrapper;
    const isAnonymousMagritteExp = useSelector((state) => state.isAnonymousMagritteExp);
    const isMagritte = useMagritte() || isAnonymousMagritteExp;
    const newsBoxType = isArticle ? 'article' : 'news';
    const { isMobile } = useBreakpoint();

    useEffect(() => {
        if (employerMainPageAnalyticsEnabled && newsBoxItems?.length && newsBoxRef.current) {
            newsBoxAnalytics.sendNewsBoxItemsShown(
                newsBoxRef.current,
                newsBoxType,
                newsBoxItems.map((item) => item.title)
            );
        }
    }, [employerMainPageAnalyticsEnabled, isArticle, newsBoxItems, newsBoxType]);

    const handleClick = () => {
        if (employerMainPageAnalyticsEnabled) {
            newsBoxAnalytics.sendNewsBoxTitleClick(newsBoxType);
        }
        buttonClick({ buttonName: isArticle ? 'article_list' : 'news_list' });
    };

    if (view === 'cell') {
        return (
            <div ref={newsBoxRef}>
                <MagritteLink
                    Element={SPALink}
                    to={href}
                    onClick={handleClick}
                    typography="custom-1-semibold"
                    style="neutral"
                >
                    {title}
                </MagritteLink>

                <VSpacing base={6} />

                <VSpacingContainer default={24}>
                    {items?.map(({ title, href, image }) => {
                        return (
                            <SPALink to={href} key={title}>
                                <Cell
                                    avatar={
                                        <Avatar
                                            mode="image"
                                            size={48}
                                            aria-label={title}
                                            Element="div"
                                            image={image}
                                            placeholder="city"
                                            fallbackMode="placeholder"
                                            onClick={() =>
                                                buttonClick({ buttonName: isArticle ? 'article_item' : 'news_item' })
                                            }
                                        />
                                    }
                                >
                                    <CellText maxLines={2}>{title}</CellText>
                                </Cell>
                            </SPALink>
                        );
                    })}
                </VSpacingContainer>
            </div>
        );
    }

    if (isMagritte) {
        return (
            <Card
                padding={isMobile ? 0 : 12}
                borderRadius={24}
                borderWidth={!isMobile ? 'default' : 'none'}
                stretched
                verticalStretched
            >
                <div className={styles.wrapper} ref={newsBoxRef}>
                    <div className={styles.inner}>
                        <Title Element="h3" size="small">
                            {title}
                        </Title>
                        <MagritteVSpacing default={24} xs={16} s={16} m={24} />
                        <VSpacingContainer default={24} xs={12} s={12} m={24}>
                            {items?.map(({ title, href, image }) => (
                                <NewsBoxItem
                                    key={title}
                                    title={title}
                                    href={href}
                                    image={image}
                                    isArticle={isArticle}
                                />
                            ))}
                        </VSpacingContainer>
                    </div>
                    <MagritteVSpacing default={24} xs={16} s={16} m={24} />
                    <Button
                        Element={SPALink}
                        to={href}
                        isSeoLink
                        onClick={handleClick}
                        mode="secondary"
                        style="accent"
                        stretched
                    >
                        {trls[TrlKeys.viewAll]}
                    </Button>
                </div>
            </Card>
        );
    }

    return (
        <Wrapper>
            <Gap top={gapTop} xsTop={inDashboard} sTop={inDashboard}>
                <div
                    ref={newsBoxRef}
                    className={classnames('index-news-box', {
                        'index-news-box_article': isArticle,
                        'index-news-box_employer': isEmployerContext,
                        'index-news-box_dashboard': inDashboard,
                        'index-news-box_dashboard-separator': inDashboard && withSeparator,
                    })}
                >
                    <div className="index-news-box__header">
                        <H2 Element="h3">
                            <BlokoLink Element={SPALink} to={href} kind={LinkKind.Tertiary} onClick={handleClick}>
                                {title}
                            </BlokoLink>
                        </H2>
                        <VSpacing base={4} />
                    </div>
                    <ul>
                        {items?.map(({ title, href, image }) => (
                            <NewsBoxItem key={title} title={title} href={href} image={image} isArticle={isArticle} />
                        ))}
                    </ul>
                    {!inDashboard && withSeparator && (
                        <div className="index-news-box__separator">
                            <div className="separator" />
                        </div>
                    )}
                </div>
            </Gap>
        </Wrapper>
    );
};

export default translation(NewsBox);
