import Analytics, { ElementShownAnchor } from '@hh.ru/analytics-js';
import { TranslatedComponent } from '@hh.ru/front-static-app';
import { Button, Card, Text, VSpacing } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';

import translation from 'src/components/translation';

import styles from './styles.less';

const VacancyStatBanner: TranslatedComponent<{ managersCount: number }> = ({ managersCount }) => {
    const link =
        `/employer/vacancies?${managersCount > 1 ? 'groupId=all&' : ''}` +
        'utm_source=hh.ru&utm_medium=referral&utm_campaign=banner_main_health&utm_content=onboarding_tab_stat';

    return (
        <Card verticalStretched stretched borderWidth="default" borderRadius={24} padding={12}>
            <ElementShownAnchor
                className={styles.content}
                fn={(element) =>
                    Analytics.sendHHEventElementShown(element, {
                        name: 'vacancy_stat_banner',
                    })
                }
            >
                <div className={styles.banner}>
                    <div>
                        <Text typography="subtitle-1-semibold">Здоровье вакансии</Text>
                        <VSpacing default={8} />
                        <Text typography="label-3-regular">
                            Проверьте свою вакансию и&nbsp;получите советы по&nbsp;улучшению
                        </Text>
                        <VSpacing default={24} />
                    </div>
                    <div className={styles.bannerArt} />
                </div>
                <Button
                    Element={SPALink}
                    to={link}
                    onClick={() => Analytics.sendHHEventButtonClick('vacancy_stat_banner_click')}
                    size="small"
                    stretched
                    mode="secondary"
                    data-qa="vacancy-stat-banner-action"
                    style="neutral"
                >
                    Проверить
                </Button>
            </ElementShownAnchor>
        </Card>
    );
};

export default translation(VacancyStatBanner);
