import { FC } from 'react';

import vacancyCatalogItemLinkButtonClick from '@hh.ru/analytics-js-events/build/xhh/common/main/vacancy_catalog_item_link_button_click';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';
import Text from 'bloko/blocks/text';

import { getProfessionLink } from 'src/components/RainbowCatalog/utils';
import translation from 'src/components/translation';
import { ProfessionsListProfession } from 'src/models/professionsList';

interface RainbowDropProfessionProps {
    professions: ProfessionsListProfession[];
    parentProfession: ProfessionsListProfession;
}

const RainbowDropProfession: FC<RainbowDropProfessionProps> = ({ professions, parentProfession }) => {
    return (
        <ul className="multiple-column-list multiple-column-list_wide">
            {[parentProfession, ...professions].map((profession, index) => {
                return (
                    <li className="multiple-column-list-item" key={profession.id}>
                        <Text>
                            <BlokoLink
                                Element={SPALink}
                                to={getProfessionLink(parentProfession, profession)}
                                additionalQueryParams={{ hhtmFromLabel: 'rainbow_profession' }}
                                kind={LinkKind.Tertiary}
                                onClick={() =>
                                    vacancyCatalogItemLinkButtonClick({ value: profession.name, position: index })
                                }
                            >
                                <div className="easy-tap-link easy-tap-link_with-arrow">
                                    <div>{profession.name}</div>
                                    <div className="easy-tap-link-number">{profession.count}</div>
                                </div>
                            </BlokoLink>
                        </Text>
                    </li>
                );
            })}
        </ul>
    );
};

export default translation(RainbowDropProfession);
