import { useMemo } from 'react';

import { useExperiment } from '@hh.ru/front-static-app';

import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { useIsRabotaBy } from 'src/hooks/useSites';
import { UserType } from 'src/models/userType';

const LINK_AGREEMENT = {
    HH: 'https://hh.ru/account/agreement',
    ZARPLATA: 'https://hhcdn.ru/icms/10319217.pdf',
};

export function useIsAgeWarning(): boolean {
    const isApplicant = useSelector((state) => state.userType === UserType.Applicant);
    const isRabotaBy = useIsRabotaBy();

    const isAgeWarning = isApplicant && !isRabotaBy;

    return useExperiment('web_warning_under_18', isAgeWarning);
}

export function useAgreementLink(): string {
    const isZarpalta = useIsZarplataPlatform();

    return useMemo(() => {
        if (isZarpalta) {
            return LINK_AGREEMENT.ZARPLATA;
        }

        return LINK_AGREEMENT.HH;
    }, [isZarpalta]);
}
