import { VSpacing, Text, Tag } from '@hh.ru/magritte-ui';

import translation from 'src/components/translation';

export const VacancyStatusKind = {
    positive: 'positive',
    warning: 'warning',
    negative: 'negative',
};

const AbstractVacancy = ({ trls, children, name, description, statusKind, tagText, statusText, narrow = false }) => {
    return (
        <>
            <Text typography="title-3-semibold" data-qa="dashboard-vacancy-header">
                {name || trls[AbstractVacancy.trls.vacancyNameStub]}
            </Text>
            {description && (
                <>
                    <VSpacing default={narrow ? 16 : 24} />
                    <Text typography="label-2-regular">{description}</Text>
                    <VSpacing default={24} />
                </>
            )}
            {tagText && (
                <>
                    <VSpacing default={8} />
                    <Tag style={statusKind} size="large">
                        <span data-qa="dashboard-vacancy-tag" suppressHydrationWarning>
                            {tagText}
                        </span>
                    </Tag>
                    <VSpacing default={32} />
                </>
            )}
            {statusText && (
                <>
                    <VSpacing default={8} />
                    <span suppressHydrationWarning>{statusText}</span>
                    <VSpacing default={32} />
                </>
            )}
            {children}
        </>
    );
};

AbstractVacancy.trls = {
    vacancyNameLabel: 'newemployer.dashboard.vacancy.name.label',
    vacancyNameStub: 'newemployer.dashboard.vacancy.name.stub',
};

export default translation(AbstractVacancy);
