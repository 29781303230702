import { useContext, PropsWithChildren, useRef } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { ChatikContext } from '@hh.ru/chatik-integration';
import {
    Avatar,
    Card,
    Cell,
    CellText,
    Link as MagritteLink,
    Text,
    TooltipHover,
    VSpacing as MagritteVSpacing,
    useMultipleRefs,
    Button,
    useBreakpoint,
} from '@hh.ru/magritte-ui';
import { BubbleSquareFilledSize16, CheckCircleOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Compensation from 'src/components/Compensation';
import { getVacancyCompanyLink } from 'src/components/RainbowCatalog/utils';
import { getVacancyLink } from 'src/components/VacanciesOfTheDay/Utils';
import { sendAnalytics } from 'src/components/VacanciesOfTheDay/analytics';
import CompanyLink from 'src/components/VacancyCompanyLink';
import VacancyResponseLink from 'src/components/VacancyResponseLink';
import VacancyResponsePopup from 'src/components/VacancyResponsePopup';
import CountriesProfileVisibilityAgreementPopup from 'src/components/VacancyResponsePopup/CountriesProfileVisibilityAgreementPopup';
import RelocationWarningPopup from 'src/components/VacancyResponsePopup/RelocationWarningPopup';
import ContactsButton from 'src/components/VacancySearchItem/ShowContactsButton';
import Source from 'src/components/VacancySearchItem/types/Source';
import translation from 'src/components/translation';
import useIsTextClamped from 'src/hooks/useIsTextClamped';
import { useMagritte } from 'src/hooks/useMagritte';
import { useSelector } from 'src/hooks/useSelector';
import NegotiationTopic from 'src/models/negotiationTopic.types';
import { VacancyOfTheDay as IVacancyOfTheDay } from 'src/models/vacanciesOfTheDay';
import { VacancyOfTheDayLocation } from 'src/utils/sendAdvSpyAnalytics';

import styles from './vacancy-of-the-day.less';

interface VacancyOfTheDayProps {
    vacancy: IVacancyOfTheDay;
    sourceQueryParam: string;
    hhtmFromLabel: string;
    isRecommended?: boolean;
    setVacancyRef?: (ref: HTMLElement | null) => void;
    topics?: NegotiationTopic[];
}

const TrlKeys = {
    response: 'vacancy.of.the.day.response',
    applied: 'vacancy.of.the.day.applied',
};

const VacancyOfTheDay: TranslatedComponent<VacancyOfTheDayProps & PropsWithChildren> = ({
    vacancy,
    sourceQueryParam,
    hhtmFromLabel,
    isRecommended = false,
    setVacancyRef,
    trls,
    topics,
}) => {
    const hhtmSource = useSelector((state) => state.analyticsParams.hhtmSource);
    const nameRef = useRef(null);
    const companyRef = useRef(null);
    const isNameClamped = useIsTextClamped(nameRef);
    const isCompanyClamped = useIsTextClamped(companyRef);
    const vacancyRef = useRef<HTMLDivElement>(null);
    const vacancyRefCallback = useMultipleRefs(vacancyRef, setVacancyRef);
    const { vacancyId, company, showContact } = vacancy;
    const { isXS } = useBreakpoint();
    const openChatik = useContext(ChatikContext)?.openChatik;
    const isMagritte = useMagritte();
    const userLabels = useSelector((state) => state.userLabelsForVacancies);

    const renderResponseButtons = () => (
        <>
            <MagritteVSpacing default={12} />
            <div className={styles.responseButtons}>
                {topics ||
                (!!userLabels[vacancy.vacancyId] &&
                    userLabels[vacancy.vacancyId].some((label) =>
                        ['RESPONSE', 'INVITE', 'DISCARD'].includes(label)
                    )) ? (
                    <>
                        <Button mode="secondary" size="small" style="accent" disabled stretched>
                            <span className={styles.appliedButton}>
                                <CheckCircleOutlinedSize16 initialColor="secondary" />
                                {trls[TrlKeys.applied]}
                            </span>
                        </Button>
                        {/* Кнопку чата показываем только если отклик рассмотрен. */}
                        {topics && topics[0]?.lastState !== 'RESPONSE' && (
                            <Button
                                mode="secondary"
                                size="small"
                                style="accent"
                                hideLabel
                                icon={<BubbleSquareFilledSize16 />}
                                aria-label="open-chat"
                                onClick={() => {
                                    Analytics.sendHHEventButtonClick('contactChat', {
                                        withResponse: false,
                                    });
                                    openChatik?.({ chatId: topics[0]?.chatId, view: 'compact' });
                                }}
                            />
                        )}
                    </>
                ) : (
                    <>
                        <VacancyResponseLink
                            vacancyId={vacancy.vacancyId}
                            employerId={company.id}
                            render={(onClick) => (
                                <Button
                                    mode="primary"
                                    size="small"
                                    style="accent"
                                    Element={SPALink}
                                    to={`/applicant/vacancy_response?vacancyId=${vacancy.vacancyId}&employerId=${company.id}`}
                                    data-qa="vacancy-response-link-top-again"
                                    onClick={onClick}
                                    stretched
                                >
                                    {trls[TrlKeys.response]}
                                </Button>
                            )}
                            place="vacancy_top_button"
                        />
                        {showContact && (
                            <ContactsButton
                                showContact={true}
                                vacancyId={vacancyId}
                                employerId={company.id}
                                companyName={company.visibleName}
                                chatWritePossibility={vacancy.chatWritePossibility}
                                magritteButtonSize="small"
                            />
                        )}
                    </>
                )}
            </div>
            <VacancyResponsePopup vacancyId={vacancy.vacancyId} vacancySource={Source.VacanciesOfTheDay} isXs={isXS} />
            <RelocationWarningPopup vacancyId={vacancy.vacancyId} />
            <CountriesProfileVisibilityAgreementPopup vacancyId={vacancy.vacancyId} />
        </>
    );

    if (isMagritte) {
        return (
            <Card borderWidth="default" padding={12} borderRadius={24} stretched verticalStretched>
                <div className={styles.wrapper}>
                    <div className={styles.info}>
                        <MagritteLink
                            style="neutral"
                            Element={SPALink}
                            to={getVacancyLink(vacancy, VacancyOfTheDayLocation.MainPageBottom, isRecommended)}
                            target="_blank"
                            additionalQueryParams={{ source: sourceQueryParam, from: hhtmSource, hhtmFromLabel }}
                            onClick={() => sendAnalytics(vacancy.vacancyId, 'vacancy_of_the_day')}
                        >
                            <div ref={vacancyRefCallback}>
                                <Text typography="label-2-regular" data-qa="vacancy_of_the_day_title" maxLines={2}>
                                    <div ref={nameRef}>{vacancy.name}</div>
                                </Text>
                            </div>
                            {isNameClamped && (
                                <TooltipHover placement="top-center" activatorRef={vacancyRef}>
                                    {vacancy.name}
                                </TooltipHover>
                            )}
                            <MagritteVSpacing default={8} />
                            <Text typography="subtitle-1-semibold" data-qa="vacancy_of_the_day_compensation">
                                <Compensation
                                    {...vacancy.compensation}
                                    analyticsContext="VacancyOfTheDay1"
                                    showNoSalaryMessage
                                />
                            </Text>
                        </MagritteLink>
                        <>
                            <MagritteVSpacing default={24} />
                            <MagritteLink
                                style="neutral"
                                Element={SPALink}
                                to={getVacancyCompanyLink(vacancy)}
                                target="_blank"
                            >
                                <Cell
                                    left={
                                        <Avatar
                                            shapeCircle
                                            size={40}
                                            mode="image"
                                            fallbackMode="placeholder"
                                            image={vacancy.company.logoUrl || ''}
                                            placeholder="city"
                                            aria-label=""
                                        />
                                    }
                                    data-qa="vacancy_of_the_day_company"
                                >
                                    <CellText maxLines={1}>
                                        <div ref={companyRef}>{vacancy.company.visibleName}</div>
                                        {isCompanyClamped && (
                                            <TooltipHover placement="top-center" activatorRef={companyRef}>
                                                {vacancy.company.visibleName}
                                            </TooltipHover>
                                        )}
                                    </CellText>
                                </Cell>
                            </MagritteLink>
                        </>
                    </div>
                    {renderResponseButtons()}
                </div>
            </Card>
        );
    }

    return (
        <div className="vacancy-of-the-day">
            <BlokoLink
                kind={LinkKind.Tertiary}
                Element={SPALink}
                to={getVacancyLink(vacancy, VacancyOfTheDayLocation.MainPageBottom, isRecommended)}
                additionalQueryParams={{ source: sourceQueryParam, from: hhtmSource, hhtmFromLabel }}
                target="_blank"
                onClick={() => sendAnalytics(vacancy.vacancyId, 'vacancy_of_the_day')}
            >
                <span className="vacancy-of-the-day__title" data-qa="vacancy_of_the_day_title" ref={setVacancyRef}>
                    {vacancy.name}
                </span>
                <span className="vacancy-of-the-day__salary" data-qa="vacancy_of_the_day_compensation">
                    <Compensation {...vacancy.compensation} analyticsContext="VacancyOfTheDay2" showNoSalaryMessage />
                </span>
            </BlokoLink>
            {vacancy.company.id && (
                <span className="vacancy-of-the-day__company" data-qa="vacancy_of_the_day_company">
                    <CompanyLink
                        vacancy={vacancy}
                    >{`${vacancy.company.visibleName}, ${vacancy.area.name}`}</CompanyLink>
                </span>
            )}
        </div>
    );
};

export default translation(VacancyOfTheDay);
