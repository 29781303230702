import differenceInYears from 'date-fns/differenceInYears';
import parse from 'date-fns/parse';

import LocalStorageWrapper from 'bloko/common/storage/LocalStorageWrapper';

const LOCAL_STORAGE = {
    KEY: 'legal_age_under_18_warning_seen',
    VALUE: 'true',
};

const AGE_LIMIT = 18;

const DATE_FORMAT = 'yyyy-MM-dd';

export function isAgeBelowLimit(date: Date | undefined, limit = AGE_LIMIT): boolean {
    if (!date) {
        return false;
    }

    const present = new Date();

    return date < present && differenceInYears(present, date) < limit;
}

export function isAgeWarningSeen(): boolean {
    return LocalStorageWrapper.getItem(LOCAL_STORAGE.KEY) === LOCAL_STORAGE.VALUE;
}

export function markAgeWarningSeen(): void {
    LocalStorageWrapper.setItem(LOCAL_STORAGE.KEY, LOCAL_STORAGE.VALUE);
}

export function parseDateInFormat(dateString: string, format: string = DATE_FORMAT): Date {
    return parse(dateString, format, new Date());
}
