import { PropsWithChildren, RefObject, useLayoutEffect, useRef } from 'react';

import { useCollapsible } from '@hh.ru/magritte-ui';

interface CollapsibleProps {
    expanded?: boolean;
    deps?: unknown[];
    collapseCallback?: (ref: RefObject<HTMLDivElement> | null, expanded: boolean) => void;
}

const Collapsible: React.FC<PropsWithChildren<CollapsibleProps>> = ({
    children,
    expanded = false,
    deps,
    collapseCallback,
}) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const { collapsibleClasses } = useCollapsible(ref, expanded, deps);

    useLayoutEffect(() => {
        if (ref?.current && collapseCallback) {
            collapseCallback(ref, expanded);
        }
    }, [collapseCallback, expanded, collapsibleClasses]);

    return (
        <div ref={ref} data-qa="collapsible" className={collapsibleClasses}>
            {children}
        </div>
    );
};

export default Collapsible;
