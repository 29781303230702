import { useCallback } from 'react';

import Analytics from '@hh.ru/analytics-js';

import { EmployerBannerName } from 'src/models/employerBannersWidget';

export const useHandleAnalyticsCardClick = (): ((name: EmployerBannerName) => void) => {
    return useCallback((name) => {
        Analytics.sendHHEventButtonClick('banners_widget_card', { card: name });
    }, []);
};
