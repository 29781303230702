import { VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useHandleSeenClick } from 'src/components/EmployerBannersWidget/BannerCard/useHandleSeenClick';
import FlippableBannerQrCode from 'src/components/EmployerBannersWidget/FlippableBannerQrCode';
import ServiceCardIcon from 'src/components/EmployerBannersWidget/ServiceCardIcon';
import ServiceName from 'src/components/EmployerBannersWidget/ServiceName';
import translation from 'src/components/translation';
import { EmployerBannerItem } from 'src/models/employerBannersWidget';

import styles from './styles.less';

const TrlKeys = {
    text: 'employer.index.bannersWidget.rabotaByMobileApp.text',
    title: 'employer.index.bannersWidget.rabotaByMobileApp.title',
};

const RABOTA_BY_QR = '/images/rabotaby-employer-qr-code.svg';

const MobileAppBanner: TranslatedComponent<EmployerBannerItem> = ({ trls, name }) => {
    const handleSeen = useHandleSeenClick(name);

    return (
        <FlippableBannerQrCode name={name} onClick={handleSeen} qrCodeImgPath={RABOTA_BY_QR}>
            <ServiceName icon={<ServiceCardIcon name="rabotaby" />}>{trls[TrlKeys.title]}</ServiceName>
            <VSpacing default={12} />
            {trls[TrlKeys.text]}
            <div className={styles.bgImage} />
        </FlippableBannerQrCode>
    );
};

export default translation(MobileAppBanner);
