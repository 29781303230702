import addHours from 'date-fns/addHours';
import parseISO from 'date-fns/parseISO';

import { Button, HSpacing, VSpacing, Text, useBreakpoint } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { formatToReactComponent, format } from 'bloko/common/trl';

import formatDate from 'Modules/formatDate';
import translation from 'src/components/translation';
import usePropertiesBundle from 'src/hooks/usePropertiesBundle';
import { useSelector } from 'src/hooks/useSelector';

import AbstractVacancy from 'src/components/IndexPageNewEmployer/components/DashboardVacancy/AbstractVacancy';

import styles from './dashboard-vacancy.less';

const ScheduledPublication = ({ trls, data }) => {
    const {
        availableDraft: { name, requiredPublications },
        draftScheduledPublication,
        assignedEmployerManagerId,
    } = data;

    const date = parseISO(draftScheduledPublication.isoDateTimePublication);

    const employerManagerId = useSelector((state) => state.employerManager?.id);
    const publicationManager = useSelector((state) =>
        state.employerManagersList.find(
            ({ id }) => id === String(assignedEmployerManagerId) && id !== String(employerManagerId)
        )
    );
    const propertiesBundle = usePropertiesBundle();
    const { isXS } = useBreakpoint();

    return (
        <AbstractVacancy
            name={name}
            statusText={
                <Text data-qa="dashboard-vacancy-status-scheduled" typography="subtitle-1-semibold" style="secondary">
                    {format(trls[ScheduledPublication.trls.publicationTime], {
                        '{manager}': publicationManager
                            ? format(trls[ScheduledPublication.trls.manager], {
                                  '{managerName}': publicationManager.text,
                              })
                            : '',
                        '{day}': trls[ScheduledPublication.trls.weekdays[date.getDay()]],
                        '{startHour}': formatDate(date, 'HH:mm'),
                        '{endHour}': formatDate(addHours(date, 1), 'HH:mm'),
                        '{gmt}': `+${parseInt(draftScheduledPublication.gmt.slice(1, 3), 10)}`,
                    })}
                </Text>
            }
        >
            <Text typography="label-2-regular">
                {formatToReactComponent(trls[ScheduledPublication.trls.description], {
                    '{0}': (
                        <Text typography="subtitle-1-semibold" Element="span">
                            {requiredPublications[0].count}
                        </Text>
                    ),
                    '{1}': (
                        <Text typography="subtitle-1-semibold" Element="span">
                            {
                                requiredPublications[0].vacancyProperties?.calculatedStates?.[propertiesBundle]
                                    ?.translation
                            }
                        </Text>
                    ),
                })}
            </Text>
            <VSpacing default={32} />
            <div className={styles.buttonGroup}>
                <Button
                    Element={SPALink}
                    to="/employer/vacancies"
                    data-qa="dashboard-scheduled-vacancy__employer-vacancies-link"
                    style="accent"
                    mode="secondary"
                    size="medium"
                    stretched={isXS}
                >
                    {trls[ScheduledPublication.trls.toMyVacancies]}
                </Button>
                <HSpacing default={12} />
                <Button
                    Element={SPALink}
                    to="/price/publications"
                    data-qa="dashboard-scheduled-vacancy__price-publications-link"
                    style="accent"
                    mode="secondary"
                    size="medium"
                    stretched={isXS}
                >
                    {trls[ScheduledPublication.trls.toBuyServices]}
                </Button>
            </div>
        </AbstractVacancy>
    );
};

ScheduledPublication.trls = {
    toMyVacancies: 'vacancies.groups.myVacancies',
    toBuyServices: 'newemployer.dashboard.action.toBuyServices',
    description: 'newemployer.dashboard.scheduledPublication.requiredPublications',
    publicationTime: 'employer.vacancy.draft.scheduledPublicationTime',
    manager: 'employer.vacancy.draft.scheduledPublicationTime.anotherManager',
    weekdays: {
        0: 'lastActivity.withinWeek.sunday',
        1: 'lastActivity.withinWeek.monday',
        2: 'lastActivity.withinWeek.tuesday',
        3: 'lastActivity.withinWeek.wednesday',
        4: 'lastActivity.withinWeek.thursday',
        5: 'lastActivity.withinWeek.friday',
        6: 'lastActivity.withinWeek.saturday',
    },
};

export default translation(ScheduledPublication);
