import { createElement } from 'react';
import classnames from 'classnames';
import { domToReact } from 'html-react-parser';

import { TagMapperComponent, TagMapperProps } from 'src/hooks/useParseHtml';
import { HHATag } from 'src/models/articleHHATags';

import styles from 'src/components/IndexPageAnonymous/WorkInCity/styles.less';

const Paragraph: TagMapperComponent<TagMapperProps> = ({ options, originalElement: { name, children, parent } }) => (
    <>
        {!!children &&
            !!name &&
            createElement(
                name,
                {
                    className: classnames(styles.workInCityParagraph, {
                        [styles.workInCityParagraphToggling]: parent?.name === HHATag.Toggle,
                    }),
                },
                domToReact(children, options)
            )}
    </>
);

export default Paragraph;
