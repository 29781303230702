import { VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useHandleSeenClick } from 'src/components/EmployerBannersWidget/BannerCard/useHandleSeenClick';
import FlippableBannerQrCode from 'src/components/EmployerBannersWidget/FlippableBannerQrCode';
import ServiceCardIcon from 'src/components/EmployerBannersWidget/ServiceCardIcon';
import translation from 'src/components/translation';
import { EmployerBannerItem } from 'src/models/employerBannersWidget';

import styles from './styles.less';

const TrlKeys = {
    text: 'employer.index.bannersWidget.mobileApp.text',
};

const HH_BUSINESS_QR = '/images/hh/hh-business/qr-code-transparent.png';

const MobileAppBanner: TranslatedComponent<EmployerBannerItem> = ({ trls, name }) => {
    const handleSeen = useHandleSeenClick(name);

    return (
        <FlippableBannerQrCode name={name} onClick={handleSeen} qrCodeImgPath={HH_BUSINESS_QR}>
            <ServiceCardIcon name="hhbusiness" />
            <VSpacing default={12} />
            {trls[TrlKeys.text]}
            <div className={styles.bgImage} />
        </FlippableBannerQrCode>
    );
};

export default translation(MobileAppBanner);
