import { useState } from 'react';
import classnames from 'classnames';

import Analytics from '@hh.ru/analytics-js';
import { Action, Button, Card, Text, useBreakpoint, VSpacing } from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import Column from 'bloko/blocks/column';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import styles from './free-standard-plus-banner.less';

const TrlKeys = {
    part1: 'index.newEmployer.freeStandardPlusBanner.part1',
    highlighted: 'index.newEmployer.freeStandardPlusBanner.highlighted',
    part2: 'index.newEmployer.freeStandardPlusBanner.part2',
    button: 'index.newEmployer.freeStandardPlusBanner.button',
};

const FreeStandardPlusBanner: TranslatedComponent = ({ trls }) => {
    const { isMobile } = useBreakpoint();

    const [isVisible, setIsVisible] = useState(true);

    const handleButtonClick = () => {
        Analytics.sendHHEventButtonClick('index_page-free_standard_plus_banner_click');
    };

    if (!isVisible) {
        return null;
    }

    return (
        <div className={classnames({ [styles.fixed]: isMobile })}>
            <Column xs="4" s="8" m="5" l="5" container={!isMobile}>
                <div className={styles.banner}>
                    <Card
                        stretched
                        padding={12}
                        borderRadius={24}
                        style={isMobile ? 'constant' : 'primary'}
                        borderWidth={isMobile ? 'none' : 'default'}
                    >
                        <div className={styles.background} />

                        <div className={styles.text}>
                            <Text
                                style={isMobile ? 'contrast' : 'primary'}
                                typography={isMobile ? 'subtitle-1-semibold' : 'title-5-semibold'}
                            >
                                {trls[TrlKeys.part1]}
                                <Text
                                    style={isMobile ? 'positive-secondary' : 'positive'}
                                    typography={isMobile ? 'subtitle-1-semibold' : 'title-5-semibold'}
                                    Element="span"
                                >
                                    {trls[TrlKeys.highlighted]}
                                </Text>
                                {trls[TrlKeys.part2]}
                            </Text>
                        </div>

                        <VSpacing default={isMobile ? 24 : 64} />

                        <div className={styles.buttons}>
                            <Button
                                Element={SPALink}
                                to="/employer/vacancy/create"
                                mode="secondary"
                                size="medium"
                                style={isMobile ? 'contrast' : 'neutral'}
                                stretched
                                onClick={handleButtonClick}
                            >
                                {trls[TrlKeys.button]}
                            </Button>

                            {isMobile && (
                                <Action
                                    style="contrast"
                                    icon={CrossOutlinedSize24}
                                    onClick={() => setIsVisible(false)}
                                />
                            )}
                        </div>
                    </Card>
                </div>
            </Column>
        </div>
    );
};

export default translation(FreeStandardPlusBanner);
