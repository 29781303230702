import type { ButtonProps } from '@hh.ru/magritte-ui-button/types';

export const getExpireButtonStyles = ({
    isVeryLow,
    isZero,
}: {
    isZero?: boolean;
    isVeryLow: boolean;
}): Pick<ButtonProps, 'style' | 'mode'> => {
    if (isZero) {
        return {
            style: 'negative',
            mode: 'primary',
        };
    }
    if (isVeryLow) {
        return {
            style: 'negative',
            mode: 'secondary',
        };
    }

    return {
        style: 'neutral',
        mode: 'secondary',
    };
};
