import { useMemo } from 'react';

import { Button } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { EmployerBannerItem, EmployerBannerName } from 'src/models/employerBannersWidget';

import BannerCard from 'src/components/EmployerBannersWidget/BannerCard';
import { useHandleSeenClick } from 'src/components/EmployerBannersWidget/BannerCard/useHandleSeenClick';
import MobileAppBanner from 'src/components/EmployerBannersWidget/banners/MobileAppBanner';
import RabotaByAppBanner from 'src/components/EmployerBannersWidget/banners/RabotaByAppBanner';
import SetkaAppBanner from 'src/components/EmployerBannersWidget/banners/SetkaAppBanner';
import { useGetSimpleBanners } from 'src/components/EmployerBannersWidget/useGetSimpleBanners';

const getCustomBanner = (banner?: EmployerBannerItem) => {
    switch (banner?.name) {
        case EmployerBannerName.MobileApp:
            return <MobileAppBanner {...banner} />;
        case EmployerBannerName.RabotaByMobileApp:
            return <RabotaByAppBanner {...banner} />;
        case EmployerBannerName.Setka:
            return <SetkaAppBanner {...banner} />;
        default:
            return null;
    }
};

const EmployerBannersWidget: TranslatedComponent = ({ trls }) => {
    const { banner } = useSelector((state) => state.employerBannersWidget);

    const simpleBanners = useGetSimpleBanners(trls);
    const customBanner = useMemo(() => getCustomBanner(banner), [banner]);
    const handleSeen = useHandleSeenClick(banner?.name);

    if (!banner) {
        return null;
    }

    const simpleBannerData = simpleBanners[banner.name];

    if (simpleBannerData) {
        return (
            <BannerCard
                style={simpleBannerData.style}
                isTextSmall={simpleBannerData.isTextSmall}
                button={
                    <Button
                        size="small"
                        mode="primary"
                        style="contrast"
                        stretched
                        Element={SPALink}
                        target={simpleBannerData.isOuterLink ? '_blank' : undefined}
                        to={simpleBannerData.buttonLink}
                        onClick={handleSeen}
                    >
                        {simpleBannerData.buttonText}
                    </Button>
                }
                name={banner.name}
                title={simpleBannerData.title}
            >
                {simpleBannerData.text}
            </BannerCard>
        );
    }

    if (customBanner) {
        return customBanner;
    }

    return null;
};

export default translation(EmployerBannersWidget);
