import { MouseEventHandler } from 'react';
import classnames from 'classnames';

import { Link } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { useIsRussia } from 'src/hooks/useCountries';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelectorNonNullable, useSelector } from 'src/hooks/useSelector';

interface AgreementTextProps {
    text?: string;
    link?: string;
    buttonType?: AgreementButtonType;
    withSocial?: boolean;
    isIframeView?: boolean;
    target?: string;
}

export enum AgreementButtonType {
    Continue = 'continue',
    Signup = 'signup',
}

const TrlKeys = {
    bare: {
        common: {
            text: 'signup.agreementText.text',
        },
        russia: {
            text: 'signup.agreementText.russia.text',
            personalLink: 'signup.agreementText.russia.personal-data',
        },
        zp: {
            text: 'signup.agreementText.zp.text',
            signup: 'signup.agreementText.zp.signup',
            continue: 'signup.agreementText.zp.continue',
            link: 'signup.agreementText.zp.link',
            pd: 'signup.agreementText.zp.pd',
        },
        agreementLink: 'signup.agreementText.link',
    },
    withSocial: {
        common: {
            text: 'signup.agreementText.withSocial.text',
        },
        russia: {
            text: 'signup.agreementText.withSocial.russia.text',
            personalLink: 'signup.agreementText.russia.personal-data',
        },

        agreementLink: 'signup.agreementText.withSocial.link',
    },

    buttonType: {
        continue: 'signup.agreementText.withSocial.buttonType.continue',
        signup: 'signup.agreementText.withSocial.buttonType.signup',
    },
};

const agreementDataQa = 'account-signup-agreement';
const personalDataQa = 'account-signup-personal-data-russia';

const AgreementText: TranslatedComponent<AgreementTextProps> = ({
    trls,
    buttonType = AgreementButtonType.Continue,
    withSocial = false,
    isIframeView = false,
    ...props
}) => {
    const agreementURL = useSelectorNonNullable((state) => state.authUrl['agreement-form']);
    const isLightPage = useSelector(({ isLightPage }) => isLightPage);
    const personalDataRussiaURL = useSelectorNonNullable((state) => state.authUrl['policy-url']);
    const isRussia = useIsRussia();
    const isZP = useIsZarplataPlatform();

    const handleClickForVKminiApp: MouseEventHandler<HTMLAnchorElement> = (event) => {
        if (!isIframeView) {
            return;
        }
        event.preventDefault();
        window.location.href = event.currentTarget.href;
    };

    const renderLink = (text: string, dataQa: string, to: string) => {
        return (
            <SPALink
                {...props}
                onClick={handleClickForVKminiApp}
                className={classnames({ 'vk-mini-apps-link-disable': isIframeView })}
                to={to}
                data-qa={dataQa}
            >
                {text}
            </SPALink>
        );
    };

    const getText = () => {
        if (isZP) {
            return trls[TrlKeys.bare.zp.text];
        }
        if (withSocial) {
            if (isRussia) {
                return trls[TrlKeys.withSocial.russia.text];
            }
            return trls[TrlKeys.withSocial.common.text];
        }
        if (isRussia) {
            return trls[TrlKeys.bare.russia.text];
        }
        return trls[TrlKeys.bare.common.text];
    };

    const getFormat = (): Record<string, JSX.Element | string> => {
        if (isZP) {
            return {
                '{0}':
                    buttonType === AgreementButtonType.Continue
                        ? trls[TrlKeys.bare.zp.continue]
                        : trls[TrlKeys.bare.zp.signup],
                '{1}': renderLink(trls[TrlKeys.bare.zp.link], agreementDataQa, agreementURL),
                '{2}': (
                    <Link href="https://hhcdn.ru/file/17755941.doc" data-qa="account-signup-zp-pd">
                        {trls[TrlKeys.bare.zp.pd]}
                    </Link>
                ),
            };
        }
        if (withSocial) {
            if (isRussia) {
                return {
                    '{0}': trls[TrlKeys.buttonType[buttonType]],
                    '{1}': renderLink(trls[TrlKeys.withSocial.agreementLink], agreementDataQa, agreementURL),
                    '{2}': renderLink(
                        trls[TrlKeys.withSocial.russia.personalLink],
                        personalDataQa,
                        personalDataRussiaURL
                    ),
                };
            }
            return {
                '{0}': trls[TrlKeys.buttonType[buttonType]],
                '{1}': renderLink(trls[TrlKeys.withSocial.agreementLink], agreementDataQa, agreementURL),
            };
        }
        if (isRussia) {
            return {
                '{0}': trls[TrlKeys.buttonType[buttonType]],
                '{1}': renderLink(trls[TrlKeys.bare.agreementLink], agreementDataQa, agreementURL),
                '{2}': renderLink(trls[TrlKeys.bare.russia.personalLink], personalDataRussiaURL, personalDataRussiaURL),
            };
        }
        return {
            '{0}': renderLink(trls[TrlKeys.bare.agreementLink], agreementDataQa, agreementURL),
        };
    };

    return (
        <div
            className={classnames('account-agreement-text', {
                'account-agreement-text_light-page': isLightPage,
            })}
        >
            {formatToReactComponent(getText(), getFormat())}
        </div>
    );
};

export default translation(AgreementText);
