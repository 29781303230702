import { FC } from 'react';

import { SPALink } from '@hh.ru/redux-spa-middleware';
import { H2 } from 'bloko/blocks/header';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';

interface HeaderProps {
    text: string;
    disableTitleLink?: boolean;
}

const Header: FC<HeaderProps> = ({ text, disableTitleLink }) => {
    return (
        <H2 data-qa="index__work-in-profession-header">
            {disableTitleLink ? (
                text
            ) : (
                <BlokoLink kind={LinkKind.Tertiary} Element={SPALink} to="/vacancies" isSeoLink>
                    {text}
                </BlokoLink>
            )}
        </H2>
    );
};

export default Header;
