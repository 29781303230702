import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    noRegion: 'resumeSearchesWidget.savedSearches.noRegion',
};

interface AreaProps {
    areas?: string[];
}

const Area: TranslatedComponent<AreaProps> = ({ trls, areas = [] }) => {
    if (!areas.length) {
        return <>{trls[TrlKeys.noRegion]}</>;
    }

    return <>{areas.join(', ')}</>;
};

export default translation(Area);
