import { Fragment } from 'react';

import { VSpacing, Text, Divider } from '@hh.ru/magritte-ui';
import Conversion from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { CountryId } from 'src/models/countryId';
import { MonthNum, ServiceEmployerDescription } from 'src/models/oldEmployer/employerBillingSummary';
import { ServiceItemCode } from 'src/models/price/productCodes';

import { isItemExpireSoon } from 'src/components/EmployerBalanceWidget/cards/PublicationsCard/isItemExpireSoon';
import useGetPublicationItems from 'src/components/EmployerBalanceWidget/cards/PublicationsCard/useGetPublicationItems';

import styles from './styles.less';

const TrlKeys = {
    vacancies: 'index.employer.billing.vacancies',
    buy: 'index.employer.billing.buy',
    regionTitle: 'employer.service.regions.title',
    anyRegion: 'any.region',
    profRoleGroupTitle: 'employer.service.profRoleGroup.title',
    anyProfRoleGroup: 'any.profRoleGroup',
    zpCrossPosting: 'zarplata.crossposting',
    newWillBeAdded: 'employer.accountHistory.freeServices.newWillBeAdded',
    newWillBeAvailable: 'index.employer.billing.newWillBeAvailable',
    freeRegion: 'price.purchased.freeRegions.RFPN',
    expires: 'employer.index.balanceWidget.publication.expires',
    genitive: {
        month: {
            1: 'genitive.month.1',
            2: 'genitive.month.2',
            3: 'genitive.month.3',
            4: 'genitive.month.4',
            5: 'genitive.month.5',
            6: 'genitive.month.6',
            7: 'genitive.month.7',
            8: 'genitive.month.8',
            9: 'genitive.month.9',
            10: 'genitive.month.10',
            11: 'genitive.month.11',
            12: 'genitive.month.12',
        },
    },
    days: {
        one: 'index.employer.billing.days.one',
        some: 'index.employer.billing.days.some',
        many: 'index.employer.billing.days.many',
    },
};

const PublicationsHoverContent: TranslatedComponent = ({ trls }) => {
    const employerIndexPage = useSelector(({ employerIndexPage }) => employerIndexPage);
    const countryIdBySeller = useSelector(({ employerCountryIdBySeller }) => employerCountryIdBySeller?.toString());
    const publicationItems = useGetPublicationItems();
    const isRussia = (countryId: number) => countryId === parseInt(CountryId.Russia, 10);

    const getPricePurchasedItemRegions = (item: ServiceEmployerDescription) => {
        const { code, count, areaIds, areaIdsTrl } = item;

        if (
            code === ServiceItemCode.FreeRegionalVacancy &&
            count &&
            parseInt(count, 10) > 0 &&
            countryIdBySeller !== CountryId.Belarus
        ) {
            return (
                <Text typography="label-3-regular" style="secondary">
                    {trls[TrlKeys.freeRegion]}
                </Text>
            );
        }

        return (
            <Text typography="label-3-regular" style="secondary">
                {areaIds && areaIds?.length > 0 ? (
                    <>
                        {`${trls[TrlKeys.regionTitle]}: `}
                        {areaIdsTrl?.map((trl, index) => (
                            <Fragment key={trl}>
                                <span data-qa="price-purchased-item-region">{trl}</span>
                                {index < areaIdsTrl.length - 1 && ', '}
                            </Fragment>
                        ))}
                    </>
                ) : (
                    trls[TrlKeys.anyRegion]
                )}
            </Text>
        );
    };

    const getPricePurchasedItemProfareas = (item: ServiceEmployerDescription) => {
        const { profRoleGroupIds, profRoleGroupIdsTrl } = item;

        return (
            <Text typography="label-3-regular" style="secondary">
                {profRoleGroupIds?.length > 0 ? (
                    <>
                        {`${trls[TrlKeys.profRoleGroupTitle]}: `}
                        {profRoleGroupIdsTrl?.map((trl, index) => (
                            <Fragment key={trl}>
                                <span data-qa="price-purchased-item-profarea">{trl}</span>
                                {index < profRoleGroupIdsTrl.length - 1 && ', '}
                            </Fragment>
                        ))}
                    </>
                ) : (
                    trls[TrlKeys.anyProfRoleGroup]
                )}
            </Text>
        );
    };

    const getPricePurchasedItemZPCrossPost = (item: ServiceEmployerDescription) =>
        item.zpCrossPostAvailable ? (
            <Text typography="label-3-regular" style="secondary">
                {trls[TrlKeys.zpCrossPosting]}
            </Text>
        ) : null;

    const getPricePurchasedVacancyNextUpdate = (
        nextFreeUpdate: ServiceEmployerDescription['nextFreeUpdate'],
        countryId: number
    ) => {
        if (isRussia(countryId) || !nextFreeUpdate) {
            return null;
        }

        if (nextFreeUpdate.remainingDays === 0) {
            return (
                <Text typography="label-3-regular" style="secondary">
                    {trls[TrlKeys.newWillBeAvailable]}
                </Text>
            );
        }

        if (nextFreeUpdate.remainingDays > 0) {
            return (
                <Text typography="label-3-regular" style="secondary">
                    <Conversion
                        one={trls[TrlKeys.days.one]}
                        some={trls[TrlKeys.days.many]}
                        many={trls[TrlKeys.days.some]}
                        value={nextFreeUpdate.remainingDays}
                        hasValue={false}
                        format={(trl) =>
                            format(trls[TrlKeys.newWillBeAvailable], {
                                '{0}': nextFreeUpdate.remainingDays,
                                '{1}': trl,
                            })
                        }
                    />
                </Text>
            );
        }

        return (
            <Text typography="label-3-regular" style="secondary">
                {`${trls[TrlKeys.newWillBeAdded]} ${nextFreeUpdate.day} ${
                    trls[TrlKeys.genitive.month[nextFreeUpdate.month]]
                } ${nextFreeUpdate.year}`}
            </Text>
        );
    };

    const getPricePurchasedItemRegionsExpired = (item: ServiceEmployerDescription) => {
        if (item.expiredInfos?.length === 1 && item.expiredInfos[0].date) {
            const { date } = item.expiredInfos[0];

            const dateObj = new Date(date);

            const isExpireSoon = isItemExpireSoon(item);

            const day = dateObj.getDate();
            const month = (dateObj.getMonth() + 1) as MonthNum;
            const year = dateObj.getFullYear();

            return (
                <Text typography="label-3-regular" style={isExpireSoon ? 'negative' : 'secondary'}>
                    {`${trls[TrlKeys.expires]} ${day} ${trls[TrlKeys.genitive.month[month]]} ${year}`}
                </Text>
            );
        }

        return null;
    };

    const getPurchasedVacancyPublicationsItem = (item: ServiceEmployerDescription, countryId: number) => {
        const { code, count, nextFreeUpdate, serviceEntryTrl } = item;

        if (code === ServiceItemCode.FreeRegionalVacancy && count && parseInt(count, 10) === 0 && isRussia(countryId)) {
            return null;
        }

        return (
            <div className={styles.item} data-qa={`purchased-service-${code}`}>
                <div className={styles.itemRow}>
                    <Text typography="label-2-regular">{serviceEntryTrl}</Text>
                    <Text typography="label-2-regular">{count}</Text>
                </div>
                <VSpacing default={8} />
                {getPricePurchasedItemRegions(item)}
                {getPricePurchasedItemProfareas(item)}
                {getPricePurchasedItemZPCrossPost(item)}
                {getPricePurchasedVacancyNextUpdate(nextFreeUpdate, countryId)}
                {getPricePurchasedItemRegionsExpired(item)}
            </div>
        );
    };

    if (employerIndexPage.oldEmployer) {
        const { countryId } = employerIndexPage.oldEmployer.employer;
        return (
            <div className={styles.container}>
                {publicationItems.map((service, index) => (
                    <Fragment key={index}>
                        {index !== 0 && <Divider marginTop={24} marginBottom={24} />}
                        {getPurchasedVacancyPublicationsItem(service, countryId)}
                    </Fragment>
                ))}
            </div>
        );
    }

    return null;
};

export default translation(PublicationsHoverContent);
