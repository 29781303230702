import { ReactNode } from 'react';

import Analytics, { ElementShownAnchor } from '@hh.ru/analytics-js';
import { Card, VSpacing, Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import styles from './styles.less';

interface AdviceCardProps {
    children: ReactNode;
    name: string;
    button?: ReactNode;
}

const AdviceCard: TranslatedComponent<AdviceCardProps> = ({ children, button, name }) => {
    return (
        <ElementShownAnchor fn={Analytics.sendHHEventElementShown} name="advices_widget_card" card={name}>
            <div className={styles.cardContainer}>
                <Card verticalStretched borderWidth="default" padding={12} borderRadius={24} stretched>
                    <div className={styles.cardContent}>
                        <div className={styles.text}>
                            <Text typography="title-5-semibold">{children}</Text>
                        </div>
                        {button && (
                            <>
                                <VSpacing default={6} />
                                {button}
                            </>
                        )}
                    </div>
                </Card>
            </div>
        </ElementShownAnchor>
    );
};

export default translation(AdviceCard);
