import classnames from 'classnames';
import { domToReact } from 'html-react-parser';

import { VSpacing as VSpacingMagritte, Link as MagritteLink } from '@hh.ru/magritte-ui';
import { ChevronDownOutlinedSize16, ChevronUpOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import BlokoLink, { LinkAppearance } from 'bloko/blocks/link';

import { TagMapperComponent, TagMapperProps } from 'src/hooks/useParseHtml';
import { useSelector } from 'src/hooks/useSelector';

import styles from 'src/components/IndexPageAnonymous/WorkInCity/styles.less';

interface ToggleProps extends TagMapperProps {
    expanded: boolean;
    showMore: string;
    open: string;
    close: string;
    handleContainerClick: () => void;
}

const Toggle: TagMapperComponent<ToggleProps> = ({
    options,
    originalElement: { children },
    expanded,
    showMore,
    open,
    close,
    handleContainerClick,
}) => {
    const isAnonymousMagritteExp = useSelector((state) => state.isAnonymousMagritteExp);
    if (!children) {
        return null;
    }
    if (isAnonymousMagritteExp) {
        return (
            <>
                {expanded && domToReact(children, options)}
                <VSpacingMagritte default={16} gteS={20} gteM={24} />
                <MagritteLink
                    iconRight={expanded ? <ChevronUpOutlinedSize16 /> : <ChevronDownOutlinedSize16 />}
                    onClick={handleContainerClick}
                    Element="button"
                >
                    {expanded ? close : open}
                </MagritteLink>
            </>
        );
    }
    return (
        <>
            <p className={classnames(styles.workInCityParagraph, styles.workInCityParagraphShowMore)}>
                <BlokoLink appearance={LinkAppearance.Pseudo} onClick={handleContainerClick}>
                    {showMore}
                </BlokoLink>
            </p>
            {domToReact(children, options)}
        </>
    );
};

export default Toggle;
