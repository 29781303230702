import { StrictMode, useLayoutEffect } from 'react';
import { Meta } from 'react-head';

import FullPageLayout from 'src/app/layouts/FullPageLayout';
import PageLayout, { EmptyLayout } from 'src/app/layouts/PageLayout';
import { clearXSLMetaTags } from 'src/app/layouts/utils';
import AdditionalCheck from 'src/components/Employer/AdditionalCheck';
import IndexAnonymous from 'src/components/IndexPageAnonymous';
import IndexApplicant from 'src/components/IndexPageApplicant';
import IndexEmployer from 'src/components/IndexPageEmployer';
import IndexNewEmployer from 'src/components/IndexPageNewEmployer';
import MobileAppPromotion from 'src/components/MobileAppPromotion';
import { PageHead, PageHreflangLinks, PageMeta, PageTitle } from 'src/components/PageHead';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { useIsHhKz } from 'src/hooks/useSites';
import { UserType } from 'src/models/userType';

const TrlKeys = {
    googleSiteVerification: 'google-site-verification-2',
    wmailVerification: 'wmail-verification',
    yandexVerification: 'yandex-verification',
    vkVerification: 'vk-verification',
};

const IndexPage = ({ trls }) => {
    const isNewEmployer = useSelector(({ isNewEmployer }) => isNewEmployer);
    const { title, hreflangItems, verifications } = useSelector((state) => state.pageMetaData);
    const showAdditionalCheck = useSelector((state) => state.additionalCheckFirstView);
    const userType = useSelector((state) => state.userType);
    const isHhKz = useIsHhKz();
    const pageTitle = (
        <>
            <PageTitle>{title}</PageTitle>
            <PageMeta property="og:title" content={title} />
        </>
    );

    useLayoutEffect(() => {
        if (userType !== UserType.Employer) {
            clearXSLMetaTags({ title });
        }
    }, [title, userType]);

    if (userType === UserType.Applicant) {
        return (
            <FullPageLayout>
                {title && <PageHead>{pageTitle}</PageHead>}
                <IndexApplicant />
                <MobileAppPromotion />
            </FullPageLayout>
        );
    }

    if (userType === UserType.Employer) {
        return (
            <StrictMode>
                <PageLayout layout={EmptyLayout} title={title}>
                    {showAdditionalCheck && <AdditionalCheck />}
                    {isNewEmployer ? <IndexNewEmployer /> : <IndexEmployer />}
                </PageLayout>
            </StrictMode>
        );
    }
    // react-head отдает только последний из мета-тегов c одинаковым name, потому PageHead используется 2 раза
    return (
        <FullPageLayout>
            {!!verifications?.length && (
                <PageHead>
                    {verifications.map((verification) => (
                        <Meta
                            key={verification.name + verification.content}
                            name={verification.name}
                            content={verification.content}
                        />
                    ))}
                </PageHead>
            )}
            <PageHead>
                <Meta name="google-site-verification" content={trls[TrlKeys.googleSiteVerification]} />
                <Meta name="wmail-verification" content={trls[TrlKeys.wmailVerification]} />
                <Meta name="yandex-verification" content={trls[TrlKeys.yandexVerification]} />
                <Meta name="vk-verification" content={trls[TrlKeys.vkVerification]} />
                {isHhKz && <Meta name="facebook-domain-verification" content="pvlnrerfi2u9wypg5ezr847mleffhi" />}
                {title && pageTitle}
                {!!hreflangItems?.length && <PageHreflangLinks content={hreflangItems} />}
            </PageHead>
            <IndexAnonymous />
        </FullPageLayout>
    );
};

export default translation(IndexPage);
