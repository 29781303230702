import classNames from 'classnames';

import { VSpacing } from '@hh.ru/magritte-ui';
import Column from 'bloko/blocks/column';

import PreparedVacancyTemplates from 'src/components/IndexPageNewEmployer/components/PreparedVacancyTemplates';
import { useSelector } from 'src/hooks/useSelector';

import BilledVacancy from 'src/components/IndexPageNewEmployer/components/DashboardVacancy/BilledVacancy';
import FreeStandardPlusBanner from 'src/components/IndexPageNewEmployer/components/DashboardVacancy/FreeStandardPlusBanner';
import RecentDraftVacancy from 'src/components/IndexPageNewEmployer/components/DashboardVacancy/RecentDraftVacancy';
import ScheduledPublication from 'src/components/IndexPageNewEmployer/components/DashboardVacancy/ScheduledPublication';
import VacancyStub from 'src/components/IndexPageNewEmployer/components/DashboardVacancy/VacancyStub';

import styles from './dashboard-vacancy.less';

const DashboardVacancy = () => {
    const {
        draft,
        bill,
        giftsCount,
        employerRegionTrl,
        employerRegionResumesCount,
        nextDraftScheduled,
        isFreeStandardPlusAvailable,
    } = useSelector((state) => state.newEmployerDashboardVacancy);

    const renderVacancy = () => {
        if (draft && bill) {
            return <BilledVacancy vacancy={draft} bill={bill} giftsCount={giftsCount} />;
        }

        if (draft) {
            return <RecentDraftVacancy vacancy={draft} />;
        }

        if (nextDraftScheduled) {
            return <ScheduledPublication data={nextDraftScheduled} />;
        }

        return (
            <VacancyStub
                giftsCount={giftsCount}
                employerRegion={employerRegionTrl}
                employerRegionResumesCount={employerRegionResumesCount}
            />
        );
    };

    return (
        <>
            <VSpacing default={40} />
            <div
                className={classNames(styles.dashboardVacancy, {
                    [styles.dashboardVacancyWithoutBg]: isFreeStandardPlusAvailable,
                })}
            >
                <Column xs="4" s="8" m="7" l="10" container>
                    {renderVacancy()}
                </Column>

                {isFreeStandardPlusAvailable && <FreeStandardPlusBanner />}
            </div>
            <PreparedVacancyTemplates />
        </>
    );
};

export default DashboardVacancy;
