import { ChangeEvent, useState } from 'react';

import {
    Card,
    GridColumn,
    Title,
    useBreakpoint,
    ChipsContainer,
    CheckableChip,
    Button,
    VSpacing,
} from '@hh.ru/magritte-ui';
import Cookies from 'bloko/common/Cookies';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { PresenceGoalsMap, PRESENCE_GOALS_COOKIE_NAME } from 'src/models/presenceGoals';

import styles from './styles.less';

const TrlKeys = {
    title: 'presence.goals.title',
    description: 'presence.goals.description',
    labels: {
        findJob: 'presence.goals.label.find.job',
        evaluateVacancy: 'presence.goals.label.evaluate.vacancy',
        improveCareer: 'presence.goals.label.improve.career',
        improveCareerShort: 'presence.goals.label.improve.career.short',
        updateResume: 'presence.goals.label.update.resume',
    },
    submit: 'presence.goals.submit',
};

const presenceGoalsList = [
    { value: PresenceGoalsMap.FindJob, label: TrlKeys.labels.findJob },
    { value: PresenceGoalsMap.EvaluateVacancy, label: TrlKeys.labels.evaluateVacancy },
    { value: PresenceGoalsMap.UpdateResume, label: TrlKeys.labels.updateResume },
    {
        value: PresenceGoalsMap.ImproveCareer,
        label: TrlKeys.labels.improveCareer,
        shortLabel: TrlKeys.labels.improveCareerShort,
    },
];

interface PresenceGoalsProps {
    onSubmit: (selected: PresenceGoalsMap[]) => void;
}

const PresenceGoals: TranslatedComponent<PresenceGoalsProps> = ({ trls, onSubmit }) => {
    const { isMobile } = useBreakpoint();
    const [selected, setSelected] = useState<PresenceGoalsMap[]>([]);

    const handleChipsClick = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value as PresenceGoalsMap;
        const newSelectedValue = selected.includes(value)
            ? selected.filter((item) => item !== value)
            : [...selected, value];
        setSelected(newSelectedValue);
        Cookies.set(PRESENCE_GOALS_COOKIE_NAME, newSelectedValue.join(','));
    };

    const handleClickSubmit = () => {
        if (onSubmit) {
            onSubmit(selected);
        }
    };

    return (
        <GridColumn xs={4} s={8} m={12} l={12}>
            <Card borderWidth="default" padding={isMobile ? 24 : 32} borderRadius={isMobile ? 24 : 32} stretched>
                <div className={styles.container}>
                    <div>
                        <Title size="medium" Element="h2" description={trls[TrlKeys.description]}>
                            {trls[TrlKeys.title]}
                        </Title>
                        <VSpacing default={24} />
                        <ChipsContainer>
                            {presenceGoalsList.map((goal) => (
                                <CheckableChip
                                    key={goal.value}
                                    type="checkbox"
                                    value={goal.value}
                                    mode="primary"
                                    style="accent"
                                    size="medium"
                                    checked={selected.includes(goal.value)}
                                    onChange={handleChipsClick}
                                >
                                    {trls[isMobile && goal.shortLabel ? goal.shortLabel : goal.label]}
                                </CheckableChip>
                            ))}
                        </ChipsContainer>
                        <VSpacing default={24} />
                        <Button
                            mode="primary"
                            size="large"
                            style="accent"
                            onClick={handleClickSubmit}
                            stretched={isMobile}
                        >
                            {trls[TrlKeys.submit]}
                        </Button>
                    </div>

                    {!isMobile && <div className={styles.image} />}
                </div>
            </Card>
        </GridColumn>
    );
};

export default translation(PresenceGoals);
