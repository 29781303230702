import { Button, Text, useBreakpoint } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import Conversion from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import AdviceCard from 'src/components/EmployerAdvicesWidget/AdviceCard';
import { useHandleAnalyticsCardClick } from 'src/components/EmployerAdvicesWidget/useHandleAnalyticsCardClick';
import translation from 'src/components/translation';
import { UnusedPublicationsAdvice } from 'src/models/employerAdvicesWidget/advices';

const TrlKeys = {
    text: 'employer.index.advicesWidget.unusedPublications.text',
    one: 'employer.index.advicesWidget.unusedPublications.one',
    some: 'employer.index.advicesWidget.unusedPublications.some',
    many: 'employer.index.advicesWidget.unusedPublications.many',
    button: 'employer.index.advicesWidget.unusedPublications.button',
};

const UnusedPublications: TranslatedComponent<UnusedPublicationsAdvice> = ({
    trls,
    name,
    data: { publicationsCount },
}) => {
    const { isS } = useBreakpoint();
    const sendAnalytics = useHandleAnalyticsCardClick(name);

    return (
        <AdviceCard
            name={name}
            button={
                <Button
                    onClick={sendAnalytics}
                    Element={SPALink}
                    to="/employer/vacancy/create"
                    size="small"
                    stretched
                    mode="primary"
                    style="accent"
                >
                    {trls[TrlKeys.button]}
                </Button>
            }
        >
            {formatToReactComponent(trls[TrlKeys.text], {
                '{0}': (
                    <Text typography="title-5-semibold" Element={isS ? 'span' : 'div'} style="accent">
                        <Conversion
                            one={trls[TrlKeys.one]}
                            some={trls[TrlKeys.some]}
                            many={trls[TrlKeys.many]}
                            value={publicationsCount}
                            hasValue
                        />
                    </Text>
                ),
            })}
        </AdviceCard>
    );
};

export default translation(UnusedPublications);
