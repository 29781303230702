import { SPALink } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind } from 'bloko/blocks/button';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    createResume: 'supernova.navi.createResume',
};

const CreateResumeButton: TranslatedComponent = ({ trls }) => {
    return (
        <div className="index-resume-button">
            <Button
                Element={SPALink}
                kind={ButtonKind.Primary}
                to="/account/signup?backurl=%2Fapplicant%2Fresumes%2Fnew"
            >
                <span className="index-resume-button-content">{trls[TrlKeys.createResume]}</span>
            </Button>
        </div>
    );
};

export default translation(CreateResumeButton);
