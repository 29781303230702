import {
    createContext,
    useContext,
    FC,
    PropsWithChildren,
    useMemo,
    useState,
    useCallback,
    type ReactNode,
} from 'react';

interface SignupTrlsContextType {
    header: {
        title: ReactNode;
        subtitle: ReactNode;
    };
    updateHeader: (header: SignupTrlsContextType['header']) => void;
}

const SignupTrlsContext = createContext<SignupTrlsContextType | null>(null);

export const useSignupTrlsContextContext = (): SignupTrlsContextType => {
    const context = useContext(SignupTrlsContext);

    if (!context) {
        throw new Error('useSignupTrlsContextContext must be used within SignupTrlsContextProvider');
    }

    return context;
};

export const SignupTrlsContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const [header, setHeader] = useState<SignupTrlsContextType['header']>({ title: '', subtitle: '' });

    const updateHeader: SignupTrlsContextType['updateHeader'] = useCallback((header) => {
        setHeader(header);
    }, []);

    const value = useMemo(() => ({ header, updateHeader }), [header, updateHeader]);

    return <SignupTrlsContext.Provider value={value}>{children}</SignupTrlsContext.Provider>;
};
