import { Badge, Button, ComponentWithBadge, Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import styles from './styles.less';

interface CollapseButtonProps {
    isCollapsed: boolean;
    onCollapsedToggle: () => void;
    dropCardsCount: number;
    hasProblem: boolean;
}

const TrlKeys = {
    show: 'employer.index.balanceWidget.button.show',
    hide: 'employer.index.balanceWidget.button.hide',
};

const CollapseButton: TranslatedComponent<CollapseButtonProps> = ({
    trls,
    onCollapsedToggle,
    isCollapsed,
    dropCardsCount,
    hasProblem,
}) => {
    const badge = hasProblem && isCollapsed ? <Badge style="attention" size="extra-small" /> : undefined;

    const button = (
        <Button onClick={onCollapsedToggle} mode="secondary" size="small" style="neutral" stretched>
            <div className={styles.buttonText}>
                {trls[isCollapsed ? TrlKeys.show : TrlKeys.hide]}
                <Text style="secondary" typography="subtitle-2-semibold">
                    {dropCardsCount}
                </Text>
            </div>
        </Button>
    );

    if (badge) {
        // TODO HH-250134 убрать ComponentWithBadge и передавать badge в Button после фикса
        return (
            <ComponentWithBadge stretched badge={badge}>
                {button}
            </ComponentWithBadge>
        );
    }

    return button;
};

export default translation(CollapseButton);
