import { Button, HSpacing } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { formatCounter } from 'src/components/EmployerVacanciesWidget/utils';
import translation from 'src/components/translation';
import { VacanciesWidgetVacancy } from 'src/models/employer/vacanciesWidget';

const TrlKeys = {
    showVacancy: 'employer.indexPage.widgets.myVacancies.vacancy.showVacancy',
    responses: 'employer.indexPage.widgets.myVacancies.vacancy.responses',
    draftContinue: 'employer.indexPage.widgets.myVacancies.vacancy.draftContinue',
    archivePublish: 'employer.indexPage.widgets.myVacancies.vacancy.archivePublish',
};

interface VacancyButtonProps {
    vacancy: VacanciesWidgetVacancy;
    isDraft: boolean;
    isArchive: boolean;
    draftUrl: string;
    onClick: () => void;
}

const VacancyButton: TranslatedComponent<VacancyButtonProps> = ({
    vacancy,
    trls,
    isArchive,
    isDraft,
    draftUrl,
    onClick,
}) => {
    const vacancyResponsesUrl = `/employer/vacancyresponses?vacancyId=${vacancy.id}`;
    const archiveUrl = `/employer/vacancy/restore/${vacancy.id}`;

    if (vacancy.unread?.responses) {
        return (
            <Button
                style="positive"
                mode="secondary"
                Element={SPALink}
                to={vacancyResponsesUrl}
                stretched
                data-qa={`my-vacancies_vacancy-responses-link_${vacancy.id}`}
                onClick={onClick}
            >
                {trls[TrlKeys.responses]}
                <HSpacing default={8} />
                {formatCounter(vacancy.unread.responses)}
            </Button>
        );
    }

    if (isDraft) {
        return (
            <Button
                style="neutral"
                mode="secondary"
                Element={SPALink}
                to={draftUrl}
                stretched
                data-qa={`my-vacancies_draft-link_${vacancy.id}`}
                onClick={onClick}
            >
                {trls[TrlKeys.draftContinue]}
            </Button>
        );
    }

    if (isArchive) {
        return (
            <Button
                style="neutral"
                mode="secondary"
                Element={SPALink}
                to={archiveUrl}
                stretched
                data-qa={`my-vacancies_archive-link_${vacancy.id}`}
                onClick={onClick}
            >
                {trls[TrlKeys.archivePublish]}
            </Button>
        );
    }

    return (
        <Button
            style="neutral"
            mode="secondary"
            Element={SPALink}
            to={vacancyResponsesUrl}
            stretched
            data-qa={`my-vacancies_vacancy-responses-link_${vacancy.id}`}
            onClick={onClick}
        >
            {trls[TrlKeys.showVacancy]}
        </Button>
    );
};

export default translation(VacancyButton);
