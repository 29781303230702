import { useEffect, useRef } from 'react';
import classnames from 'classnames';

import Analytics from '@hh.ru/analytics-js';
import {
    Card,
    Title,
    Button as MagritteButton,
    VSpacing as VSpacingMagritte,
    Text as TextMagritte,
    useBreakpoint,
} from '@hh.ru/magritte-ui';
import Button, { ButtonAppearance, ButtonKind } from 'bloko/blocks/button';
import { H2 } from 'bloko/blocks/header';
import BlokoLink from 'bloko/blocks/link';
import Text from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useMagritte } from 'src/hooks/useMagritte';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

import styles from './employer-rating-teaser.less';

const TrlKeys = {
    header: 'index.employersRatingTeaser.header',
    description: 'index.employersRatingTeaser.description',
    button: 'index.employersRatingTeaser.button',
};

interface EmployerRatingTeaserProps {
    isInline?: boolean;
}

const EmployerRatingTeaser: TranslatedComponent<EmployerRatingTeaserProps> = ({ trls, isInline }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const userType = useSelector((state) => state.userType);
    const isAnonymousMagritteExp = useSelector((state) => state.isAnonymousMagritteExp);
    const isApplicant = userType === UserType.Applicant;
    const isMagritte = useMagritte() || isAnonymousMagritteExp;
    const { isGtM, isXS } = useBreakpoint();

    const POLL_URL = `https://rating.hh.ru/poll/?utm_source=hh.ru&utm_medium=referral&utm_campaign=above_footer_poll&utm_term=${userType}`;

    const classnamesWithApplicantVariant = (basicClass: string) => {
        return classnames(basicClass, {
            [`${basicClass}_applicant`]: isApplicant,
        });
    };

    const sendClickAnalytics = () => {
        Analytics.sendEvent(userType, 'button_click', 'employers-rating-teaser');
    };

    useEffect(() => {
        if (!containerRef.current) {
            return;
        }
        Analytics.sendEventElementShown(containerRef.current, userType, 'show', 'employers-rating-teaser');
    }, [userType]);

    if (isAnonymousMagritteExp) {
        return (
            <Card padding={12} borderWidth="default" borderRadius={24} stretched verticalStretched>
                <div className={styles.container}>
                    <div>
                        <Title Element="h3" size="medium" alignment="center">
                            {trls[TrlKeys.header]}
                        </Title>
                        <VSpacingMagritte default={16} />
                        <div className={styles.descriptionWrapper}>
                            <TextMagritte typography="paragraph-3-regular">{trls[TrlKeys.description]}</TextMagritte>
                        </div>
                    </div>
                    <div className={styles.illustration} />
                    <MagritteButton
                        Element="a"
                        target="_blank"
                        href={POLL_URL}
                        onClick={sendClickAnalytics}
                        mode="secondary"
                        style="accent"
                        stretched
                    >
                        {trls[TrlKeys.button]}
                    </MagritteButton>
                </div>
            </Card>
        );
    }

    if (isMagritte) {
        const Button = () => (
            <div className={styles.button}>
                <MagritteButton
                    size={isXS ? 'small' : 'medium'}
                    style="accent"
                    mode="secondary"
                    stretched={true}
                    target="_blank"
                    Element="a"
                    href={POLL_URL}
                    onClick={sendClickAnalytics}
                >
                    {trls[TrlKeys.button]}
                </MagritteButton>
            </div>
        );

        return (
            <Card padding={12} borderWidth="default" borderRadius={24} stretched verticalStretched>
                <div className={styles.wrapper} ref={containerRef}>
                    <div className={styles.header}>
                        <div className={styles.text}>
                            <Title
                                Element="h3"
                                size="small"
                                descriptionStyle="secondary"
                                description={trls[TrlKeys.description]}
                            >
                                {trls[TrlKeys.header]}
                            </Title>
                            <VSpacingMagritte default={24} xs={80} gteL={0} />
                        </div>
                        <span className={styles.imageXs} />
                        {!isXS && !isGtM && <Button />}
                    </div>
                    {!isXS && (
                        <>
                            <VSpacingMagritte default={10} />
                            <span className={styles.image} />
                            {isGtM && <VSpacingMagritte default={16} />}
                        </>
                    )}
                    {(isXS || isGtM) && <Button />}
                </div>
            </Card>
        );
    }

    return (
        <div
            className={classnames('employers-rating-teaser-content', {
                'employers-rating-teaser-content_inline': isInline,
            })}
            ref={containerRef}
        >
            <div>
                <div className="employers-rating-teaser-heading-wrapper">
                    <H2 Element="h3">{trls[TrlKeys.header]}</H2>
                </div>
                <div className={classnamesWithApplicantVariant('employers-rating-teaser-description-wrapper')}>
                    <Text>{trls[TrlKeys.description]}</Text>
                </div>
                {!isInline && (
                    <div className={classnamesWithApplicantVariant('employers-rating-teaser-image-wrapper')}>
                        <span className={classnamesWithApplicantVariant('employers-rating-teaser-image')} />
                    </div>
                )}
                <div className="employers-rating-teaser-link-wrapper">
                    <BlokoLink target="_blank" href={POLL_URL} onClick={sendClickAnalytics} disableVisited>
                        <span className="employers-rating-teaser-button-label">{trls[TrlKeys.button]}</span>
                    </BlokoLink>
                </div>
                <div className="employers-rating-teaser-button-wrapper">
                    <Button
                        kind={ButtonKind.Primary}
                        appearance={ButtonAppearance.Outlined}
                        target="_blank"
                        Element="a"
                        href={POLL_URL}
                        onClick={sendClickAnalytics}
                    >
                        <span className={classnamesWithApplicantVariant('employers-rating-teaser-button-label')}>
                            {trls[TrlKeys.button]}
                        </span>
                    </Button>
                </div>
            </div>
            {isInline && (
                <div className="employers-rating-teaser-image-wrapper">
                    <span className="employers-rating-teaser-image" />
                </div>
            )}
        </div>
    );
};

export default translation(EmployerRatingTeaser);
