import notVerifiedStatusBannerPrimaryClick, {
    StatusEmployer,
} from '@hh.ru/analytics-js-events/build/xhh/employer/verification/not_verified_status_banner_primary_click';
import { Button, Text } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import AdviceCard from 'src/components/EmployerAdvicesWidget/AdviceCard';
import { useHandleAnalyticsCardClick } from 'src/components/EmployerAdvicesWidget/useHandleAnalyticsCardClick';
import translation from 'src/components/translation';
import { NotVerifiedAdvice } from 'src/models/employerAdvicesWidget/advices';

const TrlKeys = {
    withoutDocs: 'employer.index.advicesWidget.notVerified.withoutDocs',
    withoutDocsHighlighted: 'employer.index.advicesWidget.notVerified.withoutDocs.highlighted',
    withDocs: 'employer.index.advicesWidget.notVerified.withDocs',
    withDocsHighlighted: 'employer.index.advicesWidget.notVerified.withDocs.highlighted',
    button: 'employer.index.advicesWidget.notVerified.withoutDocs.button',
};

const NotVerified: TranslatedComponent<NotVerifiedAdvice> = ({ trls, name, data: { hasDocuments, isNewEmployer } }) => {
    const text = hasDocuments ? trls[TrlKeys.withDocs] : trls[TrlKeys.withoutDocs];
    const highlightedText = hasDocuments ? trls[TrlKeys.withDocsHighlighted] : trls[TrlKeys.withoutDocsHighlighted];

    const sendAnalytics = useHandleAnalyticsCardClick(name);

    const handleClick = () => {
        sendAnalytics();
        let statusEmployer: StatusEmployer = 'employer_new_with_doc';

        if (!hasDocuments) {
            statusEmployer = 'employer_new_without_doc';
        }

        if (!isNewEmployer) {
            statusEmployer = 'employer_not_verified_without_doc';
        }

        notVerifiedStatusBannerPrimaryClick({
            statusEmployer,
        });
    };

    return (
        <AdviceCard
            name={name}
            button={
                hasDocuments ? null : (
                    <Button
                        onClick={handleClick}
                        Element={SPALink}
                        to="/employer/documents"
                        size="small"
                        stretched
                        mode="secondary"
                        style="negative"
                    >
                        {trls[TrlKeys.button]}
                    </Button>
                )
            }
        >
            {formatToReactComponent(text, {
                '{highlighted}': (
                    <Text typography="title-5-semibold" Element="span" style={!hasDocuments ? 'negative' : 'warning'}>
                        {highlightedText}
                    </Text>
                ),
            })}
        </AdviceCard>
    );
};

export default translation(NotVerified);
