import { Button, Text } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import AdviceCard from 'src/components/EmployerAdvicesWidget/AdviceCard';
import DaysLeftText from 'src/components/EmployerAdvicesWidget/DaysLeftText';
import { getExpireButtonStyles } from 'src/components/EmployerAdvicesWidget/getExpireButtonStyles';
import { useHandleAnalyticsCardClick } from 'src/components/EmployerAdvicesWidget/useHandleAnalyticsCardClick';
import translation from 'src/components/translation';
import { TrialExtensionAdvice } from 'src/models/employerAdvicesWidget/advices';

const TrlKeys = {
    button: 'employer.index.advicesWidget.dreamjobTrialExtension.button',
    soon: 'employer.index.advicesWidget.dreamjobTrialExtension.soon',
    verySoon: 'employer.index.advicesWidget.dreamjobTrialExtension.verySoon',
};

const TrialExtension: TranslatedComponent<TrialExtensionAdvice> = ({ trls, name, data: { trialDays } }) => {
    const isVerySoon = trialDays <= 1;
    const sendAnalytics = useHandleAnalyticsCardClick(name);

    return (
        <AdviceCard
            name={name}
            button={
                <Button
                    onClick={sendAnalytics}
                    Element={SPALink}
                    to="/article/dreamjob"
                    size="small"
                    stretched
                    {...getExpireButtonStyles({ isZero: trialDays === 0, isVeryLow: isVerySoon })}
                >
                    {trls[TrlKeys.button]}
                </Button>
            }
        >
            {formatToReactComponent(trls[isVerySoon ? TrlKeys.verySoon : TrlKeys.soon], {
                '{when}': (
                    <Text typography="title-5-semibold" Element="span" style={isVerySoon ? 'negative' : 'warning'}>
                        <DaysLeftText capitalize={isVerySoon} days={trialDays} />
                    </Text>
                ),
            })}
        </AdviceCard>
    );
};

export default translation(TrialExtension);
