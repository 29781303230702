import { useEffect, useMemo, useRef, useState } from 'react';

import { translation, type TranslatedComponent, useSelector } from '@hh.ru/front-static-app';
import { Snackbar } from '@hh.ru/magritte-ui';
import { ExclamationCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';

import { useIsAgeWarning, useAgreementLink } from 'src/components/AgeWarning/hooks';
import {
    isAgeBelowLimit,
    isAgeWarningSeen,
    markAgeWarningSeen,
    parseDateInFormat,
} from 'src/components/AgeWarning/utils';

const TrlKeys = {
    message: 'applicant.ageWarning.under18.message',
    action: 'applicant.ageWarning.under18.action',
};

const AgeWarningSnackbar: TranslatedComponent = ({ trls }) => {
    const birthdayFromProfile = useSelector((store) => store.applicantProfile?.fields?.birthday?.[0]?.date);
    const date: Date | undefined = useMemo(() => {
        return birthdayFromProfile ? parseDateInFormat(birthdayFromProfile) : undefined;
    }, [birthdayFromProfile]);

    const agreementLink = useAgreementLink();
    const isAgeWarning = useIsAgeWarning();

    const isVisible = useRef<boolean>(isAgeWarning && !!date && !isAgeWarningSeen() && isAgeBelowLimit(date));
    const [isClosed, setIsClosed] = useState(false);

    useEffect(() => {
        return isVisible.current ? markAgeWarningSeen : undefined;
    });

    if (!isVisible.current || isClosed) {
        return null;
    }

    const closeSnackbar = () => {
        markAgeWarningSeen();
        setIsClosed(true);
    };

    const handleAction = () => {
        window.open(agreementLink, '_blank');
        closeSnackbar();
    };

    return (
        <Snackbar
            role="alert"
            addon={<ExclamationCircleFilledSize24 initialColor="contrast" />}
            actionLabel={trls[TrlKeys.action]}
            autohideTime={0}
            data-qa="age-under-18-warning-snackbar"
            showClose
            onAction={handleAction}
            onClose={closeSnackbar}
        >
            {trls[TrlKeys.message]}
        </Snackbar>
    );
};

export default translation(AgeWarningSnackbar);
