import { useEffect, useRef, useCallback, RefObject } from 'react';

type UseInViewOptions = IntersectionObserverInit;

/**
 * Хук для отслеживания видимости элемента
 * @param callback - Функция, которая будет вызвана, когда элемент станет видимым
 * @param options - Настройки IntersectionObserver
 * @returns Ref для отслеживаемого элемента
 */
const useInView = <T extends HTMLElement>(callback: () => void, options: UseInViewOptions = {}): RefObject<T> => {
    const ref = useRef<T | null>(null);

    const observerCallback: IntersectionObserverCallback = useCallback(
        (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    callback();
                }
            });
        },
        [callback]
    );

    useEffect(() => {
        const element = ref.current;
        if (!element) {
            return undefined;
        }
        const observer = new IntersectionObserver(observerCallback, options);
        observer.observe(element);
        return () => {
            observer.disconnect();
        };
    }, [observerCallback, options]);

    return ref;
};

export default useInView;
