import { ComponentType } from 'react';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { EmployerServiceWidget } from 'src/models/employerServicesWidget';

import AnalyticsServiceCard from 'src/components/EmployerServicesWidget/cards/AnalyticsServiceCard';
import BrandingServiceCard from 'src/components/EmployerServicesWidget/cards/BrandingServiceCard';
import ClickmeServiceCard from 'src/components/EmployerServicesWidget/cards/ClickmeServiceCard';
import DreamjobServiceCard from 'src/components/EmployerServicesWidget/cards/DreamjobServiceCard';
import EfficiencyServiceCard from 'src/components/EmployerServicesWidget/cards/EfficiencyServiceCard';
import HRSpaceServiceCard from 'src/components/EmployerServicesWidget/cards/HRSpaceServiceCard';
import HrlinkServiceCard from 'src/components/EmployerServicesWidget/cards/HrlinkServiceCard';
import SkillazServiceCard from 'src/components/EmployerServicesWidget/cards/SkillazServiceCard';
import TalantixServiceCard from 'src/components/EmployerServicesWidget/cards/TalantixServiceCard';

import styles from './styles.less';

const CARDS_MAP: Record<string, ComponentType<EmployerServiceWidget>> = {
    dreamjob: DreamjobServiceCard,
    clickme: ClickmeServiceCard,
    talantix: TalantixServiceCard,
    hrSpace: HRSpaceServiceCard,
    branding: BrandingServiceCard,
    skillaz: SkillazServiceCard,
    hrLink: HrlinkServiceCard,
    analytics: AnalyticsServiceCard,
    efficiency: EfficiencyServiceCard,
};

const EmployerServicesWidget: TranslatedComponent = () => {
    const employerServicesWidget = useSelector(({ employerServicesWidget }) => employerServicesWidget);
    return (
        <div className={styles.container}>
            {employerServicesWidget.map((service) => {
                const ServiceComponent = CARDS_MAP[service.name];

                return <ServiceComponent key={service.name} {...service} />;
            })}
        </div>
    );
};

export default translation(EmployerServicesWidget);
