import { PropsWithChildren } from 'react';

import { Card, VSpacing, Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import styles from './styles.less';

const TrlKeys = {
    title: 'employer.index.additionalServices.reviewsSmallWidget.title',
};

const ReviewsSmallWidgetWrapper: TranslatedComponent<PropsWithChildren> = ({ children, trls }) => {
    return (
        <Card stretched borderWidth="default" borderRadius={24} padding={16}>
            <div className={styles.title}>
                <div className={styles.logo}></div>
                <Text typography="label-3-regular" style="secondary">
                    {trls[TrlKeys.title]}
                </Text>
            </div>
            <VSpacing default={8} />
            {children}
        </Card>
    );
};

export default translation(ReviewsSmallWidgetWrapper);
