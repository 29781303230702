import { FC } from 'react';

interface EmploymentProps {
    employments: string[];
}

const Employment: FC<EmploymentProps> = ({ employments }) => {
    return <>{employments.join(', ')}</>;
};

export default Employment;
