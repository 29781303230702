import { translation, type TranslatedComponent } from '@hh.ru/front-static-app';
import {
    BottomSheet,
    BottomSheetFooter,
    Button,
    Card,
    Modal,
    NavigationBar,
    Text,
    useBreakpoint,
    VSpacing,
    ActionBar,
} from '@hh.ru/magritte-ui';

import LowActivityVacancyPrompt from 'src/components/LowActivityVacancyList/LowActivityVacancyPrompt';
import { LowActivityAdviceVacancy } from 'src/models/lowActivityVacancies';

const TrlKeys = {
    description: 'employer.vacancies.advice.lowPerformanceVacancies.description',
    modalTitle: 'employer.vacancies.advice.modal.lowPerformanceVacancies.modal.title',
    modalButton: 'employer.vacancies.advice.modal.lowPerformanceVacancies.modal.button',
};

interface LowActivityAdviceModalProps {
    vacancies: LowActivityAdviceVacancy[];
    isModalOpen: boolean;
    hideModal: () => void;
}

const LowActivityAdviceModal: TranslatedComponent<LowActivityAdviceModalProps> = ({
    trls,
    vacancies,
    isModalOpen,
    hideModal,
}) => {
    const { isXS, isS } = useBreakpoint();
    const isSmallScreen = isS || isXS;
    const closeButton = (
        <Button style="accent" mode="primary" size="small" onClick={hideModal} stretched={isSmallScreen}>
            {trls[TrlKeys.modalButton]}
        </Button>
    );
    const content = (
        <>
            <Text typography="label-3-regular">{trls[TrlKeys.description]}</Text>
            <VSpacing default={24} />
            <Card borderRadius={24} stretched borderWidth="default" padding={24}>
                {vacancies.map((vacancy, idx) => (
                    <LowActivityVacancyPrompt
                        hhtmSourceLabel="employer_advice"
                        key={vacancy.vacancyId}
                        vacancy={vacancy}
                        isLast={vacancies.length - 1 === idx}
                        isFirst={idx === 0}
                    />
                ))}
            </Card>
        </>
    );

    return (
        <>
            <BottomSheet
                onClose={hideModal}
                visible={isModalOpen}
                header={<NavigationBar title={trls[TrlKeys.modalTitle]} />}
                footer={<BottomSheetFooter>{closeButton}</BottomSheetFooter>}
            >
                {content}
            </BottomSheet>

            <Modal
                onClose={hideModal}
                title={trls[TrlKeys.modalTitle]}
                titleSize="small"
                visible={isModalOpen}
                footer={<ActionBar primaryActions={closeButton} />}
            >
                {content}
            </Modal>
        </>
    );
};

export default translation(LowActivityAdviceModal);
