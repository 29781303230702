import { RefObject } from 'react';

import buttonClick from '@hh.ru/analytics-js-events/build/xhh/common/main/button_click';
import { Card, Title, Text, VSpacing, useBreakpoint, Link as LinkMagritte } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import paths from 'src/app/routePaths';
import Compensation from 'src/components/Compensation';
import IndexCollapsibleBlock from 'src/components/IndexCollapsibleBlock';
import { getVacancyCompanyLink } from 'src/components/RainbowCatalog/utils';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { VacancySearchItem } from 'src/types/search/vacancy/card';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import styles from './styles.less';

const TrlKeys = {
    title: 'index.anonymous.suitable.title',
    all: 'index.vacancies.all',
};

const SuitableVacancies: TranslatedComponent = ({ trls }) => {
    const suitableVacancies = useSelector((state) => state.suitableVacancies);
    const resultsFound = suitableVacancies?.resultsFound || 0;
    const { isMobile } = useBreakpoint();

    const getResultsFoundLink = () => {
        const areas = suitableVacancies?.areaIds?.map((areaId) => `&area=${areaId}`).join('') || '';
        return `${paths.vacancySearch}?recommended_by_uid=true${areas}`;
    };

    const handleCollapse = (expanded: boolean) => {
        if (expanded) {
            buttonClick({ buttonName: 'suitable_vacancies' });
        }
    };

    if (!suitableVacancies?.vacancies.length) {
        return null;
    }

    const renderCard = (vacancy: VacancySearchItem) => (
        <Card borderWidth="default" stretched padding={12} borderRadius={12} key={vacancy.name} hoverEnabled>
            <div className={styles.cardContent}>
                <SPALink to={vacancy.links.desktop}>
                    <div className={styles.title}>
                        <Text typography="subtitle-1-semibold">{vacancy.name}</Text>
                    </div>
                    <Text typography="paragraph-2-regular">
                        {vacancy.compensation && (
                            <Compensation {...vacancy.compensation} analyticsContext="SuitableVacancies" />
                        )}
                    </Text>
                </SPALink>
                <VSpacing default={24} />
                <SPALink to={getVacancyCompanyLink(vacancy)}>
                    <Text typography="paragraph-3-regular" style="secondary">
                        {`${vacancy.company.name}, ${vacancy.area.name}`}
                    </Text>
                </SPALink>
            </div>
        </Card>
    );

    return (
        <div>
            <div className={styles.title}>
                <Title Element="h3" size={isMobile ? 'small' : 'medium'} style="primary">
                    {trls[TrlKeys.title]}
                </Title>
                <LinkMagritte
                    href={getResultsFoundLink()}
                    typography="label-2-regular"
                    inline
                    data-qa="index-recommended-link-xs"
                >
                    {trls[TrlKeys.all]}
                    {NON_BREAKING_SPACE}
                    {resultsFound}
                </LinkMagritte>
            </div>
            <VSpacing default={16} gteS={20} gteM={24} />
            <IndexCollapsibleBlock
                renderPreview={() => (
                    <div className={styles.container}>
                        {suitableVacancies.vacancies.slice(0, 3).map((vacancy) => renderCard(vacancy))}
                    </div>
                )}
                renderRest={() => null}
                renderBottomSheetContent={() => (
                    <div className={styles.container}>
                        {suitableVacancies.vacancies.map((vacancy) => renderCard(vacancy))}
                    </div>
                )}
                bottomSheetTitle={trls[TrlKeys.title]}
                needToExpand={suitableVacancies.vacancies.length > 3}
                collapseCallback={(ref: RefObject<HTMLDivElement> | null, expanded: boolean) =>
                    handleCollapse(expanded)
                }
            />
        </div>
    );
};

export default translation(SuitableVacancies);
