import { useEffect, useRef } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Link } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import indexPageEmployerAnalytics from 'src/components/IndexPageEmployer/components/analytics';
import translation from 'src/components/translation';
import useFeatureEnabled from 'src/hooks/useFeatureEnabled';

const Features = {
    employerMainPageAnalyticsEnabled: 'employer_main_page_analytics_enabled',
};

const TrlKeys = {
    allUpdatesLink: 'index.employerUpdatesInfo.allUpdatesLink',
};

const Footer: TranslatedComponent = ({ trls }) => {
    const employerMainPageAnalyticsEnabled = useFeatureEnabled(Features.employerMainPageAnalyticsEnabled);
    const updatesAllLinkRef = useRef(null);

    const trackAnalytics = () => {
        Analytics.sendEvent('employer', 'click', 'all_updates');
        if (employerMainPageAnalyticsEnabled) {
            indexPageEmployerAnalytics.updatesAllClick();
        }
    };

    useEffect(() => {
        if (employerMainPageAnalyticsEnabled && updatesAllLinkRef.current) {
            const { stopSpying } = indexPageEmployerAnalytics.updatesAllShown(updatesAllLinkRef.current);

            return stopSpying;
        }

        return undefined;
    }, [employerMainPageAnalyticsEnabled]);

    return (
        <div className="updates-footer" ref={updatesAllLinkRef}>
            <Link
                Element={SPALink}
                to="/articles/employers/updates?from=dashboard&hhtmFromLabel=dashboard"
                data-qa="all-updates"
                onClick={trackAnalytics}
            >
                {trls[TrlKeys.allUpdatesLink]}
            </Link>
        </div>
    );
};

export default translation(Footer);
