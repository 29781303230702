import { useEffect, useRef, type FC } from 'react';

import actionCardElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/main/action_card_element_shown';
import { VSpacing } from '@hh.ru/magritte-ui';

import { useIsRussia } from 'src/hooks/useCountries';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';

import CareerBanner from 'src/components/IndexPageApplicant/Actions/CareerBanner';
import CreateResume from 'src/components/IndexPageApplicant/Actions/CreateResume';
import EnableResumeAutoUpdate from 'src/components/IndexPageApplicant/Actions/EnableResumeAutoUpdate';
import KakdelaBanner from 'src/components/IndexPageApplicant/Actions/KakdelaBanner';
import ResponseStreak from 'src/components/IndexPageApplicant/Actions/ResponseStreak';
import UpdateResumes from 'src/components/IndexPageApplicant/Actions/UpdateResumes';

import styles from './actions.less';

interface ActionsProps {
    hasResumes: boolean;
    hasFinishedResumes: boolean;
}

const Actions: FC<ActionsProps> = ({ hasResumes, hasFinishedResumes }) => {
    const isRussia = useIsRussia();
    const isZP = useIsZarplataPlatform();

    const actionsRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!actionsRef.current) {
            return undefined;
        }

        const instance = actionCardElementShown(actionsRef.current, {
            hhtmSource: 'main',
            actionCardList: [
                !hasResumes && 'create_resume_card',
                hasFinishedResumes && 'update_resume_card',
                hasFinishedResumes && 'update_automatically_card',
                hasFinishedResumes && 'vacancies_continue_to_response_card',
                'career_skills_growth',
            ]
                .filter(Boolean)
                .join(','),
        });

        return instance.stopSpying;
    }, [actionsRef, hasResumes, hasFinishedResumes]);

    return (
        <div>
            {!hasResumes && <VSpacing default={28} xs={0} />}
            <div className={styles.cardsScroller}>
                <div ref={actionsRef} className={styles.cardsWrapper}>
                    {!hasResumes && <CreateResume />}
                    {hasFinishedResumes && (
                        <>
                            <UpdateResumes />
                            <EnableResumeAutoUpdate />
                        </>
                    )}
                    {!isZP && isRussia && <KakdelaBanner />}
                    {hasResumes && <ResponseStreak />}
                    <CareerBanner />
                </div>
            </div>
        </div>
    );
};

export default Actions;
