import { useMemo } from 'react';
import getDayOfYear from 'date-fns/getDayOfYear';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import AdviceCard from 'src/components/EmployerAdvicesWidget/AdviceCard';
import translation from 'src/components/translation';

import styles from './styles.less';

type CardNum = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14;

const TrlKeys: { cardText: Record<CardNum, string> } = {
    cardText: {
        1: 'employer.index.advicesWidget.remainingAdvice.1.text',
        2: 'employer.index.advicesWidget.remainingAdvice.2.text',
        3: 'employer.index.advicesWidget.remainingAdvice.3.text',
        4: 'employer.index.advicesWidget.remainingAdvice.4.text',
        5: 'employer.index.advicesWidget.remainingAdvice.5.text',
        6: 'employer.index.advicesWidget.remainingAdvice.6.text',
        7: 'employer.index.advicesWidget.remainingAdvice.7.text',
        8: 'employer.index.advicesWidget.remainingAdvice.8.text',
        9: 'employer.index.advicesWidget.remainingAdvice.9.text',
        10: 'employer.index.advicesWidget.remainingAdvice.10.text',
        11: 'employer.index.advicesWidget.remainingAdvice.11.text',
        12: 'employer.index.advicesWidget.remainingAdvice.12.text',
        13: 'employer.index.advicesWidget.remainingAdvice.13.text',
        14: 'employer.index.advicesWidget.remainingAdvice.14.text',
    },
};

const RemainingAdvice: TranslatedComponent = ({ trls }) => {
    const cardNum = useMemo<CardNum>(() => {
        const dateOfYear = getDayOfYear(new Date());

        return ((dateOfYear % Object.keys(TrlKeys.cardText).length) + 1) as CardNum;
    }, []);

    return (
        <AdviceCard name="remaining-advice">
            <div className={styles[`card${cardNum}`]}>
                {trls[TrlKeys.cardText[cardNum]]}
                <div className={styles.image} />
            </div>
        </AdviceCard>
    );
};

export default translation(RemainingAdvice);
