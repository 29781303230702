import { Button, Text } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import AdviceCard from 'src/components/EmployerAdvicesWidget/AdviceCard';
import DaysLeftText from 'src/components/EmployerAdvicesWidget/DaysLeftText';
import { getExpireButtonStyles } from 'src/components/EmployerAdvicesWidget/getExpireButtonStyles';
import { useHandleAnalyticsCardClick } from 'src/components/EmployerAdvicesWidget/useHandleAnalyticsCardClick';
import translation from 'src/components/translation';
import { ExpiringResumeAccessesAdvice } from 'src/models/employerAdvicesWidget/advices';

const TrlKeys = {
    prolongButton: 'employer.index.advicesWidget.expiringResumeAccesses.prolong.button',
    singleSoon: 'employer.index.advicesWidget.expiringResumeAccesses.single.soon',
    singleVerySoon: 'employer.index.advicesWidget.expiringResumeAccesses.single.verySoon',
    singleVerySoonEnd: 'employer.index.advicesWidget.expiringResumeAccesses.single.verySoon.end',
    multipleSoon: 'employer.index.advicesWidget.expiringResumeAccesses.multiple.soon',
    multipleVerySoon: 'employer.index.advicesWidget.expiringResumeAccesses.multiple.verySoon',
};

const ExpiringResumeAccesses: TranslatedComponent<ExpiringResumeAccessesAdvice> = ({
    trls,
    name,
    data: { resumeAccessesCount, expireInDays },
}) => {
    const isVerySoon = expireInDays <= 1;
    const isMultiple = resumeAccessesCount > 1;

    const sendAnalytics = useHandleAnalyticsCardClick(name);

    const getText = () => {
        const daysLeftText = <DaysLeftText days={expireInDays} />;
        let text = '';

        if (!isVerySoon) {
            text = isMultiple ? trls[TrlKeys.multipleSoon] : trls[TrlKeys.singleSoon];
        } else {
            text = isMultiple ? trls[TrlKeys.multipleVerySoon] : trls[TrlKeys.singleVerySoon];
        }

        const textStyle = isVerySoon ? 'negative' : 'warning';

        return formatToReactComponent(text, {
            '{when}': (
                <Text typography="title-5-semibold" Element="span" style={textStyle}>
                    {daysLeftText}
                </Text>
            ),
            '{0}': (
                <Text typography="title-5-semibold" Element="span" style={textStyle}>
                    {trls[TrlKeys.singleVerySoonEnd]}
                </Text>
            ),
        });
    };

    return (
        <AdviceCard
            name={name}
            button={
                <Button
                    Element={SPALink}
                    to="/price/dbaccess"
                    size="small"
                    onClick={sendAnalytics}
                    stretched
                    {...getExpireButtonStyles({ isVeryLow: isVerySoon })}
                >
                    {trls[TrlKeys.prolongButton]}
                </Button>
            }
        >
            {getText()}
        </AdviceCard>
    );
};

export default translation(ExpiringResumeAccesses);
