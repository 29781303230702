import { useRef, useEffect } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Button, Text, VSpacing, useBreakpoint } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import numberFormatter from 'bloko/common/numberFormatter';
import { format, formatToReactComponent } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import AbstractVacancy from 'src/components/IndexPageNewEmployer/components/DashboardVacancy/AbstractVacancy';
import LinkInfo from 'src/components/IndexPageNewEmployer/components/DashboardVacancy/LinkInfo';

import styles from './dashboard-vacancy.less';

const sendAnalyticsCreateVacancy = () => {
    Analytics.sendHHEventButtonClick('dashboard_vacancy_create_vacancy');
};

const VacancyStub = ({ trls, employerRegion, employerRegionResumesCount, giftsCount }) => {
    const vacancyStubRef = useRef();
    const isZp = useIsZarplataPlatform();
    const { isXS } = useBreakpoint();

    useEffect(() => {
        Analytics.sendHHEventElementShown(vacancyStubRef.current, {
            name: 'dashboard_vacancy_first_state',
        });
    }, [vacancyStubRef]);

    return (
        <AbstractVacancy name={trls[VacancyStub.trls.title]} description={trls[VacancyStub.trls.description]}>
            <div ref={vacancyStubRef} data-qa="dashboard-vacancy-stub">
                <ul>
                    <li className={styles.dashboardVacancyListItem}>
                        <Text Element="span" typography="label-2-regular">
                            {formatToReactComponent(trls[VacancyStub.trls.firstSuggestText], {
                                '{0}': (
                                    <Text Element="span" typography="subtitle-1-semibold">
                                        {trls[VacancyStub.trls.firstHighlightText]}
                                    </Text>
                                ),
                            })}
                        </Text>
                    </li>
                    <li className={styles.dashboardVacancyListItem}>
                        <Text Element="span" typography="label-2-regular">
                            {formatToReactComponent(trls[VacancyStub.trls.secondSuggestText], {
                                '{0}': (
                                    <LinkInfo
                                        onClick={() => {
                                            Analytics.sendHHEventButtonClick(
                                                'dashboard_vacancy_vacancy_stub_suggestion_first'
                                            );
                                        }}
                                        tooltipContent={trls[VacancyStub.trls.secondSuggestInfoText]}
                                        data-qa="dashboard-vacancy-stub__active-applicants"
                                    >
                                        {format(trls[VacancyStub.trls.secondSuggestLinkText], {
                                            '{0}': numberFormatter.format(`${employerRegionResumesCount}`, {
                                                decimalLength: 0,
                                                groupSeparator: NON_BREAKING_SPACE,
                                            }),
                                        })}
                                    </LinkInfo>
                                ),
                                '{1}': employerRegion,
                            })}
                        </Text>
                    </li>
                    {!isZp && giftsCount > 0 && (
                        <li className={styles.dashboardVacancyListItem}>
                            <Text Element="span" typography="label-2-regular">
                                {formatToReactComponent(trls[VacancyStub.trls.thirdSuggestText], {
                                    '{0}': (
                                        <div className={styles.giftLink}>
                                            <LinkInfo
                                                Element="div"
                                                onClick={() => {
                                                    Analytics.sendHHEventButtonClick(
                                                        'dashboard_vacancy_vacancy_stub_suggestion_second'
                                                    );
                                                }}
                                                tooltipContent={format(trls[VacancyStub.trls.thirdSuggestInfoText], {
                                                    '{0}': giftsCount,
                                                })}
                                                data-qa="dashboard-vacancy-gifts"
                                            >
                                                {format(trls[VacancyStub.trls.thirdSuggestLinkText], {
                                                    '{0}': giftsCount,
                                                })}
                                            </LinkInfo>
                                        </div>
                                    ),
                                })}
                            </Text>
                        </li>
                    )}
                </ul>
            </div>
            <VSpacing default={24} />
            <Button
                size="medium"
                style="accent"
                mode="primary"
                Element={SPALink}
                to="/employer/vacancy/create"
                onClick={sendAnalyticsCreateVacancy}
                data-qa="dashboard-vacancy-stub__create-vacancy"
                stretched={isXS}
            >
                {trls[VacancyStub.trls.createVacancy]}
            </Button>
        </AbstractVacancy>
    );
};

VacancyStub.trls = {
    title: 'newemployer.dashboard.vacancyStub.title',
    description: 'newemployer.dashboard.vacancyStub.description',
    firstSuggestText: 'newemployer.dashboard.vacancyStub.suggest.1.text',
    firstHighlightText: 'newemployer.dashboard.vacancyStub.suggest.1.highlightText',
    secondSuggestText: 'newemployer.dashboard.vacancyStub.suggest.2.text',
    secondSuggestLinkText: 'newemployer.dashboard.vacancyStub.suggest.2.linkText',
    secondSuggestInfoText: 'newemployer.dashboard.vacancyStub.suggest.2.infoText',
    thirdSuggestText: 'newemployer.dashboard.vacancyStub.suggest.3.text',
    thirdSuggestLinkText: 'newemployer.dashboard.vacancyStub.suggest.3.linkText',
    thirdSuggestInfoText: 'newemployer.dashboard.vacancyStub.suggest.3.infoText',
    createVacancy: 'newemployer.dashboard.vacancyStub.action.createVacancy',
};

export default translation(VacancyStub);
