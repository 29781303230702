import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Analytics, { useElementShown } from '@hh.ru/analytics-js';
import { Card, Tab, Tabs } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import {
    ResumeSearchesWidgetTabName,
    ResumeSearchesWidgetTabCounters,
} from 'src/models/employer/resumeSearchesWidget/types';

import TabsContent from 'src/components/EmployerResumeSearchesWidget/components/TabsContent';
import useRefetch from 'src/components/EmployerResumeSearchesWidget/hooks/useRefetch';
import {
    getAnalyticsName,
    getAnalyticsCounters,
    getAnalyticsTabs,
    hasContent,
} from 'src/components/EmployerResumeSearchesWidget/utils';

import styles from './styles.less';

const TABS_CORRECTIONS = {
    correction: 24,
};

const TrlKeys = {
    targetResumes: 'resumeSearchesWidget.tab.targetResumes',
    favoriteResumes: 'resumeSearchesWidget.tab.favoriteResumes',
    savedSearches: 'resumeSearchesWidget.tab.savedSearches',
    lastSearches: 'resumeSearchesWidget.tab.lastSearches',
};

const EmployerResumeSearchesWidget: TranslatedComponent = ({ trls }) => {
    const resumeSearchesWidget = useSelector((state) => state.resumeSearchesWidget);
    const { tabs } = resumeSearchesWidget;
    const [activeTab, setActiveTab] = useState(tabs[0]?.name);
    const tabsContentRef = useRef<HTMLDivElement | null>(null);

    const analyticsParams = useMemo(
        () => ({
            counters: JSON.stringify(getAnalyticsCounters(tabs)),
            tabs: getAnalyticsTabs(tabs),
        }),
        [tabs]
    );

    const widgetRef = useElementShown(Analytics.sendHHEventElementShown, {
        name: getAnalyticsName(tabs[0]?.name),
        hasContent: hasContent(tabs[0]?.name, resumeSearchesWidget),
        ...analyticsParams,
    });

    const handleTabChange = useCallback((tabName: string) => {
        setActiveTab(tabName as ResumeSearchesWidgetTabName);
    }, []);

    const getTabPostfix = (counters?: ResumeSearchesWidgetTabCounters): string => {
        if (!counters) {
            return '';
        }

        if (counters.new) {
            return `+${counters.new}`;
        }

        if (counters.all) {
            return counters.all.toString();
        }

        return '';
    };

    const getTitle = (tab: ResumeSearchesWidgetTabName): string => {
        return trls[TrlKeys[tab]];
    };

    useRefetch();

    useEffect(() => {
        tabsContentRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
    }, [activeTab]);

    return (
        <Card borderWidth="default" borderRadius={24} stretched verticalStretched ref={widgetRef}>
            <div className={styles.tabsWrapper}>
                <Tabs
                    activeItemId={activeTab}
                    mode="secondary"
                    onChange={handleTabChange}
                    containerCorrections={TABS_CORRECTIONS}
                    disableContainerButtons
                >
                    {tabs.map(({ name, counters }) => (
                        <Tab
                            key={name}
                            id={name}
                            postfix={getTabPostfix(counters)}
                            onClick={() => {
                                Analytics.sendHHEventButtonClick(getAnalyticsName(name), analyticsParams);
                            }}
                            // Убрать после добавления баджа в секондари-табы магрита
                            data-qa={counters?.new ? 'tab-has-news' : undefined}
                        >
                            {getTitle(name)}
                        </Tab>
                    ))}
                </Tabs>
            </div>
            <div className={styles.widgetTabContent} ref={tabsContentRef}>
                <TabsContent activeTab={activeTab} containerRef={tabsContentRef} />
            </div>
        </Card>
    );
};

export default translation(EmployerResumeSearchesWidget);
