import { Link, Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ServiceCard from 'src/components/EmployerServicesWidget/ServiceCard';
import ServiceCardIcon from 'src/components/EmployerServicesWidget/ServiceCardIcon';
import translation from 'src/components/translation';
import { EmployerServiceWidget } from 'src/models/employerServicesWidget';

const TrlKeys = {
    title: 'employer.index.servicesWidget.hrLink.title',
    connect: 'employer.index.servicesWidget.connect',
    connected: 'employer.index.servicesWidget.connected',
};

const HrlinkServiceCard: TranslatedComponent<EmployerServiceWidget> = ({ trls, active, name }) => {
    return (
        <ServiceCard
            name={name}
            isActive={active}
            title={trls[TrlKeys.title]}
            icon={<ServiceCardIcon isActive={active} name="hrlink" />}
        >
            {active ? (
                <Text typography="subtitle-1-semibold">{trls[TrlKeys.connected]}</Text>
            ) : (
                <Link typography="label-2-regular">{trls[TrlKeys.connect]}</Link>
            )}
        </ServiceCard>
    );
};

export default translation(HrlinkServiceCard);
