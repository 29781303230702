import Analytics from '@hh.ru/analytics-js';
import { Button } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import routePaths from 'src/app/routePaths';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    addButton: 'employer.index.balanceWidget.balance.addButton',
};

const TopupButton: TranslatedComponent = ({ trls }) => {
    const employerIndexPage = useSelector((state) => state.employerIndexPage);
    const hasPaymentFeature = employerIndexPage?.oldEmployer?.hasPaymentFeature;

    const handleClick = () => {
        Analytics.sendHHEventButtonClick('balance_widget_card_button_plus_click');
    };

    return (
        <Button
            Element={SPALink}
            to={routePaths.employerInvoicePayment}
            disabled={!hasPaymentFeature}
            onClick={handleClick}
            mode="secondary"
            size="small"
            style="neutral"
            stretched
        >
            {trls[TrlKeys.addButton]}
        </Button>
    );
};

export default translation(TopupButton);
