import { Link, Text } from '@hh.ru/magritte-ui';
import { HhBrandSize24, HhGrayscaleSize24 } from '@hh.ru/magritte-ui/service';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ServiceCard from 'src/components/EmployerServicesWidget/ServiceCard';
import { useHandleAnalyticsCardClick } from 'src/components/EmployerServicesWidget/useHandleAnalyticsCardClick';
import translation from 'src/components/translation';
import { EmployerServiceWidget } from 'src/models/employerServicesWidget';

const TrlKeys = {
    title: 'employer.index.servicesWidget.hhEmployees.title',
    connected: 'employer.index.servicesWidget.connected',
    connect: 'employer.index.servicesWidget.connect',
};

const HRSPACE_CONNECTED_LINK =
    'https://sotrudniki.hh.ru/ ?utm_source=hh.ru&utm_medium=referral&utm_campaign=main_widget_connected_service';
const HRSPACE_NOT_CONNECTED_LINK =
    'https://sotrudniki.hh.ru/ ?utm_source=hh.ru&utm_medium=referral&utm_campaign=main_widget_unconnected_service  ';

const HRSpaceServiceCard: TranslatedComponent<EmployerServiceWidget> = ({ trls, active, name }) => {
    const handleClick = useHandleAnalyticsCardClick({ name, active });

    const card = (
        <ServiceCard
            name={name}
            isActive={active}
            title={trls[TrlKeys.title]}
            icon={active ? <HhBrandSize24 /> : <HhGrayscaleSize24 />}
        >
            {active ? (
                <Text typography="subtitle-1-semibold">{trls[TrlKeys.connected]}</Text>
            ) : (
                <Link
                    target="_blank"
                    onClick={handleClick}
                    href={HRSPACE_NOT_CONNECTED_LINK}
                    typography="label-2-regular"
                >
                    {trls[TrlKeys.connect]}
                </Link>
            )}
        </ServiceCard>
    );

    return active ? (
        <SPALink target="_blank" onClick={handleClick} to={HRSPACE_CONNECTED_LINK}>
            {card}
        </SPALink>
    ) : (
        card
    );
};

export default translation(HRSpaceServiceCard);
