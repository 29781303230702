import { ReactNode, RefObject, useState } from 'react';

import {
    Action,
    BottomSheet,
    Button,
    Link as MagritteLink,
    NavigationBar,
    useBreakpoint,
    VSpacing as MagritteVSpacing,
} from '@hh.ru/magritte-ui';
import {
    ChevronDownOutlinedSize16,
    ChevronLeftOutlinedSize24,
    ChevronUpOutlinedSize16,
    CrossOutlinedSize24,
} from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import Collapsible from 'src/components/IndexCollapsibleBlock/Collapsible';

import styles from './index-collapsible-block.less';

const TrlKeys = {
    open: 'index.collapsible.button.open',
    close: 'index.collapsible.button.close',
    showAll: 'index.collapsible.button.showAll',
};

interface IndexCollapsibleBlockProps {
    renderPreview: () => ReactNode;
    renderRest: () => ReactNode;
    needToExpand: boolean;
    renderBottomSheetContent: () => ReactNode;
    restSpacing?: ReactNode;
    bottomSheetTitle: string;
    bottomSheetSubtitle?: string;

    collapseCallback?: (ref: RefObject<HTMLDivElement> | null, expanded: boolean) => void;
    onBottomSheetClose?: () => void;
    forceBottomSheetVisible?: boolean;
    onBottomSheetClickBack?: () => void;
}

const IndexCollapsibleBlock: TranslatedComponent<IndexCollapsibleBlockProps> = ({
    renderPreview,
    renderRest,
    needToExpand,
    renderBottomSheetContent,
    bottomSheetTitle,
    bottomSheetSubtitle,
    restSpacing,
    trls,

    collapseCallback,
    onBottomSheetClose,
    onBottomSheetClickBack,
    forceBottomSheetVisible,
}) => {
    const [isAllVisible, setIsAllVisible] = useState(false);
    const [isBottomSheetVisible, setIsBottomSheetVisible] = useState(false);
    const { isXS } = useBreakpoint();
    return (
        <>
            <div className={styles.wrapper}>{renderPreview()}</div>
            {!isXS && needToExpand && (
                <>
                    <Collapsible expanded={isAllVisible} collapseCallback={collapseCallback}>
                        {restSpacing}
                        {renderRest()}
                    </Collapsible>
                    <MagritteVSpacing default={24} />
                    <MagritteLink
                        iconRight={isAllVisible ? <ChevronUpOutlinedSize16 /> : <ChevronDownOutlinedSize16 />}
                        onClick={() => setIsAllVisible(!isAllVisible)}
                        Element="button"
                    >
                        {trls[isAllVisible ? TrlKeys.close : TrlKeys.open]}
                    </MagritteLink>
                </>
            )}
            {isXS && (
                <>
                    <MagritteVSpacing default={16} gteS={20} gteM={24} />
                    <Button
                        mode="secondary"
                        style="neutral"
                        stretched
                        onClick={() => {
                            collapseCallback?.(null, true);
                            setIsBottomSheetVisible(true);
                        }}
                    >
                        {trls[TrlKeys.showAll]}
                    </Button>
                    <BottomSheet
                        visible={forceBottomSheetVisible || isBottomSheetVisible}
                        onClose={() => setIsBottomSheetVisible(false)}
                        header={
                            <NavigationBar
                                right={
                                    <Action
                                        icon={CrossOutlinedSize24}
                                        onClick={() => {
                                            setIsBottomSheetVisible(false);
                                            onBottomSheetClose?.();
                                        }}
                                    />
                                }
                                left={
                                    onBottomSheetClickBack && (
                                        <Action
                                            mode="secondary"
                                            onClick={onBottomSheetClickBack}
                                            icon={ChevronLeftOutlinedSize24}
                                        />
                                    )
                                }
                                title={bottomSheetTitle}
                                subtitle={bottomSheetSubtitle}
                                showDivider="always"
                            />
                        }
                    >
                        {renderBottomSheetContent()}
                    </BottomSheet>
                </>
            )}
        </>
    );
};

export default translation(IndexCollapsibleBlock);
