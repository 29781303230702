import { useSelector } from 'src/hooks/useSelector';

const useVacancyStatBanner = () => {
    const data = useSelector(({ vacancyStatBannerData }) => vacancyStatBannerData);

    return {
        hasContent: data && data.managersCount > 0,
        managersCount: data?.managersCount ?? 0,
    };
};

export default useVacancyStatBanner;
