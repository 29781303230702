// tempexp_27495_file
import { useLayoutEffect, useMemo } from 'react';

import { useSelector } from '@hh.ru/front-static-app';
import { VSpacingContainer, Text as MagritteText } from '@hh.ru/magritte-ui';
import Text, { TextImportance, TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import { useSignupTrlsContextContext } from 'src/components/SignupModal/contexts/SignupTrls';
import VerificationForm, { VerificationFormBaseProps } from 'src/components/Verification/VerificationForm';
import translation from 'src/components/translation';

import styles from './verification-step.less';

const TrlKeys = {
    header: 'signUpForm.verification.header',
    phone: {
        sent: 'signUpForm.verification.codeSent.phone',
        sentShort: 'otp.confirm.header',
        description1: 'signUpForm.verification.codeSentDescription.1.phone',
        description2: 'signUpForm.verification.codeSentDescription.2.phone',
    },
    email: {
        sent: 'signUpForm.verification.codeSent.email',
        sentShort: 'otp.confirm.header',
        description: 'otp.confirm.sub.header',
        descriptionShort: 'otp.confirm.sub.header.short',
    },
    whatsApp: {
        sent: 'signUpForm.verification.codeSent.whatsApp',
        description: 'signUpForm.verification.codeSent.whatsApp.description',
    },
};

export interface VerificationStepProps extends VerificationFormBaseProps {
    onCancel?: () => void;
    cancelText?: string;
    codePlaceholder?: string;
    errorPageLogin?: boolean;
}

const VerificationStep: TranslatedComponent<VerificationStepProps> = ({
    trls,
    errorPageLogin,
    codePlaceholder,
    onCancel,
    cancelText,
    ...formProps
}) => {
    const { authType, login } = formProps;
    const isAnonymousMagritteExp = useSelector(({ isAnonymousMagritteExp }) => isAnonymousMagritteExp);
    const { updateHeader } = useSignupTrlsContextContext();

    const header = useMemo(() => {
        switch (authType) {
            case 'PHONE_CALL':
            case 'SMS':
                return trls[isAnonymousMagritteExp ? TrlKeys.phone.sentShort : TrlKeys.phone.sent];
            case 'WHATS_APP':
                return trls[TrlKeys.whatsApp.sent];
            default:
                return trls[isAnonymousMagritteExp ? TrlKeys.email.sentShort : TrlKeys.email.sent];
        }
    }, [authType, isAnonymousMagritteExp, trls]);

    const title = useMemo(() => {
        if (isAnonymousMagritteExp) {
            return formatToReactComponent(header, {
                '{0}': <strong>{login}</strong>,
            });
        }

        return trls[TrlKeys.header];
    }, [isAnonymousMagritteExp, trls, header, login]);

    const description = useMemo(() => {
        switch (authType) {
            case 'PHONE_CALL':
            case 'SMS':
                return (
                    <>
                        <p>{trls[TrlKeys.phone.description1]}</p>
                        <p>{trls[TrlKeys.phone.description2]}</p>
                    </>
                );
            case 'WHATS_APP':
                return trls[TrlKeys.whatsApp.description];
            default:
                return isAnonymousMagritteExp ? trls[TrlKeys.email.descriptionShort] : trls[TrlKeys.email.description];
        }
    }, [authType, isAnonymousMagritteExp, trls]);

    const subtitle = useMemo(() => {
        return (
            <>
                {!isAnonymousMagritteExp && (
                    <>
                        <p>
                            {formatToReactComponent(header, {
                                '{0}': <strong>{login}</strong>,
                            })}
                        </p>
                        <VSpacing base={4} />
                    </>
                )}
                {description}
            </>
        );
    }, [isAnonymousMagritteExp, header, login, description]);

    useLayoutEffect(() => {
        updateHeader({ title, subtitle });
    }, [title, subtitle, updateHeader]);

    return (
        <VerificationForm
            render={({ renderInput, renderCodeError, renderCodeSender, renderSubmit }) =>
                isAnonymousMagritteExp ? (
                    <VSpacingContainer default={24}>
                        <div className={styles.field}>
                            <div className={styles.input}>
                                {renderInput({
                                    placeholder: codePlaceholder,
                                    isAutofocus: true,
                                })}
                                {renderCodeError()}
                            </div>
                            <div className={styles.submit}>{renderSubmit({ isStretched: true })}</div>
                        </div>
                        <MagritteText>{renderCodeSender()}</MagritteText>
                    </VSpacingContainer>
                ) : (
                    <div>
                        <>
                            <Text size={TextSize.Large} strong>
                                {title}
                            </Text>
                            <VSpacing base={1} />
                            <Text size={TextSize.Medium} importance={TextImportance.Secondary}>
                                {subtitle}
                            </Text>
                        </>
                        <VSpacing base={4} />
                        <div className={styles.formContainer}>
                            <div className={styles.inputWrapper}>
                                {renderInput({
                                    placeholder: codePlaceholder,
                                    isAutofocus: true,
                                })}
                            </div>
                            {renderCodeError()}
                            <VSpacing base={6} />
                            <div className={styles.buttonWrapper}>{renderSubmit({ isStretched: true })}</div>
                        </div>
                        <div>
                            <VSpacing base={3} />
                            <Text importance={TextImportance.Tertiary}>{renderCodeSender()}</Text>
                        </div>
                    </div>
                )
            }
            {...formProps}
        />
    );
};

export default translation(VerificationStep);
