import { Link, Text } from '@hh.ru/magritte-ui';
import { AstrostarOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ServiceCard from 'src/components/EmployerServicesWidget/ServiceCard';
import translation from 'src/components/translation';
import { EmployerServiceWidget } from 'src/models/employerServicesWidget';

const TrlKeys = {
    title: 'employer.index.servicesWidget.branding.title',
    connected: 'employer.index.servicesWidget.connected',
    connect: 'employer.index.servicesWidget.branding.connect',
};

const BrandingServiceCard: TranslatedComponent<EmployerServiceWidget> = ({ trls, active, name }) => {
    return (
        <ServiceCard
            name={name}
            isActive={active}
            title={trls[TrlKeys.title]}
            icon={<AstrostarOutlinedSize24 initialColor={active ? 'warning' : 'secondary'} />}
        >
            {active ? (
                <Text typography="subtitle-1-semibold">{trls[TrlKeys.connected]}</Text>
            ) : (
                <Link typography="label-2-regular">{trls[TrlKeys.connect]}</Link>
            )}
        </ServiceCard>
    );
};

export default translation(BrandingServiceCard);
