import {
    ResumeSearchesWidgetTab,
    ResumeSearchesWidgetTabName,
    ResumeSearchesWidget,
} from 'src/models/employer/resumeSearchesWidget/types';

export const getAnalyticsCounters = (tabs: ResumeSearchesWidgetTab[]) => {
    return tabs.reduce<Record<string, string>>((acc, item) => {
        if (!item.counters) {
            return acc;
        }

        if (item.counters.new) {
            acc[item.name] = `+${item.counters.new}`;
        } else if (item.counters.all) {
            acc[item.name] = item.counters.all?.toString();
        }

        return acc;
    }, {});
};

export const getAnalyticsName = (tabName: string): string => `resume_searches_widget_${tabName}`;

export const getAnalyticsTabs = (tabs: ResumeSearchesWidgetTab[]): string[] => {
    return tabs.map((tab) => tab.name);
};

export const hasContent = (
    activeTab: ResumeSearchesWidgetTabName,
    { targetResumes, favoriteResumes, savedSearches }: ResumeSearchesWidget
): boolean => {
    switch (activeTab) {
        case 'targetResumes':
            return targetResumes.items.length > 0;
        case 'favoriteResumes':
            return favoriteResumes.items.length > 0;
        case 'savedSearches':
            return savedSearches.items.length > 0;
        default:
            return true;
    }
};
