import { Dispatch, SetStateAction } from 'react';

import { Cell, CellText, Checkbox, CheckableCard, Text, VSpacingContainer } from '@hh.ru/magritte-ui';
import { DriverLicenseType } from '@hh.ru/types-hh-microcore';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { type DriverLicenses } from 'src/components/ResumeAdditionalDataCollector/types';
import translation from 'src/components/translation';
import { DEFAULT_DRIVER_LICENSE_TYPE_ORDER } from 'src/models/dictionariesDefaultOrder';

import styles from './styles.less';

const TrlKeys = {
    driverLicenseSubtitle: 'resumeAdditionalDataCollector.driverLicenseSubtitle',
    driverLicenseType: 'resumeAdditionalDataCollector.driverLicenseType',
    hasVehicle: 'resumeAdditionalDataCollector.hasVehicle',
    driverLicense: {
        [DriverLicenseType.A]: 'driverLicenseTypes.A',
        [DriverLicenseType.B]: 'driverLicenseTypes.B',
        [DriverLicenseType.C]: 'driverLicenseTypes.C',
        [DriverLicenseType.D]: 'driverLicenseTypes.D',
        [DriverLicenseType.E]: 'driverLicenseTypes.E',
        [DriverLicenseType.BE]: 'driverLicenseTypes.BE',
        [DriverLicenseType.CE]: 'driverLicenseTypes.CE',
        [DriverLicenseType.DE]: 'driverLicenseTypes.DE',
        [DriverLicenseType.TM]: 'driverLicenseTypes.TM',
        [DriverLicenseType.TB]: 'driverLicenseTypes.TB',
    },
};

interface DriverLicenseProps {
    isSaving: boolean;
    hasVehicle: boolean;
    setHasVehicle: Dispatch<SetStateAction<boolean>>;
    driverLicenses: DriverLicenses;
    setDriverLicenses: Dispatch<SetStateAction<DriverLicenses>>;
}

const DriverLicense: TranslatedComponent<DriverLicenseProps> = ({
    trls,
    isSaving,
    hasVehicle,
    setHasVehicle,
    driverLicenses,
    setDriverLicenses,
}) => {
    const handleHasVehicleChange = () => {
        setHasVehicle((currentHasVehicle) => !currentHasVehicle);
    };

    const handleDriverLicenseChange = (driverLicenseType: DriverLicenseType) => {
        setDriverLicenses((currentDriverLicenses) => ({
            ...currentDriverLicenses,
            [driverLicenseType]: !currentDriverLicenses[driverLicenseType],
        }));
    };

    return (
        <VSpacingContainer default={24}>
            <Text Element="div" style="secondary">
                {trls[TrlKeys.driverLicenseSubtitle]}
            </Text>
            <div className={styles.hasVehicleWrapper}>
                <CheckableCard
                    flexible
                    disabled={isSaving}
                    checked={hasVehicle}
                    aria-label={trls[TrlKeys.hasVehicle]}
                    borderRadius={12}
                    name="hasVehicle"
                    padding={16}
                    type="checkbox"
                    readOnly
                    onClick={handleHasVehicleChange}
                >
                    <Cell right={<Checkbox disabled={isSaving} checked={hasVehicle} readOnly />}>
                        <CellText>{trls[TrlKeys.hasVehicle]}</CellText>
                    </Cell>
                </CheckableCard>
            </div>
            <VSpacingContainer default={12}>
                <Text style="secondary">{trls[TrlKeys.driverLicenseType]}</Text>
                <div className={styles.driverLicensesWrapper}>
                    {DEFAULT_DRIVER_LICENSE_TYPE_ORDER.map((driverLicenseType) => {
                        const driverLicenseTrl = trls[TrlKeys.driverLicense[driverLicenseType]];
                        const isChecked = driverLicenses[driverLicenseType] ?? false;

                        return (
                            <CheckableCard
                                key={driverLicenseType}
                                flexible
                                disabled={isSaving}
                                checked={isChecked}
                                aria-label={driverLicenseTrl}
                                borderRadius={12}
                                name={driverLicenseType}
                                padding={16}
                                type="checkbox"
                                readOnly
                                onClick={() => handleDriverLicenseChange(driverLicenseType)}
                            >
                                <Cell right={<Checkbox disabled={isSaving} checked={isChecked} readOnly />}>
                                    <CellText>{driverLicenseTrl}</CellText>
                                </Cell>
                            </CheckableCard>
                        );
                    })}
                </div>
            </VSpacingContainer>
        </VSpacingContainer>
    );
};

export default translation(DriverLicense);
