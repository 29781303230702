import { Fragment, ReactElement } from 'react';

import buttonClick, { ButtonName } from '@hh.ru/analytics-js-events/build/xhh/applicant/main/button_click';
import { Card, Cell, CellText, Divider, CellRightLabel, Text, useBreakpoint, VSpacing } from '@hh.ru/magritte-ui';
import {
    EnvelopeOutlinedSize24,
    EyeOutlinedSize24,
    HeartOutlinedSize24,
    MagnifierOutlinedHeartOutlinedSize24,
} from '@hh.ru/magritte-ui/icon';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import styles from './event-list.less';

const TrlKeys = {
    header: 'index.headers.events',
    letters: 'index.events.letters',
    lettersMobile: 'index.events.letters.mobile',
    views: 'index.events.views',
    viewsMobile: 'index.events.views.mobile',
    selectedVacancies: 'index.events.selectedVacancies',
    selectedVacanciesMobile: 'index.events.selectedVacancies.mobile',
    autosearches: 'index.events.autosearches',
    moreThenOneThousand: 'index.events.morethan1k',
    zero: 'applicant.counters.zero',
    countersViews: {
        one: 'applicant.counters.views.one',
        some: 'applicant.counters.views.some',
        many: 'applicant.counters.views.many',
    },
    countersResponses: {
        one: 'applicant.counters.responses.one',
        some: 'applicant.counters.responses.some',
        many: 'applicant.counters.responses.many',
    },
    countersSavedSearches: {
        one: 'applicant.counters.savedSearches.one',
        some: 'applicant.counters.savedSearches.some',
        many: 'applicant.counters.savedSearches.many',
    },
};

const EventList: TranslatedComponent = ({ trls }) => {
    const userStats = useSelector((state) => state.userStats);
    const favoriteVacanciesCount = useSelector((state) => state.favoriteVacanciesCount) || 0;
    const savedVacancySearch = useSelector((state) => state.savedVacancySearch);
    const { isGtS, isMobile: isMobileBreakpoint } = useBreakpoint();

    const getInvitationsCount = (): string | ReactElement => {
        const applicantInvitations = userStats?.['applicant-invitations'];
        const newApplicantInvitations = userStats?.['new-applicant-invitations'];
        if (newApplicantInvitations && newApplicantInvitations > 0) {
            return <span className={styles.countNew}>+{newApplicantInvitations}</span>;
        }
        if (applicantInvitations !== undefined) {
            return applicantInvitations > 999 ? '999+' : String(applicantInvitations || '');
        }
        return '';
    };

    const getResumeViewsCount = (): string | ReactElement => {
        const resumesViews = userStats?.['resumes-views'];
        const newResumesViews = userStats?.['new-resumes-views'];
        if (newResumesViews && newResumesViews > 0) {
            return <span className={styles.countNew}>+{newResumesViews}</span>;
        }
        return String(resumesViews || '');
    };

    const getAutosearchesCount = () => {
        if (isMobileBreakpoint) {
            return String(savedVacancySearch?.totalItems || '');
        }
        return '';
    };

    const eventsMap = {
        invitations: {
            link: '/applicant/negotiations',
            modifier: 'letters',
            name: trls[TrlKeys.letters],
            nameMobile: trls[TrlKeys.lettersMobile],
            icon: EnvelopeOutlinedSize24,
            count: getInvitationsCount(),
        },
        applicantResumes: {
            link: '/applicant/resumes',
            modifier: 'views',
            name: trls[TrlKeys.views],
            nameMobile: trls[TrlKeys.viewsMobile],
            icon: EyeOutlinedSize24,
            count: getResumeViewsCount(),
        },
        favoriteVacancies: {
            link: '/applicant/favorite_vacancies',
            modifier: 'selected-vacancies',
            name: trls[TrlKeys.selectedVacancies],
            nameMobile: trls[TrlKeys.selectedVacanciesMobile],
            icon: HeartOutlinedSize24,
            count: String(favoriteVacanciesCount || ''),
        },
        autosearch: {
            link: '/applicant/autosearch.xml',
            modifier: 'autosearches',
            name: trls[TrlKeys.autosearches],
            nameMobile: trls[TrlKeys.autosearches],
            icon: MagnifierOutlinedHeartOutlinedSize24,
            count: getAutosearchesCount(),
        },
    };

    let events;
    if (isGtS) {
        events = [eventsMap.invitations, eventsMap.applicantResumes, eventsMap.favoriteVacancies, eventsMap.autosearch];
    } else {
        events = [eventsMap.applicantResumes, eventsMap.invitations, eventsMap.autosearch];
    }

    const handleEventClick = (type: string) => {
        let buttonName: ButtonName = 'favorite_vacancy_list_dashboard';
        if (type === 'views') {
            buttonName = 'resume_view_history_dashboard';
        } else if (type === 'letters') {
            buttonName = 'negotiation_list_dashboard';
        } else if (type === 'autosearches') {
            buttonName = 'vacancy_autosearch_list_dashboard';
        }
        buttonClick({ buttonName });
    };

    return (
        <Card borderWidth="default" padding={isGtS ? 24 : 16} borderRadius={isGtS ? 24 : 16} stretched>
            <div className={styles.listWrapper}>
                {events.map((event, index) => {
                    const Icon = event.icon;
                    if (isGtS) {
                        return (
                            <SPALink to={event.link} key={event.link} onClick={() => handleEventClick(event.modifier)}>
                                <Cell left={<Icon />} right={<CellRightLabel hideIcon>{event.count}</CellRightLabel>}>
                                    <CellText type="subtitle" style="primary">
                                        {event.name}
                                    </CellText>
                                </Cell>
                            </SPALink>
                        );
                    }
                    return (
                        <Fragment key={event.link}>
                            {index !== 0 && <Divider mode="vertical" length={48} />}
                            <SPALink
                                to={event.link}
                                className={styles.listItem}
                                onClick={() => handleEventClick(event.modifier)}
                            >
                                <Text typography="label-3-regular" style="secondary">
                                    {event.nameMobile}
                                </Text>
                                <VSpacing default={6} />
                                <Text typography="label-2-regular" style="primary">
                                    {event.count || '0'}
                                </Text>
                            </SPALink>
                        </Fragment>
                    );
                })}
            </div>
        </Card>
    );
};

export default translation(EventList);
