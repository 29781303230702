import { useRef, useEffect } from 'react';

import Analytics from '@hh.ru/analytics-js';
import dashboardVacancyFinishVacancyButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/vacancy_builder/dashboard_vacancy_finish_vacancy_button_click';
import dashboardVacancyPublishVacancyButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/vacancy_builder/dashboard_vacancy_publish_vacancy_button_click';
import { VSpacing, Text, Button, useBreakpoint } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import numberFormatter from 'bloko/common/numberFormatter';
import { format, formatToReactComponent } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import AbstractVacancy, {
    VacancyStatusKind,
} from 'src/components/IndexPageNewEmployer/components/DashboardVacancy/AbstractVacancy';
import LinkInfo from 'src/components/IndexPageNewEmployer/components/DashboardVacancy/LinkInfo';

import styles from './dashboard-vacancy.less';

const VACANCY_FULLNESS_LEVEL = {
    LOW: 30,
    MEDIUM: 60,
    COMPLETE: 100,
};

const calcVacancyStatus = (completePercentage) => {
    if (completePercentage <= VACANCY_FULLNESS_LEVEL.LOW) {
        return VacancyStatusKind.negative;
    }

    if (completePercentage > VACANCY_FULLNESS_LEVEL.LOW && completePercentage < VACANCY_FULLNESS_LEVEL.COMPLETE) {
        return VacancyStatusKind.warning;
    }

    return VacancyStatusKind.positive;
};

const RecentDraftVacancy = ({
    trls,
    vacancy: { draftId, name, completedFieldsPercentage, suitableResumesCountTotal, suitableResumesCountLastMonth },
}) => {
    const recentDraftRef = useRef();
    const vacancyStatus = calcVacancyStatus(completedFieldsPercentage);
    const tagText =
        vacancyStatus === VacancyStatusKind.positive
            ? trls[RecentDraftVacancy.trls.readyToPublish]
            : format(trls[RecentDraftVacancy.trls.notReady], { '{0}': completedFieldsPercentage });

    const isReadyToPublish = completedFieldsPercentage === VACANCY_FULLNESS_LEVEL.COMPLETE;
    const { isXS } = useBreakpoint();

    useEffect(() => {
        Analytics.sendHHEventElementShown(recentDraftRef.current, {
            name: isReadyToPublish ? 'dashboard_vacancy_third_state' : 'dashboard_vacancy_second_state',
        });
    }, [recentDraftRef, isReadyToPublish]);

    return (
        <AbstractVacancy name={name} statusKind={vacancyStatus} tagText={tagText}>
            <div ref={recentDraftRef} data-qa="dashboard-recent-draft">
                <Text typography="label-2-regular">{trls[RecentDraftVacancy.trls.recentVacancySuggestTitle]}</Text>
                <VSpacing default={24} />
                <ul>
                    <li className={styles.dashboardVacancyListItem}>
                        <Text typography="label-2-regular" Element="span">
                            {formatToReactComponent(trls[RecentDraftVacancy.trls.recentVacancyFirstSuggestText], {
                                '{0}': (
                                    <LinkInfo
                                        tooltipContent={trls[RecentDraftVacancy.trls.recentVacancyFirstSuggestInfoText]}
                                        data-qa="dashboard-recent-draft__suitable-applicants"
                                    >
                                        {numberFormatter.format(`${suitableResumesCountTotal}`, {
                                            decimalLength: 0,
                                            groupSeparator: NON_BREAKING_SPACE,
                                        })}
                                    </LinkInfo>
                                ),
                            })}
                        </Text>
                    </li>
                    <li className={styles.dashboardVacancyListItem}>
                        <Text typography="label-2-regular" Element="span">
                            {formatToReactComponent(trls[RecentDraftVacancy.trls.recentVacancySecondSuggestText], {
                                '{0}': (
                                    <LinkInfo
                                        tooltipContent={
                                            trls[RecentDraftVacancy.trls.recentVacancySecondSuggestInfoText]
                                        }
                                        data-qa="dashboard-recent-draft__looking-for-work"
                                    >
                                        {numberFormatter.format(`${suitableResumesCountLastMonth}`, {
                                            decimalLength: 0,
                                            groupSeparator: NON_BREAKING_SPACE,
                                        })}
                                    </LinkInfo>
                                ),
                            })}
                        </Text>
                    </li>
                </ul>
                {isReadyToPublish && (
                    <>
                        <VSpacing default={24} />
                        <ul>
                            <li className={styles.dashboardVacancyListItem}>
                                <Text typography="label-2-regular" Element="span">
                                    {trls[RecentDraftVacancy.trls.recentVacancyThirdSuggestText]}
                                </Text>
                            </li>
                            <li className={styles.dashboardVacancyListItem}>
                                <Text typography="label-2-regular" Element="span">
                                    {trls[RecentDraftVacancy.trls.recentVacancyFoursSuggestText]}
                                </Text>
                            </li>
                        </ul>
                    </>
                )}
            </div>
            <VSpacing default={24} />
            <Button
                Element={SPALink}
                to={`/employer/vacancy/create?draftId=${draftId}`}
                onClick={
                    isReadyToPublish
                        ? () => dashboardVacancyPublishVacancyButtonClick({ draftId })
                        : () => dashboardVacancyFinishVacancyButtonClick({ draftId })
                }
                data-qa={`dashboard-recent-draft__${isReadyToPublish ? 'publish_vacancy' : 'continue_vacancy_filling'}`}
                style="accent"
                mode="primary"
                size="medium"
                stretched={isXS}
            >
                {isReadyToPublish
                    ? trls[RecentDraftVacancy.trls.publishVacancy]
                    : trls[RecentDraftVacancy.trls.continueVacancyFilling]}
            </Button>
        </AbstractVacancy>
    );
};

RecentDraftVacancy.trls = {
    notReady: 'newemployer.dashboard.recentVacancy.status.notReady',
    readyToPublish: 'newemployer.dashboard.recentVacancy.status.readyToPublish',
    recentVacancySuggestTitle: 'newemployer.dashboard.recentVacancy.suggestTitle',
    recentVacancyFirstSuggestText: 'newemployer.dashboard.recentVacancy.suggest.1.text',
    recentVacancyFirstSuggestInfoText: 'newemployer.dashboard.recentVacancy.suggest.1.infoText',
    recentVacancySecondSuggestText: 'newemployer.dashboard.recentVacancy.suggest.2.text',
    recentVacancySecondSuggestInfoText: 'newemployer.dashboard.recentVacancy.suggest.2.infoText',
    recentVacancyThirdSuggestText: 'newemployer.dashboard.recentVacancy.suggest.3.text',
    recentVacancyFoursSuggestText: 'newemployer.dashboard.recentVacancy.suggest.4.text',
    publishVacancy: 'newemployer.dashboard.recentVacancy.action.publishVacancy',
    continueVacancyFilling: 'newemployer.dashboard.recentVacancy.action.continueVacancyFilling',
};

export default translation(RecentDraftVacancy);
