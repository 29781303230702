import { ReactNode } from 'react';

import { Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import styles from './styles.less';

interface ServiceNameProps {
    icon: ReactNode;
    children: ReactNode;
}

const ServiceName: TranslatedComponent<ServiceNameProps> = ({ icon, children }) => {
    return (
        <div className={styles.container}>
            {icon}
            <Text typography="label-3-regular">{children}</Text>
        </div>
    );
};

export default translation(ServiceName);
