import { FC, PropsWithChildren } from 'react';

import { SPALink } from '@hh.ru/redux-spa-middleware';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';

import { getVacancyCompanyLink } from 'src/components/RainbowCatalog/utils';

interface VacancyCompanyLinkProps {
    vacancy: {
        id?: number;
        company: {
            id: number;
            department?: {
                '@code': string;
            };
        };
    };
}

const VacancyCompanyLink: FC<VacancyCompanyLinkProps & PropsWithChildren> = ({ vacancy, children }) => {
    if (vacancy.company.id !== undefined) {
        return (
            <BlokoLink Element={SPALink} to={getVacancyCompanyLink(vacancy)} kind={LinkKind.Secondary}>
                {children}
            </BlokoLink>
        );
    }
    return <>{children}</>;
};

export default VacancyCompanyLink;
