import Analytics from '@hh.ru/analytics-js';
import { Text } from '@hh.ru/magritte-ui';
import { WalletOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import routePaths from 'src/app/routePaths';
import ElementShownAnchor from 'src/components/ElementShownAnchor';
import BalanceCard from 'src/components/EmployerBalanceWidget/BalanceCard';
import FormatMoney from 'src/components/FormatMoney';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    title: 'employer.index.balanceWidget.balance.title',
    ariaLabel: 'employer.index.balanceWidget.addButton.aria-label',
};

const MainBalanceCard: TranslatedComponent = ({ trls }) => {
    const employerIndexPage = useSelector((state) => state.employerIndexPage);
    const billingSummary = employerIndexPage?.oldEmployer?.billingSummary;
    const hasPaymentFeature = employerIndexPage?.oldEmployer?.hasPaymentFeature;

    const isEmpty = !billingSummary || billingSummary.availableWithoutBlockedMoney === 0;

    const analyticParams = {
        card: 'main_balance',
        money: billingSummary?.availableWithoutBlockedMoney || 0,
    };

    const handleClick = () => {
        const event = isEmpty ? 'balance_widget_card_plus_click' : 'balance_widget_card_click';

        Analytics.sendHHEventButtonClick(event, analyticParams);
    };

    const handlePlusClick = () => {
        Analytics.sendHHEventButtonClick('balance_widget_card_plus_click', analyticParams);
    };

    const addUrl = hasPaymentFeature ? routePaths.employerInvoicePayment : undefined;

    return (
        <ElementShownAnchor fn={Analytics.sendHHEventElementShown} name="balance_widget_card" {...analyticParams}>
            <BalanceCard
                type="pinned"
                rightContent={<WalletOutlinedSize24 initialColor="secondary" />}
                title={trls[TrlKeys.title]}
                onPlusClick={handlePlusClick}
                addUrl={addUrl}
            >
                <SPALink onClick={handleClick} to={routePaths.accountHistory}>
                    <div>
                        <Text typography="subtitle-1-semibold">
                            {billingSummary?.currency ? (
                                <FormatMoney currency={billingSummary.currency}>
                                    {billingSummary.availableWithoutBlockedMoney}
                                </FormatMoney>
                            ) : (
                                0
                            )}
                        </Text>
                    </div>
                </SPALink>
            </BalanceCard>
        </ElementShownAnchor>
    );
};

export default translation(MainBalanceCard);
