import differenceInDays from 'date-fns/differenceInDays';

import { ServiceEmployerDescription } from 'src/models/oldEmployer/employerBillingSummary';

const DAYS_TO_SHOW_EXPIRE = 2;

export const isItemExpireSoon = (item: ServiceEmployerDescription) => {
    if (item.status === 'active') {
        const days = differenceInDays(new Date(item.expirationTime.date), new Date());

        return days <= DAYS_TO_SHOW_EXPIRE;
    }
    return false;
};
