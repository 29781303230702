import { ReactNode, useEffect } from 'react';

import { Title, useBreakpoint, VSpacing as VSpacingMagritte } from '@hh.ru/magritte-ui';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import AgreementText from 'src/components/ApplicantSignInSignUpFlow/AgreementText';
import SignInFormStep from 'src/components/ApplicantSignInSignUpFlow/SignInStep/SignInForm';
import AgreementTextMagritte from 'src/components/IndexPageAnonymous/SignUp/AgreementTextMagritte';
import Illustration from 'src/components/IndexPageAnonymous/SignUp/Illustration';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import styles from './sign-in-step.less';

const TrlKeys = {
    header: 'signup.header.phone',
    submit: 'signupForm.create.submit',
    inputPlaceholder: 'signup.placeholder.phoneNumber',
    headerMagritte: 'signup.header.phone.and.email',
};

export interface SignInStepProps {
    callbackSubmit?: () => void;
    next: () => void;
    setHeader: (headerContent: ReactNode) => void;
}

const SignInStep: TranslatedComponent<SignInStepProps> = ({ next, trls, callbackSubmit, setHeader }) => {
    const backurl = useSelector(({ authUrl }) => authUrl?.backurl);
    const isAnonymousMagritteExp = useSelector(({ isAnonymousMagritteExp }) => isAnonymousMagritteExp);
    const { isGtM, isMobile } = useBreakpoint();

    useEffect(() => {
        setHeader(
            <Text size={TextSize.Large} strong>
                {trls[TrlKeys.header]}
            </Text>
        );
    }, [setHeader, trls]);

    if (isAnonymousMagritteExp) {
        return (
            <div className={styles.box}>
                <div>
                    <div className={styles.title}>
                        <Title size={isMobile ? 'small' : 'medium'} Element="h3">
                            {trls[TrlKeys.headerMagritte]}
                        </Title>
                    </div>
                    <VSpacingMagritte default={24} />
                    <SignInFormStep
                        isAutofocusEnabled={false}
                        backurl={backurl}
                        next={next}
                        callbackSubmit={callbackSubmit}
                        render={({ renderLogin, renderSubmitButton, renderCaptcha }) => (
                            <div className={styles.wrapperMagritte}>
                                <div className={styles.wrapperForm}>
                                    <div className={styles.container}>
                                        <div className={styles.inputWrapper}>
                                            {renderLogin({
                                                placeholder: '',
                                            })}
                                        </div>
                                        <div className={styles.buttonWrapper}>
                                            {renderSubmitButton(trls[TrlKeys.submit], {
                                                scale: undefined,
                                            })}
                                        </div>
                                    </div>
                                    {renderCaptcha()}
                                    <VSpacingMagritte default={24} />
                                    <Text>
                                        <div className={styles.agreement}>
                                            <AgreementTextMagritte />
                                        </div>
                                    </Text>
                                </div>
                            </div>
                        )}
                    />
                </div>
                {isGtM ? <Illustration size="medium" className={styles.illustration} /> : null}
            </div>
        );
    }

    return (
        <>
            <SignInFormStep
                isAutofocusEnabled={false}
                backurl={backurl}
                next={next}
                callbackSubmit={callbackSubmit}
                render={({ renderLogin, renderSubmitButton, renderCaptcha }) => (
                    <div className={styles.wrapper}>
                        <div className={styles.container}>
                            <div className={styles.inputWrapper}>
                                {renderLogin({ placeholder: trls[TrlKeys.inputPlaceholder] })}
                            </div>
                            <div className={styles.buttonWrapper}>
                                {renderSubmitButton(trls[TrlKeys.submit], {
                                    scale: undefined,
                                })}
                            </div>
                        </div>
                        {renderCaptcha()}
                        <VSpacing base={8} xs={5} />
                        <Text>
                            <div className={styles.agreement}>
                                <AgreementText />
                            </div>
                        </Text>
                    </div>
                )}
            />
        </>
    );
};

export default translation(SignInStep);
