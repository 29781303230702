import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useSelector } from 'src/hooks/useSelector';
import {
    refetchFavoriteResumes,
    refetchSavedSearches,
    refetchTargetResumes,
} from 'src/models/employer/resumeSearchesWidget';

const useRefetch = () => {
    const { savedSearches, targetResumes, favoriteResumes } = useSelector((state) => state.resumeSearchesWidget);

    const dispatch = useDispatch();

    useEffect(() => {
        let abortController: AbortController;

        if (savedSearches.shouldRefetch) {
            abortController = new AbortController();
            void dispatch(refetchSavedSearches(abortController));
        }

        return () => {
            if (abortController) {
                abortController.abort();
            }
        };
    }, [dispatch, savedSearches.shouldRefetch]);

    useEffect(() => {
        let abortController: AbortController;

        if (targetResumes.shouldRefetch) {
            abortController = new AbortController();
            void dispatch(refetchTargetResumes(abortController));
        }

        return () => {
            if (abortController) {
                abortController.abort();
            }
        };
    }, [dispatch, targetResumes.shouldRefetch]);

    useEffect(() => {
        let abortController: AbortController;

        if (favoriteResumes.shouldRefetch) {
            abortController = new AbortController();
            void dispatch(refetchFavoriteResumes(abortController));
        }

        return () => {
            if (abortController) {
                abortController.abort();
            }
        };
    }, [dispatch, favoriteResumes.shouldRefetch]);
};

export default useRefetch;
