import { useCallback } from 'react';

import Analytics from '@hh.ru/analytics-js';

interface HandleAnalyticsCardClickArgs {
    active: boolean;
    name: string;
}

export const useHandleAnalyticsCardClick = ({ active, name }: HandleAnalyticsCardClickArgs): (() => void) => {
    return useCallback(() => {
        Analytics.sendHHEventButtonClick('services_widget_card', { active, card: name });
    }, [active, name]);
};
