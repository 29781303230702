import { DreamjobBrandSize24 } from '@hh.ru/magritte-ui/service';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import EmployerReviewsSmallWidget from 'src/components/EmployerReviews/WidgetSmall';
import ServiceCard from 'src/components/EmployerServicesWidget/ServiceCard';
import translation from 'src/components/translation';
import { EmployerServiceWidget } from 'src/models/employerServicesWidget';

const TrlKeys = {
    title: 'employer.index.servicesWidget.dreamJob.title',
};

const DreamjobServiceCard: TranslatedComponent<EmployerServiceWidget> = ({ trls, name }) => {
    return (
        <ServiceCard name={name} isActive title={trls[TrlKeys.title]} icon={<DreamjobBrandSize24 />}>
            <div>
                <EmployerReviewsSmallWidget onlyTrusted={false} />
            </div>
        </ServiceCard>
    );
};

export default translation(DreamjobServiceCard);
