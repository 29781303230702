import { ReactNode, useCallback, useState } from 'react';
import classnames from 'classnames';

import { Card, GridColumn, useBreakpoint } from '@hh.ru/magritte-ui';
import Column, { ColumnsWrapper } from 'bloko/blocks/column';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Form from 'src/components/IndexPageAnonymous/SignUp/Form';
import MagritteWrapper from 'src/components/MagritteWrapper/MagritteWrapper';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import styles from './styles.less';

const TrlKeys = {
    headerPhone: 'signup.header.phone',
};

const SignUpBanner: TranslatedComponent = ({ trls }) => {
    const logoDomain = useSelector(({ logos }) => logos.logoDomain);
    const isAnonymousMagritteExp = useSelector(({ isAnonymousMagritteExp }) => isAnonymousMagritteExp);
    const { isMobile } = useBreakpoint();

    const [headerContent, setHeaderContent] = useState<ReactNode>(
        !isAnonymousMagritteExp ? (
            <Text size={TextSize.Large} strong>
                {trls[TrlKeys.headerPhone]}
            </Text>
        ) : null
    );

    const setHeader = useCallback((content: ReactNode) => {
        setHeaderContent(content);
    }, []);

    if (isAnonymousMagritteExp) {
        return (
            <GridColumn xs={4} s={8} m={12} l={12}>
                <Card borderWidth="default" padding={isMobile ? 24 : 32} borderRadius={isMobile ? 24 : 32} stretched>
                    <MagritteWrapper isEnabled>
                        <div className={styles.container}>
                            <Form setHeader={setHeader} />
                        </div>
                    </MagritteWrapper>
                </Card>
            </GridColumn>
        );
    }

    return (
        <ColumnsWrapper>
            <VSpacing base={10} xs={0} />
            <Column xs="4" s="8" m="12" l="16">
                <div className={styles.wrapper}>
                    <div className={styles.wrapperSteps}>
                        <div
                            className={classnames(styles.logo, {
                                [styles.logoJobsTutBy]: logoDomain === 'jobs-tut-by',
                                [styles.logoZp]: logoDomain === 'zarplata-ru',
                            })}
                        />
                        <VSpacing base={8} xs={5} />
                        <div className={styles.stepDescription}>{headerContent}</div>
                    </div>
                    <div className={styles.wrapperContent}>
                        <div className={styles.content}>
                            <Form setHeader={setHeader} />
                        </div>
                    </div>
                </div>
            </Column>
        </ColumnsWrapper>
    );
};

export default translation(SignUpBanner);
