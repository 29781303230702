import { Link } from '@hh.ru/magritte-ui-link/Link';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

interface ShowAllLinkProps {
    href: string;
    onClick?: () => void;
    'data-qa'?: string;
}

const TrlKeys = {
    showMore: 'index.blog.showMore',
};

const ShowAllLink: TranslatedComponent<ShowAllLinkProps> = ({ href, trls, ...otherProps }) => {
    return (
        <Link {...otherProps} Element={SPALink} to={href} typography="label-2-regular" style="accent">
            {trls[TrlKeys.showMore]}
        </Link>
    );
};

export default translation(ShowAllLink);
