import Analytics, { ElementShownAnchor } from '@hh.ru/analytics-js';
import { Text } from '@hh.ru/magritte-ui';
import { ArrowRightOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import routePaths from 'src/app/routePaths';
import BalanceCard from 'src/components/EmployerBalanceWidget/BalanceCard';
import FormatMoney from 'src/components/FormatMoney';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { useIsHhKz } from 'src/hooks/useSites';

const TrlKeys = {
    title: 'employer.index.balanceWidget.hhEmployees.title',
};

const HHEmployeesCard: TranslatedComponent = ({ trls }) => {
    const employerIndexPage = useSelector(({ employerIndexPage }) => employerIndexPage);
    const currency = employerIndexPage?.oldEmployer?.billingSummary?.currency;
    const isKZ = useIsHhKz();

    const hhEmployersBalance = employerIndexPage?.oldEmployer?.balances?.hrsReserved;

    const isEmpty = !hhEmployersBalance || hhEmployersBalance === 0;
    const paymentLink = isKZ ? 'https://hrspace.hh.kz' : 'https://sotrudniki.hh.ru';

    const analyticParams = {
        card: 'hhEmployes_balance',
        money: hhEmployersBalance || 0,
    };

    const handleClick = () => {
        const event = isEmpty ? 'balance_widget_card_plus_click' : 'balance_widget_card_click';

        Analytics.sendHHEventButtonClick(event, analyticParams);
    };

    const card = (
        <BalanceCard
            addUrl={isEmpty ? paymentLink : undefined}
            type="drop"
            rightContent={<ArrowRightOutlinedSize24 initialColor="secondary" />}
            title={trls[TrlKeys.title]}
            onPlusClick={handleClick}
        >
            <Text typography="subtitle-1-semibold">
                {currency && typeof hhEmployersBalance === 'number' ? (
                    <FormatMoney currency={currency}>{hhEmployersBalance}</FormatMoney>
                ) : (
                    0
                )}
            </Text>
        </BalanceCard>
    );

    return (
        <ElementShownAnchor fn={Analytics.sendHHEventElementShown} name="balance_widget_card" {...analyticParams}>
            {isEmpty ? (
                card
            ) : (
                <SPALink onClick={handleClick} to={routePaths.accountHistory}>
                    {card}
                </SPALink>
            )}
        </ElementShownAnchor>
    );
};

export default translation(HHEmployeesCard);
