import { DriverLicenseType } from '@hh.ru/types-hh-microcore';

import { EmploymentLower } from 'src/utils/constants/employment';
import { ScheduleLowerCase } from 'src/utils/constants/schedule';

export const DEFAULT_EMPLOYMENT_ORDER = [
    EmploymentLower.Full,
    EmploymentLower.Part,
    EmploymentLower.Project,
    EmploymentLower.Volunteer,
    EmploymentLower.Probation,
];

export const DEFAULT_SCHEDULE_ORDER = [
    ScheduleLowerCase.FullDay,
    ScheduleLowerCase.Shift,
    ScheduleLowerCase.Flexible,
    ScheduleLowerCase.Remote,
    ScheduleLowerCase.FlyInFlyOut,
];

export const DEFAULT_DRIVER_LICENSE_TYPE_ORDER = [
    DriverLicenseType.A,
    DriverLicenseType.B,
    DriverLicenseType.C,
    DriverLicenseType.D,
    DriverLicenseType.E,
    DriverLicenseType.BE,
    DriverLicenseType.CE,
    DriverLicenseType.DE,
    DriverLicenseType.TM,
    DriverLicenseType.TB,
];
