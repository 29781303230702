import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import NumberFormatter from 'bloko/common/numberFormatter';

import Currency from 'src/components/Currency';
import translation from 'src/components/translation';
import { CurrencyType } from 'src/models/currencies.types';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

const TrlKeys = {
    salaryFrom: 'employer.resumesSearch.salaryFrom',
    salaryTo: 'employer.resumesSearch.salaryTo',
};

interface SalaryProps {
    salaryFrom?: number | null;
    salaryTo?: number | null;
    currencyType: CurrencyType;
}

const Salary: TranslatedComponent<SalaryProps> = ({ trls, salaryFrom, salaryTo, currencyType }) => {
    if (!salaryFrom && !salaryTo) {
        return null;
    }

    return (
        <>
            {salaryFrom && (
                <>
                    {trls[TrlKeys.salaryFrom]}
                    {NumberFormatter.format(`${salaryFrom}`, {
                        groupSeparator: NON_BREAKING_SPACE,
                    })}{' '}
                </>
            )}
            {salaryTo && (
                <>
                    {trls[TrlKeys.salaryTo]}
                    {NumberFormatter.format(`${salaryTo}`, {
                        groupSeparator: NON_BREAKING_SPACE,
                    })}{' '}
                </>
            )}
            <Currency value={currencyType} />
        </>
    );
};

export default translation(Salary);
