import Conversion from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import styles from './styles.less';

const TrlKeys = {
    today: 'employer.index.advicesWidget.today',
    tomorrow: 'employer.index.advicesWidget.tomorrow',
    some: 'employer.index.advicesWidget.days.some',
    many: 'employer.index.advicesWidget.days.many',
};

interface DaysLeftTextProps {
    days: number;
    capitalize?: boolean;
}

const DaysLeftText: TranslatedComponent<DaysLeftTextProps> = ({ days, trls, capitalize }) => {
    return (
        <Conversion
            zero={trls[TrlKeys.today]}
            one={trls[TrlKeys.tomorrow]}
            some={trls[TrlKeys.some]}
            many={trls[TrlKeys.many]}
            value={days}
            hasValue={days > 1}
            format={capitalize ? (value) => <span className={styles.capitalize}>{value}</span> : undefined}
        />
    );
};

export default translation(DaysLeftText);
