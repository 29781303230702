import { useLayoutEffect } from 'react';

import PageErrorWrapper from 'src/components/PageErrorWrapper';
import { PageHead, PageTitle, PageMeta, PageDescription, PageLink, PageHreflangLinks } from 'src/components/PageHead';

import DefaultLayout from 'src/app/layouts/BlokoLayouts/DefaultLayout';
import EmptyLayout from 'src/app/layouts/BlokoLayouts/EmptyLayout';
import HeaderLayout from 'src/app/layouts/BlokoLayouts/HeaderLayout';
import LightPageLayout from 'src/app/layouts/BlokoLayouts/LightPageLayout';
import SideBarLayout from 'src/app/layouts/BlokoLayouts/SideBarLayout';
import FullPageLayout from 'src/app/layouts/FullPageLayout';
import { PageLayoutWithCustomLayout } from 'src/app/layouts/PageLayout.types';
import { clearXSLMetaTags } from 'src/app/layouts/utils';

const RSS_MIME = 'application/rss+xml';

const PageLayout: PageLayoutWithCustomLayout = ({
    children,
    layout: LayoutComponent = DefaultLayout,
    errorWrapper: ErrorWrapperComponent = PageErrorWrapper,
    ogTitle,
    description,
    ogDescription,
    canonical,
    rssTitle,
    rssHref,
    removeTitle = false,
    afterMenuContent,
    useCleanContainer,
    ogUrl,
    ogType,
    ogImage,
    ogImageWidth,
    ogImageHeight,
    twitterImage,
    hreflangItems,
    robots,
    articleMeta,
    ...layoutProps
}) => {
    const { title } = layoutProps;

    useLayoutEffect(
        () => clearXSLMetaTags({ title, ogTitle, removeTitle, description, ogDescription, canonical }),
        [title, removeTitle, description, canonical, ogTitle, ogDescription]
    );

    const content = (
        <>
            <PageHead>
                {title && (
                    <>
                        <PageTitle>{title}</PageTitle>
                        <PageMeta property="og:title" content={ogTitle || title} />
                    </>
                )}
                {description && <PageDescription content={description} openGraphContent={ogDescription} />}
                {canonical && <PageLink rel="canonical" href={canonical} />}
                {rssHref && (title || rssTitle) && (
                    <PageLink title={rssTitle || title} type={RSS_MIME} rel="alternate" href={rssHref} />
                )}
                {ogUrl && <PageMeta property="og:url" content={ogUrl} />}
                {ogType && <PageMeta property="og:type" content={ogType} />}
                {ogImage && (
                    <>
                        <PageMeta property="og:image" content={ogImage} />
                        {ogImageWidth && <PageMeta property="og:image:width" content={String(ogImageWidth)} />}
                        {ogImageHeight && <PageMeta property="og:image:height" content={String(ogImageHeight)} />}
                    </>
                )}
                {twitterImage && <PageMeta property="twitter:image" content={twitterImage} />}
                {!!hreflangItems?.length && <PageHreflangLinks content={hreflangItems} />}
                {!!robots && <PageMeta name={robots.name} content={robots.content} />}
                {!!articleMeta && (
                    <>
                        {articleMeta.authorName && (
                            <PageMeta property="article:author" content={articleMeta.authorName} />
                        )}
                        {/* react-head does not allow multiple meta tags with simillar property values.
                        Following tags will actually render inside body. */}
                        {articleMeta.sections.map((section) => (
                            <meta key={section} property="article:section" content={section} />
                        ))}
                        {articleMeta.tags.map((tag) => (
                            <meta key={tag} property="article:tag" content={tag} />
                        ))}
                    </>
                )}
            </PageHead>
            <LayoutComponent {...layoutProps}>{children}</LayoutComponent>
        </>
    );

    return (
        <FullPageLayout afterMenuContent={afterMenuContent} useCleanContainer={useCleanContainer}>
            <ErrorWrapperComponent>{content}</ErrorWrapperComponent>
        </FullPageLayout>
    );
};

export { EmptyLayout, DefaultLayout, SideBarLayout, LightPageLayout, HeaderLayout };
export default PageLayout;
