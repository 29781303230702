import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ScrollableContainer, ScrollableControls } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { isNumber } from 'Utils/Utils';
import translation from 'src/components/translation';

import styles from './styles.less';

interface ScrollableContainerAutoProps {
    children: ReactNode;
    cardsCount: number;
}

const ScrollableContainerAuto: TranslatedComponent<ScrollableContainerAutoProps> = ({ children, cardsCount }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const controlsRef = useRef<ScrollableControls>(null);

    const [dot, setDot] = useState(1);
    const [progress, setProgress] = useState(0);
    const interval = useRef<ReturnType<typeof setInterval> | null>(null);

    const dotRef = useRef(1);
    dotRef.current = dot;

    const allowAutoScroll = useMemo(() => cardsCount > 1, [cardsCount]);

    const setNextItem = useCallback(() => {
        if (dotRef.current !== cardsCount) {
            controlsRef.current?.scrollRight();
        } else {
            controlsRef.current?.scrollToFirstItem();
        }
    }, [cardsCount]);

    const startTimerAutoScroll = useCallback(() => {
        if (!controlsRef?.current) {
            return;
        }
        interval.current = setInterval(() => {
            setProgress((progress) => {
                if (progress === 100) {
                    setNextItem();
                    return 0;
                }
                return progress + 1;
            });
        }, 70);
    }, [setNextItem]);

    const handleUseTimerAutoScroll = useCallback(() => {
        if (!interval.current) {
            setProgress(0);
            startTimerAutoScroll();
        }
    }, [startTimerAutoScroll]);

    const stopAuto = useCallback(() => {
        isNumber(interval.current) && clearInterval(interval.current);
        interval.current = null;
        setProgress(0);
    }, []);

    useEffect(() => {
        const container = containerRef.current;
        if (controlsRef?.current && allowAutoScroll) {
            handleUseTimerAutoScroll();
        }
        container?.addEventListener('mouseenter', stopAuto);
        allowAutoScroll && container?.addEventListener('mouseleave', handleUseTimerAutoScroll);
        return () => {
            container?.removeEventListener('mouseenter', stopAuto);
            container?.removeEventListener('mouseleave', handleUseTimerAutoScroll);
        };
    }, [allowAutoScroll, handleUseTimerAutoScroll, stopAuto]);

    return (
        <ScrollableContainer
            disableButtons
            ref={controlsRef}
            showDotPages
            onChangeDot={setDot}
            dotPagesProps={{
                activeDynamic: true,
                progressProps: {
                    progress,
                },
            }}
        >
            <div ref={containerRef} className={styles.container}>
                {children}
            </div>
        </ScrollableContainer>
    );
};

export default translation(ScrollableContainerAuto);
