import { Fragment, ReactNode } from 'react';

import { ScrollableItem, useBreakpoint } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import RenewalServiceWidget, { useRenewalServiceHasContent } from 'src/components/RenewalService/RenewalServiceWidget';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { AdviceItem } from 'src/models/employerAdvicesWidget';

import ScrollableContainerAuto from 'src/components/EmployerAdvicesWidget/ScrollableContainerAuto';
import ExpiringContactsResumeAccesses from 'src/components/EmployerAdvicesWidget/advices/ExpiringContactsResumeAccesses';
import ExpiringResumeAccesses from 'src/components/EmployerAdvicesWidget/advices/ExpiringResumeAccesses';
import ExpiringVacancies from 'src/components/EmployerAdvicesWidget/advices/ExpiringVacancies';
import LowActivityVacancies from 'src/components/EmployerAdvicesWidget/advices/LowActivityVacancies';
import LowSalary from 'src/components/EmployerAdvicesWidget/advices/LowSalary';
import MCPManagerUnreadResponses from 'src/components/EmployerAdvicesWidget/advices/MCPManagerUnreadResponses';
import ManagerUnreadResponses from 'src/components/EmployerAdvicesWidget/advices/ManagerUnreadResponses';
import NotVerified from 'src/components/EmployerAdvicesWidget/advices/NotVerified';
import OpenEmployerCongratulation from 'src/components/EmployerAdvicesWidget/advices/OpenEmployerCongratulation';
import RemainingAdvice from 'src/components/EmployerAdvicesWidget/advices/RemainingAdvice';
import TrialExtension from 'src/components/EmployerAdvicesWidget/advices/TrialExtension';
import UnusedPublications from 'src/components/EmployerAdvicesWidget/advices/UnusedPublications';
import WaitActivation from 'src/components/EmployerAdvicesWidget/advices/WaitActivation';
import WaitPayment from 'src/components/EmployerAdvicesWidget/advices/WaitPayment';
import WithoutReviews from 'src/components/EmployerAdvicesWidget/advices/WithoutReviews';

import styles from './styles.less';

const getAdviceElement = (advice: AdviceItem): ReactNode => {
    switch (advice.name) {
        case 'expiring_vacancies':
            return <ExpiringVacancies {...advice} />;
        case 'expiring_contacts_resume_accesses':
            return <ExpiringContactsResumeAccesses {...advice} />;
        case 'expiring_resume_accesses':
            return <ExpiringResumeAccesses {...advice} />;
        case 'trial_extension':
            return <TrialExtension {...advice} />;
        case 'unused_publications':
            return <UnusedPublications {...advice} />;
        case 'not_verified':
            return <NotVerified {...advice} />;
        case 'wait_activation':
            return <WaitActivation {...advice} />;
        case 'wait_payment':
            return <WaitPayment {...advice} />;
        case 'low_activity_vacancies':
            return <LowActivityVacancies {...advice} />;
        case 'manager_unread_responses':
            return <ManagerUnreadResponses {...advice} />;
        case 'mcp_manager_unread_responses':
            return <MCPManagerUnreadResponses {...advice} />;
        case 'low_salary':
            return <LowSalary {...advice} />;
        case 'open_employer_congratulation':
            return <OpenEmployerCongratulation {...advice} />;
        case 'without_reviews':
            return <WithoutReviews {...advice} />;
        default:
            return null;
    }
};

const EmployerAdvicesWidget: TranslatedComponent = () => {
    const { isGtS } = useBreakpoint();
    const advices = useSelector((state) => state.employerAdvicesWidget.advices);
    const isHasRenewalServices = useRenewalServiceHasContent();

    if (isHasRenewalServices) {
        return <RenewalServiceWidget />;
    }

    if (!advices.length) {
        if (isGtS) {
            return <RemainingAdvice />;
        }
        return null;
    }

    if (advices.length > 1) {
        return (
            <ScrollableContainerAuto cardsCount={advices.length}>
                {advices.map((advice) => {
                    return (
                        <ScrollableItem className={styles.itemContainer} key={advice.name}>
                            {getAdviceElement(advice)}
                        </ScrollableItem>
                    );
                })}
            </ScrollableContainerAuto>
        );
    }

    return (
        <div>
            {advices.map((advice) => {
                return <Fragment key={advice.name}>{getAdviceElement(advice)}</Fragment>;
            })}
        </div>
    );
};

export default translation(EmployerAdvicesWidget);
