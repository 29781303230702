import { useEffect, useState } from 'react';

import { SPALink } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind } from 'bloko/blocks/button';
import HSpacing from 'bloko/blocks/hSpacing';
import Modal, { ModalContent, ModalFooter, ModalTitle } from 'bloko/blocks/modal';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useIsRabotaBy } from 'src/hooks/useSites';
import fetcher from 'src/utils/fetcher';

import styles from './additionalCheck.less';

declare global {
    interface FetcherDeleteApi {
        '/employer/additional_check': {
            queryParams: unknown;
            response: void;
        };
    }
}

export const additionalCheckArticleLink = 'https://feedback.hh.ru/knowledge-base/article/0522';
export const additionalCheckArticleRabotaBy = 'https://rabota.by/article/33433';

const TrlKeys = {
    title: 'additionalCheck.title',
    warning: 'additionalCheck.warning',
    waiting: 'additionalCheck.waiting',
    article: 'additionalCheck.article',
    skip: 'additionalCheck.skip',
};

const AdditionalCheck: TranslatedComponent = ({ trls }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const isRabotaBy = useIsRabotaBy();

    const removeAdditionalCheckNote = async () => {
        await fetcher.delete('/employer/additional_check', { params: {} });
    };

    const additionalCheckOff = () => {
        setModalVisible(false);
        void removeAdditionalCheckNote();
    };

    useEffect(() => setModalVisible(true), [setModalVisible]);

    return (
        <>
            <Modal data-qa="additional-check-modal" visible={modalVisible} onClose={() => additionalCheckOff()}>
                <VSpacing base={2} />
                <div className={styles.additionalCheckLogo}></div>
                <ModalTitle>{trls[TrlKeys.title]}</ModalTitle>
                <VSpacing base={4} />
                <ModalContent>
                    <Text>{trls[TrlKeys.warning]}</Text>
                    <VSpacing base={2} />
                    <Text>{trls[TrlKeys.waiting]}</Text>
                </ModalContent>
                <ModalFooter>
                    <Button
                        data-qa="additional-check-modal-button-link"
                        to={isRabotaBy ? additionalCheckArticleRabotaBy : additionalCheckArticleLink}
                        Element={SPALink}
                        onClick={() => additionalCheckOff()}
                    >
                        {trls[TrlKeys.article]}
                    </Button>
                    <HSpacing base={2} />
                    <Button
                        data-qa="additional-check-modal-button-close"
                        kind={ButtonKind.Primary}
                        onClick={() => additionalCheckOff()}
                    >
                        {trls[TrlKeys.skip]}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default translation(AdditionalCheck);
