import { PfpInfo } from 'src/models/payForPerformance/pfpInfo';

export enum PfpBalanceWarning {
    LowBalance = 'lowBalance',
    ZeroBalance = 'zeroBalance',
}

export const getPfpBalanceWarning = (pfpInfo: PfpInfo): PfpBalanceWarning | null => {
    if (!pfpInfo.pfpDetails?.balance) {
        return null;
    }
    if (pfpInfo.pfpDetails.balance.possibleResponses === 0) {
        return PfpBalanceWarning.ZeroBalance;
    }
    if (pfpInfo.pfpDetails.lowBalance) {
        return PfpBalanceWarning.LowBalance;
    }
    return null;
};
