// tempexp_27495_file
import { FC } from 'react';

import { useSelector } from '@hh.ru/front-static-app';
import {
    Action,
    BottomSheet,
    Modal as MagritteModal,
    Title,
    useBreakpoint,
    VSpacing,
    VSpacingContainer,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import Modal from 'bloko/blocks/modal';

import MagritteWrapper from 'src/components/MagritteWrapper/MagritteWrapper';
import ApplicantSignInSignUpFlow from 'src/components/SignupModal/ApplicantSignInSignUpFlow';
import { useSignupTrlsContextContext } from 'src/components/SignupModal/contexts/SignupTrls';

import SignInStep from 'src/components/SignupModal/Form/steps/sign-in/SignInStep';
import SignUpStep from 'src/components/SignupModal/Form/steps/sign-up/SignUpStep';
import VerificationStep from 'src/components/SignupModal/Form/steps/verification/VerificationStep';

import styles from './sign-up-modal.less';

interface SignupModalProps {
    opened: boolean;
    onClose: () => void;
    onSignup: () => void;
    onSignIn: () => void;
}

const SignupModal: FC<SignupModalProps> = ({ opened, onClose, onSignup, onSignIn }) => {
    const isAnonymousMagritteExp = useSelector(({ isAnonymousMagritteExp }) => isAnonymousMagritteExp);
    const { header } = useSignupTrlsContextContext();
    const { isMobile } = useBreakpoint();

    if (isAnonymousMagritteExp) {
        const closeAction = <Action icon={CrossOutlinedSize24} mode="secondary" onClick={onClose} />;

        return (
            <MagritteWrapper isEnabled={true}>
                {isMobile ? (
                    <BottomSheet visible={opened} onClose={onClose}>
                        <VSpacingContainer default={32}>
                            <VSpacingContainer default={8}>
                                <header className={styles.header}>{closeAction}</header>
                                <Title Element="h2" size="medium" description={header.subtitle}>
                                    {header.title}
                                </Title>
                            </VSpacingContainer>

                            <ApplicantSignInSignUpFlow
                                SignIn={SignInStep}
                                SignUp={SignUpStep}
                                Verification={VerificationStep}
                                onSignup={onSignup}
                                onSignIn={onSignIn}
                            />
                        </VSpacingContainer>
                        <VSpacing default={16} />
                    </BottomSheet>
                ) : (
                    <MagritteModal
                        visible={opened}
                        title={header.title}
                        titleSize="medium"
                        titleDescription={header.subtitle}
                        titleDescriptionStyle="secondary"
                        actions={closeAction}
                        onClose={onClose}
                    >
                        <ApplicantSignInSignUpFlow
                            SignIn={SignInStep}
                            SignUp={SignUpStep}
                            Verification={VerificationStep}
                            onSignup={onSignup}
                            onSignIn={onSignIn}
                        />
                    </MagritteModal>
                )}
            </MagritteWrapper>
        );
    }

    return (
        <Modal visible={opened} onClose={onClose} useBottomSheet>
            <div className={styles.wrapper}>
                <ApplicantSignInSignUpFlow
                    SignIn={SignInStep}
                    SignUp={SignUpStep}
                    Verification={VerificationStep}
                    onSignup={onSignup}
                    onSignIn={onSignIn}
                />
            </div>
        </Modal>
    );
};

export default SignupModal;
