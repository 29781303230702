import { FC, PropsWithChildren } from 'react';

import { Card, Title, VSpacing as VSpacingMagritte, Link as LinkMagritte, VSpacingContainer } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import Gap from 'bloko/blocks/gap';
import { H2 } from 'bloko/blocks/header';
import Link, { LinkKind } from 'bloko/blocks/link';
import VSpacing from 'bloko/blocks/vSpacing';

import { useSelector } from 'src/hooks/useSelector';
import { ArticleItemWithTopic } from 'src/models/article.types';

interface UsefulLinksProps {
    items: ArticleItemWithTopic[] | null;
    title: string;
    withSeparator?: boolean;
    isEmployerContext?: boolean;
}

const UsefulLinks: FC<UsefulLinksProps & PropsWithChildren> = ({ items, title, withSeparator = false }) => {
    const isAnonymousMagritteExp = useSelector((state) => state.isAnonymousMagritteExp);

    if (isAnonymousMagritteExp) {
        return (
            <Card padding={24} borderRadius={24} borderWidth="default" verticalStretched stretched>
                <Title Element="h3" size="small">
                    {title}
                </Title>
                <VSpacingMagritte default={16} gteS={20} gteM={24} />
                <VSpacingContainer default={16}>
                    {items?.slice(0, 5).map(({ topic, href }) => (
                        <LinkMagritte style="neutral" Element={SPALink} to={href} key={href}>
                            {topic}
                        </LinkMagritte>
                    ))}
                </VSpacingContainer>
            </Card>
        );
    }

    return (
        <>
            <Gap top>
                <div className="index-useful">
                    <div className="index-news-box__header">
                        <H2 Element="h3">{title}</H2>
                        <VSpacing base={4} />
                    </div>
                    <ul>
                        {items?.map(({ topic, href }) => (
                            <li className="useful-link" key={href}>
                                <Link href={href} kind={LinkKind.Tertiary}>
                                    {topic}
                                </Link>
                            </li>
                        ))}
                    </ul>
                    {withSeparator && (
                        <div className="index-news-box__separator">
                            <div className="separator" />
                        </div>
                    )}
                </div>
            </Gap>
        </>
    );
};

export default UsefulLinks;
