import { useMemo } from 'react';

import Analytics, { ElementShownAnchor } from '@hh.ru/analytics-js';
import { Text } from '@hh.ru/magritte-ui';
import { EnvelopeOpenedOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import paths from 'src/app/routePaths';
import BalanceCard from 'src/components/EmployerBalanceWidget/BalanceCard';
import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { AccountHistoryAnchors } from 'src/utils/constants/accountHistoryAnchors';

import PublicationsHoverContent from 'src/components/EmployerBalanceWidget/cards/PublicationsCard/PublicationsHoverContent';
import { isItemExpireSoon } from 'src/components/EmployerBalanceWidget/cards/PublicationsCard/isItemExpireSoon';
import useGetPublicationItems from 'src/components/EmployerBalanceWidget/cards/PublicationsCard/useGetPublicationItems';

const TrlKeys = {
    title: 'employer.index.balanceWidget.publications.title',
};

const PublicationsCard: TranslatedComponent = ({ trls }) => {
    const employerIndexPage = useSelector(({ employerIndexPage }) => employerIndexPage);
    const isZp = useIsZarplataPlatform();
    const publicationsItems = useGetPublicationItems();

    const freeVacancyPublicationsCodesCount =
        employerIndexPage?.oldEmployer?.billingSummary?.serviceCategories?.freeVacancyPublicationsCodes?.count ?? 0;

    const nonFreeVacancyPublicationsCodesCount =
        employerIndexPage?.oldEmployer?.billingSummary?.serviceCategories?.nonFreeVacancyPublicationsCodes?.count ?? 0;

    const vacanciesCount = isZp
        ? nonFreeVacancyPublicationsCodesCount
        : freeVacancyPublicationsCodesCount + nonFreeVacancyPublicationsCodesCount;

    const isEmpty = vacanciesCount === 0 || !vacanciesCount;
    const linkAddPublicationUrl = isZp ? paths.priceZpPublications : paths.priceRegionalPublications;

    const isExpireSoon = useMemo(() => publicationsItems.some(isItemExpireSoon), [publicationsItems]);

    const analyticParams = {
        card: 'publications_balance',
        vacancies: vacanciesCount || 0,
    };

    const handleClick = () => {
        const event = isEmpty ? 'balance_widget_card_plus_click' : 'balance_widget_card_click';

        Analytics.sendHHEventButtonClick(event, analyticParams);
    };

    const card = (
        <BalanceCard
            showBadge={isExpireSoon}
            addUrl={isEmpty ? linkAddPublicationUrl : undefined}
            type="default"
            rightContent={<EnvelopeOpenedOutlinedSize24 initialColor="secondary" />}
            hoverContent={isEmpty ? null : <PublicationsHoverContent />}
            title={trls[TrlKeys.title]}
            onPlusClick={handleClick}
        >
            <Text typography="subtitle-1-semibold">{vacanciesCount || 0}</Text>
        </BalanceCard>
    );

    return (
        <ElementShownAnchor fn={Analytics.sendHHEventElementShown} name="balance_widget_card" {...analyticParams}>
            {isEmpty ? (
                card
            ) : (
                <SPALink
                    onClick={handleClick}
                    to={`${paths.accountHistoryServices}#${AccountHistoryAnchors.SingleServices}`}
                >
                    {card}
                </SPALink>
            )}
        </ElementShownAnchor>
    );
};

export default translation(PublicationsCard);
