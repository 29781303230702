import { type FC } from 'react';
import { useDispatch } from 'react-redux';

import { usePush } from '@hh.ru/redux-spa-middleware';

import { useNotification } from 'src/components/Notifications/Provider';
import { useSelector } from 'src/hooks/useSelector';
import { closeAnonymousSignUpModal } from 'src/models/anonymousSignUp';
import { postSignupModalAlreadyShown, postSignupBackurl } from 'src/models/anonymousSignUp/api';

import SignupModal from 'src/components/SignupModal/Form';
import SignupSuccessNotification from 'src/components/SignupModal/Notification';
import { SignupAnalyticsProvider } from 'src/components/SignupModal/contexts/SignupAnalytics';
import { SignupTrlsContextProvider } from 'src/components/SignupModal/contexts/SignupTrls';
import { redirectToFinishSignup } from 'src/components/SignupModal/lib';

const MainPageSignUpModal: FC = () => {
    const push = usePush();
    const dispatch = useDispatch();
    const opened = useSelector(({ anonymousSignUp }) => anonymousSignUp.opened && !anonymousSignUp.alreadyShown);
    const backUrl = useSelector(({ anonymousSignUp }) => anonymousSignUp.backUrl);
    const isAnonymousMagritteExp = useSelector(({ isAnonymousMagritteExp }) => isAnonymousMagritteExp);
    const { addNotification } = useNotification();

    const handleClose = async () => {
        dispatch(closeAnonymousSignUpModal());
        await postSignupModalAlreadyShown();
        push(backUrl);
    };

    const handleSignIn = async () => {
        dispatch(closeAnonymousSignUpModal());
        await Promise.all([postSignupModalAlreadyShown(), postSignupBackurl(backUrl)]);
        redirectToFinishSignup();
    };

    const handleSignup = async () => {
        dispatch(closeAnonymousSignUpModal());
        await Promise.all([postSignupModalAlreadyShown(), postSignupBackurl(backUrl)]);
        if (isAnonymousMagritteExp) {
            addNotification(SignupSuccessNotification);
        }
        redirectToFinishSignup();
    };

    return (
        <SignupTrlsContextProvider>
            <SignupAnalyticsProvider hhtmSource="main_authorization_popup">
                <SignupModal opened={opened} onClose={handleClose} onSignup={handleSignup} onSignIn={handleSignIn} />
            </SignupAnalyticsProvider>
        </SignupTrlsContextProvider>
    );
};

export default MainPageSignUpModal;
