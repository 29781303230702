import { FC } from 'react';

import vacancyCatalogItemLinkButtonClick from '@hh.ru/analytics-js-events/build/xhh/common/main/vacancy_catalog_item_link_button_click';
import { Card, Text, VSpacing } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';

import Compensation from 'src/components/Compensation';
import VacanciesCounter from 'src/components/RainbowCatalog/VacanciesCounter';
import { getProfessionLink } from 'src/components/RainbowCatalog/utils';
import { CurrencyType } from 'src/models/currencies.types';
import { ProfessionsListProfession } from 'src/models/professionsList';

import styles from './styles.less';

interface BottomSheetProfessionsProps {
    professions: ProfessionsListProfession[];
    parentProfession: ProfessionsListProfession;
    currency: CurrencyType;
    showSeoLinksForBots?: boolean;
}

const BottomSheetProfessions: FC<BottomSheetProfessionsProps> = ({
    professions,
    parentProfession,
    currency,
    showSeoLinksForBots,
}) => {
    return (
        <div className={styles.container}>
            {[parentProfession, ...professions].map((profession, index) => (
                <SPALink
                    key={profession.name}
                    to={getProfessionLink(parentProfession, profession)}
                    additionalQueryParams={{ hhtmFromLabel: 'rainbow_profession' }}
                    isSeoLink={showSeoLinksForBots}
                    data-qa="profession-item-title"
                    onClick={() => vacancyCatalogItemLinkButtonClick({ value: profession.name, position: index })}
                >
                    <Card
                        borderWidth="default"
                        stretched
                        padding={16}
                        borderRadius={16}
                        key={profession.name}
                        hoverEnabled
                    >
                        <Text typography="label-2-regular">{profession.name}</Text>
                        <VSpacing default={4} m={0} />
                        <Text typography="label-3-regular" style="secondary">
                            <Compensation
                                from={profession.compensationFrom}
                                to={profession.compensationTo}
                                currencyCode={currency}
                                dash
                            />
                        </Text>
                        <VSpacing default={4} m={0} />
                        <Text typography="paragraph-3-regular" style="tertiary">
                            <VacanciesCounter value={profession.count} />
                        </Text>
                    </Card>
                </SPALink>
            ))}
        </div>
    );
};

export default BottomSheetProfessions;
