/**
 * @deprecated
 */

export enum EmploymentLower {
    Full = 'full',
    Part = 'part',
    Project = 'project',
    Volunteer = 'volunteer',
    Probation = 'probation',
}
