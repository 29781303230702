import classnames from 'classnames';

import { Link, Card, Tag, VSpacing as MagritteVSpacing, Text as MagritteText } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import Conversion from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { ArticleItemWithTopic } from 'src/models/article.types';

import FAQAnalytics from 'src/components/FAQ/analytics';

import styles from './faq.less';

interface FAQItemProps {
    item: ArticleItemWithTopic;
    isBottomSheetItem?: boolean;
    isMagritte?: boolean;
    isAnalyticsEnabled?: boolean;
    maxLines?: number;
}

const TrlKeys = {
    minutesCounter: 'faq.card.time.count',
    minutesOne: 'plurals.minutes.one',
    minutesSome: 'plurals.minutes.some',
    minutesMany: 'plurals.minutes.many',
};

const FAQItem: TranslatedComponent<FAQItemProps> = ({
    trls,
    item,
    isBottomSheetItem = false,
    isMagritte = false,
    isAnalyticsEnabled = true,
    maxLines,
}) => {
    const { href, topic, tag, time } = item;
    if (isMagritte) {
        return (
            <div
                className={classnames(styles.faqCardWrapper, {
                    [styles.faqCardWrapperBottomsheet]: isBottomSheetItem,
                })}
                key={href}
            >
                <Card
                    Element={SPALink}
                    to={href}
                    borderWidth="default"
                    padding={24}
                    borderRadius={24}
                    stretched
                    verticalStretched
                    onClick={isAnalyticsEnabled ? () => FAQAnalytics.faqItemClick(topic) : undefined}
                >
                    <div className={styles.faqCardContent}>
                        <div>
                            {!!tag && <Tag>{tag}</Tag>}
                            <MagritteVSpacing default={16} />
                            <MagritteText maxLines={maxLines} typography="paragraph-1-regular">
                                {topic}
                            </MagritteText>
                        </div>
                        {isBottomSheetItem && <MagritteVSpacing default={24} />}
                        {!!time && (
                            <MagritteText typography="label-3-regular" style="secondary">
                                <Conversion
                                    one={trls[TrlKeys.minutesOne]}
                                    some={trls[TrlKeys.minutesSome]}
                                    many={trls[TrlKeys.minutesMany]}
                                    value={time}
                                    hasValue={false}
                                    format={(trl) =>
                                        format(trls[TrlKeys.minutesCounter], {
                                            '{0}': `${time} ${trl}`,
                                        })
                                    }
                                />
                            </MagritteText>
                        )}
                    </div>
                </Card>
            </div>
        );
    }

    return (
        <div
            data-qa="faq-item"
            key={href}
            className={classnames('multiple-column-list-item', 'multiple-column-list-item_redesigned')}
        >
            <Link
                data-qa="faq-item-link"
                href={href}
                style="neutral"
                onClick={isAnalyticsEnabled ? () => FAQAnalytics.faqItemClick(topic) : undefined}
                typography="label-2-regular"
                inline
            >
                <MagritteText data-qa="faq-item-text" maxLines={maxLines}>
                    {topic}
                </MagritteText>
            </Link>
        </div>
    );
};

export default translation(FAQItem);
