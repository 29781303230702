import { ReactNode } from 'react';
import classnames from 'classnames';

import appInstallBannerButtonClick from '@hh.ru/analytics-js-events/build/xhh/common/app_install_banner_button_click';
import { Card, useBreakpoint, Cell, CellText } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import Column from 'bloko/blocks/column';
import Gap from 'bloko/blocks/gap';
import { H4Section } from 'bloko/blocks/header';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useAppLinkHref } from 'src/hooks/useAppLinkHref';
import { useMagritte } from 'src/hooks/useMagritte';
import { useSelector } from 'src/hooks/useSelector';

import styles from './app-banner.less';

const LINK_PARAM = 'main_banner';

const TrlKeys = {
    title: 'mobile.appBanner.title',
    titleWithoutDomain: 'mobile.appBanner.title.no.domain',
    storeLinks: {
        ios: 'mobile.app.smartbanner.text.anchor.ios',
        android: 'mobile.app.smartbanner.text.anchor.android',
        winphone: 'mobile.app.smartbanner.text.anchor.winphone',
        huawei: 'mobile.app.smartbanner.text.anchor.huawei',
    },
};

interface AppBannerProps {
    spacingBottom?: ReactNode;
}

const AppBanner: TranslatedComponent<AppBannerProps> = ({ trls, spacingBottom }) => {
    const appLink = useSelector((state) => state.appLink);
    const device = useSelector((state) => state.device);
    const isMagritte = useMagritte();
    const appLinkHref = useAppLinkHref({ from: LINK_PARAM });

    const { isXS } = useBreakpoint();

    const handleLinkClick = () => {
        appInstallBannerButtonClick({ hhtmSourceLabel: 'middle' });
    };

    if (!appLink.detected) {
        return null;
    }

    if (isMagritte) {
        if (!isXS) {
            return null;
        }
        return (
            <>
                <SPALink
                    suppressHydrationWarning
                    to={appLinkHref}
                    className={styles.bannerContainer}
                    data-qa="app-banner"
                    onClick={handleLinkClick}
                    target="_blank"
                >
                    <Card borderRadius={16} padding={16} stretched borderWidth="default">
                        <Cell right={<span className={styles.bannerAppIcon}></span>}>
                            <CellText>{trls[TrlKeys.titleWithoutDomain]}</CellText>
                            {!!device.type && (
                                <CellText style="accent" data-qa="app-banner-storelink">
                                    {trls[TrlKeys.storeLinks[device.type]]}
                                </CellText>
                            )}
                        </Cell>
                    </Card>
                </SPALink>
                {spacingBottom}
            </>
        );
    }

    return (
        <Column xs="4" s="0" m="0" l="0">
            <Gap top>
                <SPALink
                    to={appLinkHref}
                    className={classnames('app-banner', styles.banner)}
                    data-qa="app-banner"
                    onClick={handleLinkClick}
                >
                    <div>
                        <div className="app-banner-title">
                            <H4Section>{trls[TrlKeys.title]}</H4Section>
                        </div>
                        {!!device.type && (
                            <span data-qa="app-banner-storelink">{trls[TrlKeys.storeLinks[device.type]]}</span>
                        )}
                    </div>
                    <span
                        className={classnames('app-banner-icon', {
                            [`app-banner-icon_${appLink.type}`]: appLink.type,
                        })}
                        data-qa="app-banner-img"
                    />
                </SPALink>
            </Gap>
        </Column>
    );
};

export default translation(AppBanner);
