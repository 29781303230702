import vacancyCatalogItemLinkButtonClick from '@hh.ru/analytics-js-events/build/xhh/common/main/vacancy_catalog_item_link_button_click';
import { Cell, Text as TextMagritte } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { CompensationMode } from '@hh.ru/types-hh-microcore';
import Conversion from 'bloko/blocks/conversion';
import BlokoLink from 'bloko/blocks/link';
import Text, { TextImportance } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Compensation from 'src/components/Compensation';
import { PART_TIME_CATALOG_ID, getProfessionLink } from 'src/components/RainbowCatalog/utils';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { CurrencyType } from 'src/models/currencies.types';
import { ProfessionsListProfession } from 'src/models/professionsList';

import styles from './styles.less';

interface RainbowDropProfessionProps {
    professions: ProfessionsListProfession[];
    parentProfession: ProfessionsListProfession;
    currency: CurrencyType;
    showSeoLinksForBots: boolean;
}

const TrlKeys = {
    vacancies: {
        one: 'professions.vacancies.one',
        some: 'professions.vacancies.some',
        many: 'professions.vacancies.many',
    },
};

const RainbowDropProfession: TranslatedComponent<RainbowDropProfessionProps> = ({
    professions,
    parentProfession,
    currency,
    showSeoLinksForBots,
    trls,
}) => {
    const isAnonymousMagritteExp = useSelector((state) => state.isAnonymousMagritteExp);

    if (isAnonymousMagritteExp) {
        return (
            <div className={styles.vacanciesList}>
                {[parentProfession, ...professions].map((profession, index) => {
                    return (
                        // Поисковым ботам показываем только ЧПУ (ссылки на каталог /vacancies/) и подработку
                        (showSeoLinksForBots
                            ? profession.catalogPath || parentProfession.id === PART_TIME_CATALOG_ID
                            : true) && (
                            <Cell align="top" key={profession.id}>
                                <SPALink
                                    to={getProfessionLink(parentProfession, profession)}
                                    additionalQueryParams={{ hhtmFromLabel: 'rainbow_profession' }}
                                    isSeoLink={showSeoLinksForBots}
                                    data-qa="profession-item-title"
                                    onClick={() =>
                                        vacancyCatalogItemLinkButtonClick({ value: profession.name, position: index })
                                    }
                                >
                                    <TextMagritte typography="label-2-regular" style="primary">
                                        {profession.name}
                                    </TextMagritte>
                                </SPALink>
                                <TextMagritte typography="label-3-regular" style="secondary">
                                    <Compensation
                                        from={profession.compensationFrom}
                                        to={profession.compensationTo}
                                        currencyCode={currency}
                                        showNoSalaryMessage
                                    />{' '}
                                    ·{' '}
                                    <Conversion
                                        value={profession.count}
                                        one={trls[TrlKeys.vacancies.one]}
                                        some={trls[TrlKeys.vacancies.some]}
                                        many={trls[TrlKeys.vacancies.many]}
                                    />
                                </TextMagritte>
                            </Cell>
                        )
                    );
                })}
            </div>
        );
    }

    return (
        <ul className="multiple-column-list multiple-column-list_wide">
            {[parentProfession, ...professions].map((profession, index) => {
                return (
                    // Поисковым ботам показываем только ЧПУ (ссылки на каталог /vacancies/) и подработку
                    (showSeoLinksForBots
                        ? profession.catalogPath || parentProfession.id === PART_TIME_CATALOG_ID
                        : true) && (
                        <li className="multiple-column-list-item" key={profession.id}>
                            <Text>
                                <div className="dashboard-tiles-drop-item">
                                    <BlokoLink
                                        Element={SPALink}
                                        to={getProfessionLink(parentProfession, profession)}
                                        additionalQueryParams={{ hhtmFromLabel: 'rainbow_profession' }}
                                        isSeoLink={showSeoLinksForBots}
                                        data-qa="profession-item-title"
                                        onClick={() =>
                                            vacancyCatalogItemLinkButtonClick({
                                                value: profession.name,
                                                position: index,
                                            })
                                        }
                                    >
                                        {profession.name}
                                    </BlokoLink>
                                </div>
                                <br />
                                <div className="dashboard-tiles-drop-compensation">
                                    <Compensation
                                        // TODO_HH-242151: Привести компенсацию к новому виду (mode, perModeFrom, perModeTo)
                                        perModeFrom={profession.compensationFrom}
                                        perModeTo={profession.compensationTo}
                                        currencyCode={currency}
                                        mode={CompensationMode.Month}
                                        dash
                                    />
                                    <span className="dashboard-tiles-drop-comma">, </span>
                                    <div className="dashboard-tiles-drop-vacancies-number">
                                        <Text importance={TextImportance.Tertiary}>
                                            <Conversion
                                                value={profession.count}
                                                one={trls[TrlKeys.vacancies.one]}
                                                some={trls[TrlKeys.vacancies.some]}
                                                many={trls[TrlKeys.vacancies.many]}
                                            />
                                        </Text>
                                    </div>
                                </div>
                            </Text>
                        </li>
                    )
                );
            })}
        </ul>
    );
};

export default translation(RainbowDropProfession);
