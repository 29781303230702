import { ReactNode, useEffect, useMemo } from 'react';

import { GridColumn, Link, Title, useBreakpoint, VSpacing as VSpacingMagritte } from '@hh.ru/magritte-ui';
import Text, { TextImportance, TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import Illustration from 'src/components/IndexPageAnonymous/SignUp/Illustration';
import VerificationForm, { VerificationFormBaseProps } from 'src/components/Verification/VerificationForm';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import styles from './verification-step.less';

const TrlKeys = {
    header: 'signUpForm.verification.header',
    phone: {
        sent: 'signUpForm.verification.codeSent.phone',
        sentShort: 'otp.confirm.header',
        description1: 'signUpForm.verification.codeSentDescription.1.phone',
        description2: 'signUpForm.verification.codeSentDescription.2.phone',
    },
    email: {
        sent: 'signUpForm.verification.codeSent.email',
        sentShort: 'otp.confirm.header',
        description: 'otp.confirm.sub.header',
        descriptionShort: 'otp.confirm.sub.header.short',
    },
    whatsApp: {
        sent: 'signUpForm.verification.codeSent.whatsApp',
        description: 'signUpForm.verification.codeSent.whatsApp.description',
    },
};

export interface VerificationStepProps extends VerificationFormBaseProps {
    onCancel?: () => void;
    cancelText?: string;
    codePlaceholder?: string;
    errorPageLogin?: boolean;
    setHeader: (headerContent: ReactNode) => void;
}

const VerificationStep: TranslatedComponent<VerificationStepProps> = ({
    trls,
    setHeader,
    errorPageLogin,
    codePlaceholder,
    onCancel,
    cancelText,
    ...formProps
}) => {
    const { login, authType } = formProps;
    const isAnonymousMagritteExp = useSelector(({ isAnonymousMagritteExp }) => isAnonymousMagritteExp);
    const { isGtM, isGtS, isMobile } = useBreakpoint();

    const header = useMemo(() => {
        switch (authType) {
            case 'SMS':
            case 'PHONE_CALL':
                return trls[isAnonymousMagritteExp ? TrlKeys.phone.sentShort : TrlKeys.phone.sent];
            case 'WHATS_APP':
                return trls[TrlKeys.whatsApp.sent];
            default:
                return trls[isAnonymousMagritteExp ? TrlKeys.email.sentShort : TrlKeys.email.sent];
        }
    }, [authType, isAnonymousMagritteExp, trls]);

    const description = useMemo(() => {
        switch (authType) {
            case 'SMS':
            case 'PHONE_CALL':
                return (
                    <>
                        <p>{trls[TrlKeys.phone.description1]}</p>
                        <p>{trls[TrlKeys.phone.description2]}</p>
                    </>
                );
            case 'WHATS_APP':
                return trls[TrlKeys.whatsApp.description];
            default:
                return isAnonymousMagritteExp ? trls[TrlKeys.email.descriptionShort] : trls[TrlKeys.email.description];
        }
    }, [authType, trls, isAnonymousMagritteExp]);

    const headerContent = useMemo(() => {
        return (
            <>
                <Text size={TextSize.Large} strong>
                    {trls[TrlKeys.header]}
                </Text>
                <VSpacing base={3} />
                <p>
                    {formatToReactComponent(header, {
                        '{0}': <strong>{login}</strong>,
                    })}
                </p>
                <VSpacing base={3} />
                {description}
            </>
        );
    }, [description, header, login, trls]);

    useEffect(() => {
        setHeader(headerContent);
    }, [headerContent, setHeader]);

    if (isAnonymousMagritteExp) {
        return (
            <div className={styles.box}>
                <div className={styles.content}>
                    <div className={styles.title}>
                        <Title size={isMobile ? 'small' : 'medium'} Element="h3" description={description}>
                            {formatToReactComponent(header, {
                                '{0}': <strong>{login}</strong>,
                            })}
                        </Title>
                    </div>
                    <VSpacingMagritte default={24} />
                    <VerificationForm
                        render={({ renderInput, renderCodeError, renderCodeSender, renderSubmit }) => {
                            return (
                                <GridColumn xs={4} s={8} m={5} l={5}>
                                    <div className={styles.formContainer}>
                                        <div className={styles.inputWrapper}>
                                            {renderInput({
                                                placeholder: codePlaceholder,
                                                isAutofocus: true,
                                            })}
                                        </div>
                                        <div className={styles.buttonWrapper}>
                                            {renderSubmit({ isStretched: true })}
                                        </div>
                                    </div>
                                    <div>
                                        <VSpacingMagritte default={12} />
                                        {renderCodeError()}
                                        <VSpacingMagritte default={12} />
                                        <Link style="accent" typography="label-2-regular">
                                            {renderCodeSender()}
                                        </Link>
                                    </div>
                                </GridColumn>
                            );
                        }}
                        {...formProps}
                    />
                </div>
                {isGtS && <Illustration size={isGtM ? 'large' : 'small'} className={styles.illustration} />}
            </div>
        );
    }

    return (
        <VerificationForm
            render={({ renderInput, renderCodeError, renderCodeSender, renderSubmit }) => {
                return (
                    <div className={styles.wrapper}>
                        <div className={styles.formContainer}>
                            <div className={styles.inputWrapper}>
                                {renderInput({
                                    placeholder: codePlaceholder,
                                    isAutofocus: true,
                                })}
                            </div>
                            <div className={styles.buttonWrapper}>{renderSubmit({ isStretched: true })}</div>
                        </div>
                        <div>
                            {renderCodeError()}
                            <VSpacing base={3} />
                            <Text importance={TextImportance.Tertiary}>{renderCodeSender()}</Text>
                        </div>
                    </div>
                );
            }}
            {...formProps}
        />
    );
};

export default translation(VerificationStep);
