import { Button, Text } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import Conversion from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import AdviceCard from 'src/components/EmployerAdvicesWidget/AdviceCard';
import { useHandleAnalyticsCardClick } from 'src/components/EmployerAdvicesWidget/useHandleAnalyticsCardClick';
import translation from 'src/components/translation';
import { MCPManagerUnreadResponsesAdvice } from 'src/models/employerAdvicesWidget/advices';

const TrlKeys = {
    text: 'employer.index.advicesWidget.mcpManagerUnreadResponses.text',
    button: 'employer.index.advicesWidget.mcpManagerUnreadResponses.button',
    responses: {
        one: 'employer.index.advicesWidget.mcpManagerUnreadResponses.responses.one',
        some: 'employer.index.advicesWidget.mcpManagerUnreadResponses.responses.some',
        many: 'employer.index.advicesWidget.mcpManagerUnreadResponses.responses.many',
    },
};

const MAXIMUM_COUNT = 99;

const MCPManagerUnreadResponses: TranslatedComponent<MCPManagerUnreadResponsesAdvice> = ({
    trls,
    name,
    data: { unreadResponsesCount },
}) => {
    const sendAnalytics = useHandleAnalyticsCardClick(name);

    return (
        <AdviceCard
            name={name}
            button={
                <Button
                    onClick={sendAnalytics}
                    Element={SPALink}
                    to="/employer/advices/MCP_MANAGERS_UNREAD_RESPONSES"
                    size="small"
                    stretched
                    mode="secondary"
                    style="neutral"
                >
                    {trls[TrlKeys.button]}
                </Button>
            }
        >
            {formatToReactComponent(trls[TrlKeys.text], {
                '{responses}': (
                    <Text typography="title-5-semibold" Element="span" style="warning">
                        <Conversion
                            one={trls[TrlKeys.responses.one]}
                            some={trls[TrlKeys.responses.some]}
                            many={trls[TrlKeys.responses.many]}
                            value={unreadResponsesCount}
                            hasValue
                            formatValue={(value) => (value > MAXIMUM_COUNT ? `${MAXIMUM_COUNT}+` : value)}
                        />
                    </Text>
                ),
            })}
        </AdviceCard>
    );
};

export default translation(MCPManagerUnreadResponses);
