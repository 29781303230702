import { useEffect, useRef, useState } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Card, Divider, Title } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import Body from 'src/components/IndexPageEmployer/components/Updates/Body';
import Footer from 'src/components/IndexPageEmployer/components/Updates/Footer';
import Tabs from 'src/components/IndexPageEmployer/components/Updates/Tabs';
import { TAB_IDS, TabValue } from 'src/components/IndexPageEmployer/components/Updates/constants';

const TrlKeys = {
    header: 'index.employerUpdatesInfo.header',
};

interface UpdateProps {
    maxHeight?: number;
}

const Updates: TranslatedComponent<UpdateProps> = ({ trls, maxHeight }) => {
    const updatesRef = useRef(null);
    const [activeTabId, setActiveTab] = useState<TabValue>(TAB_IDS.NEW_TAB_ID);

    const switchTab = (tabId: TabValue) => {
        setActiveTab(tabId);
    };

    useEffect(() => {
        if (updatesRef.current) {
            const { stopSpying } = Analytics.sendHHEventElementShown(updatesRef.current, {
                name: 'employer_updates_info',
            });

            return stopSpying;
        }
        return undefined;
    }, []);

    return (
        <div ref={updatesRef} className="updates-wrapper" style={maxHeight ? { height: `${maxHeight}px` } : undefined}>
            <Card borderWidth="default" padding={24} borderRadius={24} stretched verticalStretched>
                <div className="updates-scrollable-container">
                    <Title Element="h2" size="small" data-qa="updates-title">
                        {trls[TrlKeys.header]}
                    </Title>

                    <Tabs activeTabId={activeTabId} onChange={switchTab} />

                    <div className="updates-divider-wrapper">
                        <Divider marginBottom={16} />
                    </div>

                    <Body activeTabId={activeTabId} />

                    <Footer />
                </div>
            </Card>
        </div>
    );
};

export default translation(Updates);
