import cn from 'classnames';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import styles from './styles.less';

interface ServiceCardIconProps {
    name: string;
}

// HH-251179 - убрать этот компонент, как в магритт добавят все иконки
const ServiceCardIcon: TranslatedComponent<ServiceCardIconProps> = ({ name }) => {
    return <div className={cn(styles.icon, styles[name])} />;
};

export default translation(ServiceCardIcon);
