import differenceInDays from 'date-fns/differenceInDays';

import { ServiceEmployerDescription } from 'src/models/oldEmployer/employerBillingSummary';

const DAYS_TO_SHOW_EXPIRE = 2;

export const isItemExpireSoon = (item: ServiceEmployerDescription) => {
    if (item.expiredInfos?.length === 1 && item.expiredInfos[0].date) {
        const { date } = item.expiredInfos[0];

        const days = differenceInDays(new Date(date), new Date());

        return days <= DAYS_TO_SHOW_EXPIRE;
    }
    return false;
};
