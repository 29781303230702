import { format, translation, useSelector, type TranslatedComponent } from '@hh.ru/front-static-app';
import { CheckCircleOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import Notification from 'src/components/Notifications/Notification';
import type { NotificationProps } from 'src/components/Notifications/Provider/types';

const TrlKeys = {
    message: 'index.anonymous.signUp.notification.message',
};

const SignupSuccessNotification: TranslatedComponent<NotificationProps> = ({ trls, ...notificationProps }) => {
    const topLevelDomain = useSelector((state) => state.topLevelDomain);

    if (!topLevelDomain) {
        return null;
    }

    return (
        <Notification {...notificationProps} addon={<CheckCircleOutlinedSize24 initialColor="positive" />}>
            {format(trls[TrlKeys.message], { '{0}': topLevelDomain })}
        </Notification>
    );
};

export default translation(SignupSuccessNotification);
