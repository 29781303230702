import { FC } from 'react';

import vacancyCatalogItemLinkButtonClick from '@hh.ru/analytics-js-events/build/xhh/common/main/vacancy_catalog_item_link_button_click';
import { Cell, CellText, Link, Tag } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import NumberFormatter from 'bloko/common/numberFormatter';

import { ProfessionsListProfession } from 'src/models/professionsList';

import { getProfessionLink } from 'src/components/RainbowCatalog/utils';

interface BottomSheetItemProps {
    item: ProfessionsListProfession;
    parentItem: ProfessionsListProfession;
    index: number;
}

const BottomSheetItem: FC<BottomSheetItemProps> = ({ item, parentItem, index }) => {
    return (
        <Cell right={item.count ? <Tag>{NumberFormatter.format(String(item.count))}</Tag> : undefined}>
            <CellText>
                <Link
                    onClick={() => vacancyCatalogItemLinkButtonClick({ value: item.name, position: index })}
                    style="neutral"
                    Element={SPALink}
                    to={getProfessionLink(parentItem, item)}
                    additionalQueryParams={{ hhtmFromLabel: 'rainbow_profession' }}
                    isSeoLink
                    data-qa="profession-item-title"
                >
                    {item.name}
                </Link>
            </CellText>
        </Cell>
    );
};

export default BottomSheetItem;
