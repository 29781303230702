import React, { ReactNode, useRef, useState } from 'react';
import cn from 'classnames';

import { Badge, Button, Card, CardStyle, Drop, Text, VSpacing } from '@hh.ru/magritte-ui';
import { type CardProps } from '@hh.ru/magritte-ui-card/types';
import { PlusOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import styles from './styles.less';

interface BalanceCardProps {
    title: ReactNode;
    children: ReactNode;
    hoverContent?: ReactNode;
    rightContent: ReactNode;
    type: 'pinned' | 'default' | 'drop';
    isWarning?: boolean;
    addUrl?: string;
    onPlusClick?: () => void;
    showBadge?: boolean;
}

const TrlKeys = {
    ariaLabel: 'employer.index.balanceWidget.addButton.aria-label',
};

const BalanceCard: TranslatedComponent<BalanceCardProps> = ({
    title,
    children,
    rightContent,
    isWarning,
    addUrl,
    trls,
    type,
    hoverContent,
    showBadge,
    onPlusClick,
}) => {
    const activatorRef = useRef<HTMLDivElement>(null);
    const dropRef = useRef<HTMLDivElement>(null);
    const [dropVisible, setDropVisible] = useState(false);

    let content = (
        <div className={styles.row}>
            <div>
                <Text typography="label-3-regular" style={isWarning ? 'contrast' : 'secondary'}>
                    {title}
                </Text>
                <VSpacing default={4} />
                <div className={styles.rowText}>
                    {children}
                    {showBadge && (
                        <div className={styles.badgeContainer}>
                            <Badge size="extra-small" style="attention" />
                        </div>
                    )}
                </div>
            </div>
            {rightContent}
        </div>
    );

    if (addUrl) {
        content = (
            <div className={styles.row}>
                <div>
                    <Text typography="label-3-regular" style={isWarning ? 'contrast' : 'secondary'}>
                        {title}
                    </Text>
                    <VSpacing default={4} />
                    {children}
                </div>
                <Button
                    hideLabel
                    style="neutral"
                    icon={<PlusOutlinedSize24 />}
                    mode="secondary"
                    size="medium"
                    aria-label={trls[TrlKeys.ariaLabel]}
                    Element={SPALink}
                    to={addUrl}
                    onClick={onPlusClick}
                />
            </div>
        );
    }

    const getCardPropsByType = (): Partial<CardProps> => {
        switch (type) {
            case 'pinned':
                return {
                    borderWidth: 'none',
                    padding: 12,
                    borderRadius: 0,
                };
            case 'drop':
                return {
                    borderWidth: 'none',
                    padding: 24,
                    borderRadius: 0,
                };
            case 'default':
            default:
                return {
                    borderWidth: 'default',
                    padding: 24,
                    borderRadius: 24,
                };
        }
    };

    const getDropStyle = (): CardStyle | undefined => {
        if (dropVisible) {
            return 'secondary';
        }
        if (isWarning) {
            return 'warning-secondary';
        }
        return undefined;
    };

    const handleMouseEnter = () => {
        if (hoverContent) {
            setDropVisible(true);
        }
    };

    const handleMouseLeave = (event: React.MouseEvent | MouseEvent) => {
        if (dropRef.current && activatorRef.current && event.relatedTarget instanceof Element) {
            const isOverContainer =
                event.relatedTarget === dropRef.current || event.relatedTarget === activatorRef.current;
            const isOverChild =
                dropRef.current.contains(event.relatedTarget) || activatorRef.current.contains(event.relatedTarget);

            if (!isOverContainer && !isOverChild) {
                setDropVisible(false);
            }
        } else {
            setDropVisible(false);
        }
    };

    const handleDropOpened = () => {
        if (dropRef.current) {
            dropRef.current.addEventListener('mouseleave', handleMouseLeave);
        }
    };

    const handleDropClosed = () => {
        if (dropRef.current) {
            dropRef.current.removeEventListener('mouseleave', handleMouseLeave);
        }
    };

    return (
        <>
            <div
                className={cn({
                    [styles.cardContainerWarning]: isWarning,
                    [styles.cardContainerFixedHeight]: type === 'default',
                    [styles.cardContainerWithHoverDrop]: dropVisible,
                })}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <Card
                    ref={activatorRef}
                    verticalStretched
                    hoverStyle={hoverContent ? 'secondary' : undefined}
                    stretched
                    style={getDropStyle()}
                    {...getCardPropsByType()}
                    hoverEnabled={!!hoverContent}
                >
                    {content}
                </Card>
            </div>
            {hoverContent && (
                <Drop
                    space={0}
                    onAppear={handleDropOpened}
                    onBeforeExit={handleDropClosed}
                    ref={dropRef}
                    visible={dropVisible}
                    placement="right-center"
                    activatorRef={activatorRef}
                >
                    {hoverContent}
                </Drop>
            )}
        </>
    );
};

export default translation(BalanceCard);
