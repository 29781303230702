import { Button, Text } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import Conversion from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import AdviceCard from 'src/components/EmployerAdvicesWidget/AdviceCard';
import DaysLeftText from 'src/components/EmployerAdvicesWidget/DaysLeftText';
import { useHandleAnalyticsCardClick } from 'src/components/EmployerAdvicesWidget/useHandleAnalyticsCardClick';
import translation from 'src/components/translation';
import { ExpiringVacanciesAdvice } from 'src/models/employerAdvicesWidget/advices';

const TrlKeys = {
    willExpireSingle: 'employer.index.advicesWidget.expiringVacancies.willExpire.single',
    willExpireMultiple: 'employer.index.advicesWidget.expiringVacancies.willExpire.multiple',
    willExpireButton: 'employer.index.advicesWidget.expiringVacancies.willExpire.button',
    willExpireALot: 'employer.index.advicesWidget.expiringVacancies.willExpire.alot',
    willExpireMultipleAction: 'employer.index.advicesWidget.expiringVacancies.willExpire.multiple.action',
    vacancies: {
        one: 'employer.index.advicesWidget.vacancy.one',
        some: 'employer.index.advicesWidget.vacancy.some',
        many: 'employer.index.advicesWidget.vacancy.many',
    },
};

const ExpiringVacancies: TranslatedComponent<ExpiringVacanciesAdvice> = ({ trls, name, data: { vacancies } }) => {
    const isMultiple = vacancies.length > 1;
    const isALot = vacancies.length > 10;

    const sendAnalytics = useHandleAnalyticsCardClick(name);

    const link = isMultiple
        ? '/employer/vacancies?order=BY_EXPIRE_TIME_ASC'
        : `/vacancy/${vacancies[0].vacancyId}?runAction=prolongate`;

    const getText = () => {
        let text = '';

        if (isALot) {
            text = trls[TrlKeys.willExpireALot];
        } else if (isMultiple) {
            text = trls[TrlKeys.willExpireMultiple];
        } else {
            text = trls[TrlKeys.willExpireSingle];
        }

        return formatToReactComponent(text, {
            '{0}': (
                <Conversion
                    one={trls[TrlKeys.vacancies.one]}
                    some={trls[TrlKeys.vacancies.some]}
                    many={trls[TrlKeys.vacancies.many]}
                    value={vacancies.length}
                    hasValue
                />
            ),
            '{action}': (
                <Text typography="title-5-semibold" Element="span" style="negative">
                    {trls[TrlKeys.willExpireMultipleAction]}
                </Text>
            ),
            '{when}': (
                <Text typography="title-5-semibold" Element="span" style="negative">
                    <DaysLeftText days={vacancies[0].expireInDays} />
                </Text>
            ),
        });
    };

    return (
        <AdviceCard
            name={name}
            button={
                <Button
                    onClick={sendAnalytics}
                    Element={SPALink}
                    to={link}
                    size="small"
                    stretched
                    style="negative"
                    mode="secondary"
                >
                    {trls[TrlKeys.willExpireButton]}
                </Button>
            }
        >
            {getText()}
        </AdviceCard>
    );
};

export default translation(ExpiringVacancies);
