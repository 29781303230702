import { FC, PropsWithChildren, MouseEvent } from 'react';
import classnames from 'classnames';

import { SPALink } from '@hh.ru/redux-spa-middleware';

const DATA_QA = 'professions-item-mobile';

interface RainbowTileMobileProps {
    onClick: (title: string, event: MouseEvent<HTMLAnchorElement | HTMLDivElement>) => void;
    link?: string;
    colorIndex?: number;
    children: string;
}

const RainbowTileMobile: FC<RainbowTileMobileProps & PropsWithChildren> = ({ children, colorIndex, link, onClick }) => {
    if (link) {
        return (
            <SPALink
                to={link}
                className={'dashboard-tiles-item-mobile dashboard-tiles-item-link'}
                onClick={(event) => onClick(String(children), event)}
                data-qa={DATA_QA}
            >
                {children}
            </SPALink>
        );
    }

    return (
        <div
            className={classnames('dashboard-tiles-item-mobile', {
                [`dashboard-tiles-item-mobile_rainbow-${String(colorIndex)}`]: colorIndex !== undefined,
            })}
            onClick={(event) => onClick(String(children), event)}
            data-qa={DATA_QA}
        >
            {children}
        </div>
    );
};

export default RainbowTileMobile;
