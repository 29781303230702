import { Button, Text } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import AdviceCard from 'src/components/EmployerAdvicesWidget/AdviceCard';
import { getExpireButtonStyles } from 'src/components/EmployerAdvicesWidget/getExpireButtonStyles';
import { useHandleAnalyticsCardClick } from 'src/components/EmployerAdvicesWidget/useHandleAnalyticsCardClick';
import translation from 'src/components/translation';
import { ExpiringContactsResumeAccessesAdvice } from 'src/models/employerAdvicesWidget/advices';

const TrlKeys = {
    button: 'employer.index.advicesWidget.expiringContactsResumeAccesses.button',
    willExpire: 'employer.index.advicesWidget.expiringContactsResumeAccesses.willExpire',
    expiredStart: 'employer.index.advicesWidget.expiringContactsResumeAccesses.expiredStart',
    expiredEnd: 'employer.index.advicesWidget.expiringContactsResumeAccesses.expiredEnd',
};

const LOW_PERCENT_THRESHOLD = 5;

const ExpiringContactsResumeAccesses: TranslatedComponent<ExpiringContactsResumeAccessesAdvice> = ({
    trls,
    name,
    data: { contacts, contactsPercent },
}) => {
    const sendAnalytics = useHandleAnalyticsCardClick(name);
    return (
        <AdviceCard
            name={name}
            button={
                <Button
                    Element={SPALink}
                    onClick={sendAnalytics}
                    to={'/price/add-contacts'}
                    size="small"
                    stretched
                    {...getExpireButtonStyles({
                        isVeryLow: contactsPercent <= LOW_PERCENT_THRESHOLD,
                        isZero: contactsPercent === 0,
                    })}
                >
                    {trls[TrlKeys.button]}
                </Button>
            }
        >
            {contactsPercent === 0 ? (
                <>
                    {trls[TrlKeys.expiredStart]}{' '}
                    <Text typography="title-5-semibold" Element="span" style="negative">
                        {trls[TrlKeys.expiredEnd]}
                    </Text>
                </>
            ) : (
                formatToReactComponent(trls[TrlKeys.willExpire], {
                    '{0}': (
                        <Text
                            typography="title-5-semibold"
                            Element="span"
                            style={contactsPercent > LOW_PERCENT_THRESHOLD ? 'warning' : 'negative'}
                        >
                            {contacts}
                        </Text>
                    ),
                })
            )}
        </AdviceCard>
    );
};

export default translation(ExpiringContactsResumeAccesses);
