import { type DriverLicenseType } from '@hh.ru/types-hh-microcore';

import fetcher from 'src/utils/fetcher';

const PROFILE_UPDATE_URL = '/profile/shards/profile/update';

export interface ProfileUpdatePayload {
    profile: {
        hasVehicle: boolean;
        driverLicenseTypes: DriverLicenseType[];
    };
}

declare global {
    interface FetcherPostApi {
        [PROFILE_UPDATE_URL]: {
            queryParams: void;
            body: ProfileUpdatePayload;
            response: void;
        };
    }
}

export const updateProfile = async (payload: ProfileUpdatePayload, signal: AbortSignal) =>
    await fetcher.post(PROFILE_UPDATE_URL, payload, { signal });
