import { useRef, FC, useState, useEffect } from 'react';

import { useExperiment } from '@hh.ru/front-static-app';
import { GridColumn, GridRow, GridLayout, VSpacing } from '@hh.ru/magritte-ui';

import EmployerAdvicesWidget from 'src/components/EmployerAdvicesWidget';
import EmployerBalanceWidget from 'src/components/EmployerBalanceWidget';
import EmployerBannersWidget from 'src/components/EmployerBannersWidget';
import EmployerResumeSearchesWidget from 'src/components/EmployerResumeSearchesWidget';
import EmployerServicesWidget from 'src/components/EmployerServicesWidget';
import VacanciesWidget from 'src/components/EmployerVacanciesWidget';
import AdditionalServices from 'src/components/IndexPageEmployer/components/AdditionalServices';
import Blog from 'src/components/IndexPageEmployer/components/Blog';
import EmployerEventList from 'src/components/IndexPageEmployer/components/EmployerEventList';
import LastSearches from 'src/components/IndexPageEmployer/components/LastSearches';
import Updates from 'src/components/IndexPageEmployer/components/Updates';
import { useIsRussia } from 'src/hooks/useCountries';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';

const WIDGET_MIN_HEIGHT = 340;

const Layout: FC = () => {
    const isZp = useIsZarplataPlatform();
    const isRussia = useIsRussia();
    const eventsRef = useRef<HTMLDivElement>(null);
    const [widgetHeight, setWidgetHeight] = useState(WIDGET_MIN_HEIGHT);
    const isMainPageRedesign = useExperiment('main_page_redesign_v1');

    useEffect(() => {
        const eventsRect = eventsRef.current?.getBoundingClientRect();
        const eventsHeight = eventsRect?.height || 0;

        if (eventsHeight) {
            setWidgetHeight(eventsHeight);
        }
    }, []);

    if (isZp) {
        return (
            <GridLayout>
                <VSpacing xs={12} default={40} />

                {isMainPageRedesign && (
                    <>
                        <GridRow>
                            <GridColumn xs={4} s={8} l={3}>
                                <EmployerAdvicesWidget />
                                <VSpacing default={20} />
                                <EmployerBalanceWidget />
                            </GridColumn>
                            <GridColumn xs={0} s={0} m={4} l={6} />
                            <GridColumn xs={4} s={8} m={4} l={3}>
                                <EmployerBannersWidget />
                            </GridColumn>
                        </GridRow>

                        <VSpacing xs={12} default={40} />
                    </>
                )}

                <GridRow rowGap={24}>
                    <GridColumn flexibleContent xs={4} s={8} m={0}>
                        <VacanciesWidget />
                    </GridColumn>

                    <GridColumn flexibleContent xs={4} s={8} m={6} l={3}>
                        <EmployerEventList ref={eventsRef} />
                    </GridColumn>

                    <GridColumn flexibleContent xs={0} s={0} m={6} l={6}>
                        <VacanciesWidget />
                    </GridColumn>

                    <GridColumn flexibleContent xs={4} s={8} m={0} l={3}>
                        <LastSearches maxHeight={widgetHeight} />
                    </GridColumn>
                </GridRow>

                <VSpacing default={24} />

                <GridRow>
                    <GridColumn flexibleContent xs={0} s={0} m={6} l={0}>
                        <LastSearches />
                    </GridColumn>

                    <GridColumn flexibleContent xs={4} s={8} m={6} l={6}>
                        <Blog isVacanciesWidgetExp={true} faqMaxItems={6} />
                    </GridColumn>
                </GridRow>

                {isMainPageRedesign && (
                    <>
                        <VSpacing default={24} />

                        <GridRow>
                            <GridColumn xs={4} s={8} m={8} l={6}>
                                <EmployerResumeSearchesWidget />
                            </GridColumn>
                        </GridRow>
                    </>
                )}
            </GridLayout>
        );
    }

    return (
        <GridLayout>
            <VSpacing xs={12} default={40} />

            {isMainPageRedesign && (
                <>
                    <GridRow rowGap={24}>
                        <GridColumn xs={4} s={8} m={4} l={3}>
                            <EmployerAdvicesWidget />
                            <VSpacing default={20} />
                            <EmployerBalanceWidget />
                        </GridColumn>
                        <GridColumn xs={0} s={0} m={4} l={6} />
                        <GridColumn xs={4} s={8} m={4} l={3}>
                            <EmployerServicesWidget />
                            <VSpacing default={12} />
                            <EmployerBannersWidget />
                        </GridColumn>
                    </GridRow>

                    <VSpacing xs={24} s={24} default={40} />
                </>
            )}

            <GridRow rowGap={24}>
                <GridColumn flexibleContent xs={4} s={8} m={0}>
                    <VacanciesWidget />
                </GridColumn>

                <GridColumn flexibleContent xs={4} s={8} m={6} l={3}>
                    <EmployerEventList ref={eventsRef} />
                </GridColumn>

                <GridColumn flexibleContent xs={0} s={0} m={6} l={6}>
                    <VacanciesWidget />
                </GridColumn>

                {isRussia ? (
                    <GridColumn flexibleContent xs={4} s={8} m={0} l={3}>
                        <AdditionalServices />
                    </GridColumn>
                ) : (
                    <GridColumn flexibleContent xs={0} s={0} m={0} l={3}>
                        <LastSearches maxHeight={widgetHeight} />
                    </GridColumn>
                )}
            </GridRow>

            <VSpacing default={24} />

            <GridRow>
                {isRussia ? (
                    <GridColumn flexibleContent xs={4} s={8} m={6} l={3}>
                        <LastSearches />
                        <VSpacing default={0} xs={24} s={24} />
                    </GridColumn>
                ) : (
                    <GridColumn flexibleContent xs={4} s={8} m={6} l={0}>
                        <LastSearches />
                        <VSpacing default={0} xs={24} s={24} />
                    </GridColumn>
                )}

                {isRussia && (
                    <GridColumn flexibleContent xs={0} s={0} m={6} l={0}>
                        <VSpacing default={24} m={0} />
                        <AdditionalServices />
                    </GridColumn>
                )}

                <GridColumn flexibleContent xs={0} s={0} m={6} l={3}>
                    <VSpacing default={0} m={isRussia ? 24 : 0} />
                    <Updates />
                </GridColumn>

                <GridColumn flexibleContent xs={4} s={8} m={isRussia ? 6 : 12} l={6}>
                    <VSpacing default={0} m={24} />
                    <Blog isVacanciesWidgetExp={true} faqMaxItems={6} />
                </GridColumn>

                <GridColumn flexibleContent xs={4} s={8} m={0} l={0}>
                    <VSpacing default={24} />
                    <Updates />
                </GridColumn>
            </GridRow>

            {isMainPageRedesign && (
                <>
                    <VSpacing default={24} />

                    <GridRow>
                        <GridColumn xs={4} s={8} m={8} l={6}>
                            <EmployerResumeSearchesWidget />
                        </GridColumn>
                    </GridRow>
                </>
            )}
        </GridLayout>
    );
};

export default Layout;
