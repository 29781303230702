import { Card, GridColumn, HSpacing, IconColor, Title, useBreakpoint, VSpacing, Text } from '@hh.ru/magritte-ui';
import {
    BriefcaseOutlinedSize24,
    ChartLineOutlinedSize24,
    DocumentOutlinedSize24,
    GraduationHatOutlinedSize24,
} from '@hh.ru/magritte-ui/icon';
import { usePush } from '@hh.ru/redux-spa-middleware';
import Cookies from 'bloko/common/Cookies';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { PresenceGoalsMap, PRESENCE_GOALS_COOKIE_NAME } from 'src/models/presenceGoals';

import styles from './styles.less';

interface PresenceGoalsProps {
    onSubmit?: (selected: PresenceGoalsMap[]) => void;
}

const TrlKeys = {
    title: 'presence.goals.title',
    description: 'presence.goals.description',
    labels: {
        findJob: 'presence.goals.label.find.job',
        evaluateVacancy: 'presence.goals.label.evaluate.vacancy',
        improveCareer: 'presence.goals.label.improve.career',
        improveCareerShort: 'presence.goals.label.improve.career.short',
        updateResume: 'presence.goals.label.update.resume',
    },
    submit: 'presence.goals.submit',
};

export const presenceGoalsList = [
    {
        value: PresenceGoalsMap.FindJob,
        label: TrlKeys.labels.findJob,
        icon: BriefcaseOutlinedSize24,
        color: 'positive',
    },
    {
        value: PresenceGoalsMap.EvaluateVacancy,
        label: TrlKeys.labels.evaluateVacancy,
        icon: ChartLineOutlinedSize24,
        color: 'special',
    },
    {
        value: PresenceGoalsMap.UpdateResume,
        label: TrlKeys.labels.updateResume,
        icon: DocumentOutlinedSize24,
        color: 'accent',
    },
    {
        value: PresenceGoalsMap.ImproveCareer,
        label: TrlKeys.labels.improveCareer,
        shortLabel: TrlKeys.labels.improveCareerShort,
        icon: GraduationHatOutlinedSize24,
        color: 'warning',
    },
];

const PresenceGoalsC: TranslatedComponent<PresenceGoalsProps> = ({ trls }) => {
    const { isMobile } = useBreakpoint();
    const push = usePush();

    const handleCardClick = (value: PresenceGoalsMap) => {
        Cookies.set(PRESENCE_GOALS_COOKIE_NAME, value);
        push('/account/login');
    };

    return (
        <GridColumn xs={4} s={8} m={12} l={12}>
            <div className={styles.container}>
                <div>
                    <Title size="medium" Element="h2" description={trls[TrlKeys.description]}>
                        {trls[TrlKeys.title]}
                    </Title>
                    <VSpacing default={24} xs={16} s={16} />
                    <div className={styles.cardList}>
                        {presenceGoalsList.map((goal) => (
                            <Card
                                key={goal.label}
                                onClick={() => handleCardClick(goal.value)}
                                borderWidth="default"
                                borderRadius={24}
                                padding={24}
                            >
                                <div className={styles.cardContent}>
                                    <span className={styles.iconWrapper}>
                                        <goal.icon initialColor={goal.color as IconColor} />
                                    </span>
                                    {isMobile ? <HSpacing default={12} /> : <VSpacing default={24} />}
                                    <Text typography={isMobile ? 'label-2-regular' : 'custom-1-semibold'}>
                                        {trls[goal.label]}
                                    </Text>
                                </div>
                            </Card>
                        ))}
                    </div>
                </div>
            </div>
        </GridColumn>
    );
};

export default translation(PresenceGoalsC);
