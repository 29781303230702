import { type DriverLicenseType } from '@hh.ru/types-hh-microcore';

import { type DriverLicenses } from 'src/components/ResumeAdditionalDataCollector/types';

export function getCheckedDriverLicenseTypes(driverLicenses: DriverLicenses): DriverLicenseType[] {
    return Object.entries(driverLicenses).reduce(
        (checkedDriverLicenseTypes: DriverLicenseType[], [driverLicenseType, isChecked]) => {
            if (isChecked) {
                checkedDriverLicenseTypes.push(driverLicenseType as DriverLicenseType);
            }

            return checkedDriverLicenseTypes;
        },
        []
    );
}
