// tempexp_27495_file
import { useLayoutEffect, useMemo } from 'react';

import { useSelector } from '@hh.ru/front-static-app';
import { VSpacingContainer } from '@hh.ru/magritte-ui';
import Column from 'bloko/blocks/column';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import useShowAdvertisementAgreement from 'src/components/AdvertisementAgreement/useShowAdvertisementAgreement';
import useShowAdvertisementSubscriptionApplicant from 'src/components/AdvertisementSubscription/useShowAdvertisementSubscriptionApplicant';
import { useSignupTrlsContextContext } from 'src/components/SignupModal/contexts/SignupTrls';
import translation from 'src/components/translation';

import SignUpForm, { SignUpSignFormBaseProps } from 'src/components/SignupModal/Form/steps/sign-up/SignUpForm';

const TrlKeys = {
    header1: 'signUpStep.header.1',
    header2: 'signUpStep.header.2',
};

export type SignUpStepProps = SignUpSignFormBaseProps;

const SignUpStep: TranslatedComponent<SignUpSignFormBaseProps> = ({ trls, ...formProps }) => {
    const isAnonymousMagritteExp = useSelector(({ isAnonymousMagritteExp }) => isAnonymousMagritteExp);
    const showAdvertisementAgreement = useShowAdvertisementAgreement();
    const showAdvertisementSubscriptionApplicant = useShowAdvertisementSubscriptionApplicant();
    const { updateHeader } = useSignupTrlsContextContext();

    const title = useMemo(
        () => (
            <>
                {trls[TrlKeys.header1]}
                <br />
                {trls[TrlKeys.header2]}
            </>
        ),
        [trls]
    );

    useLayoutEffect(() => {
        updateHeader({ title, subtitle: '' });
    }, [title, updateHeader]);

    return (
        <SignUpForm
            render={({
                renderFirstNameField,
                renderLastNameField,
                renderSubmit,
                renderAccountDeleteInactive,
                renderAdvertisementAgreement,
                renderAdvertisementSubscriptionApplicant,
            }) => {
                return isAnonymousMagritteExp ? (
                    <VSpacingContainer default={16} gteS={24}>
                        <VSpacingContainer default={12}>
                            <div>{renderFirstNameField()}</div>
                            <div>{renderLastNameField()}</div>
                        </VSpacingContainer>
                        {renderAccountDeleteInactive()}
                        {showAdvertisementAgreement && renderAdvertisementAgreement()}
                        {showAdvertisementSubscriptionApplicant && renderAdvertisementSubscriptionApplicant()}
                        {renderSubmit()}
                    </VSpacingContainer>
                ) : (
                    <div>
                        <Text size={TextSize.Large} strong>
                            {title}
                        </Text>
                        <VSpacing base={4} />
                        {renderFirstNameField()}
                        <VSpacing base={4} />
                        {renderLastNameField()}
                        <VSpacing base={6} />
                        {renderAccountDeleteInactive()}
                        {renderSubmit()}
                        {showAdvertisementAgreement && (
                            <div>
                                <Column l="4" m="4" s="4" xs="4" container>
                                    <VSpacing base={5} />
                                    {renderAdvertisementAgreement()}
                                </Column>
                            </div>
                        )}
                    </div>
                );
            }}
            isSignupMain
            {...formProps}
        />
    );
};

export default translation(SignUpStep);
