import { MutableRefObject, useCallback, useRef } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Button, Cell, CellText, Text, VSpacing } from '@hh.ru/magritte-ui';
import { ChevronRightOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Area from 'src/components/EmployerResumeSearchesWidget/components/CellSubtitle/Area';
import Salary from 'src/components/EmployerResumeSearchesWidget/components/CellSubtitle/Salary';
import Subtitle, { SubtitleItem } from 'src/components/EmployerResumeSearchesWidget/components/CellSubtitle/Subtitle';
import CellWrapper from 'src/components/EmployerResumeSearchesWidget/components/CellWrapper';
import Footer from 'src/components/EmployerResumeSearchesWidget/components/Footer';
import Placeholder from 'src/components/EmployerResumeSearchesWidget/components/Placeholder';
import translation from 'src/components/translation';
import useInView from 'src/hooks/useInView';
import { useSelector } from 'src/hooks/useSelector';
import { ResumeSavedSearchItem } from 'src/models/resumeSavedSearch';
import { CriteriaKey } from 'src/types/search/common/criteria';
import { deleteDeprecatedFilter } from 'src/utils/resumeSearch/deleteDeprecatedFilter';

const TrlKeys = {
    noName: 'resumeSearchesWidget.savedSearches.noName',
    allSearches: 'resumeSearchesWidget.savedSearches.allSearches',
    placeholder: {
        title: 'resumeSearchesWidget.savedSearches.placeholder.title',
        description: 'resumeSearchesWidget.savedSearches.placeholder.description',
        button: 'resumeSearchesWidget.savedSearches.placeholder.button',
    },
};

interface SavedSearchesProps {
    containerRef: MutableRefObject<HTMLDivElement | null>;
}

const SavedSearches: TranslatedComponent<SavedSearchesProps> = ({ trls, containerRef }) => {
    const {
        savedSearches: { items },
    } = useSelector((state) => state.resumeSearchesWidget);

    const allItemsButtonShownAnalyticsSended = useRef(false);

    const handleLinkClick = useCallback(() => {
        Analytics.sendHHEventButtonClick('saved_searches_item');
    }, []);

    const handleAllItemsClick = useCallback(() => {
        Analytics.sendHHEventButtonClick('saved_searches_all_items_link', { items: items.length });
    }, [items.length]);

    const getCellSubtitle = (savedSearch: ResumeSavedSearchItem): SubtitleItem[] => {
        const { searchCriteria, params } = savedSearch;
        const areas = params[CriteriaKey.Area];
        const salaryFrom = searchCriteria[CriteriaKey.SalaryFrom];
        const salaryTo = searchCriteria[CriteriaKey.SalaryTo];

        const subtitleItems: SubtitleItem[] = [{ content: <Area areas={areas} /> }];

        if (salaryFrom || salaryTo) {
            subtitleItems.push({
                growing: true,
                content: (
                    <Salary
                        salaryFrom={salaryFrom}
                        salaryTo={salaryTo}
                        currencyType={searchCriteria[CriteriaKey.CurrencyCode]}
                    />
                ),
            });
        }

        return subtitleItems;
    };

    const sendAllItemsButtonShownAnalytics = () => {
        if (!allItemsButtonShownAnalyticsSended.current) {
            allItemsButtonShownAnalyticsSended.current = true;

            Analytics.sendHHEvent('element_shown', {
                elementName: 'saved_searches_all_items',
                items: items.length,
            });
        }
    };

    // elementSpy (используется под капотом sendHHEventElementShown) не корректно работает,
    // если передать root-контейнер, надо чинить (HH-249681)
    const allItemsButtonRef = useInView<HTMLButtonElement>(sendAllItemsButtonShownAnalytics, {
        root: containerRef.current,
        threshold: 0.8,
    });

    if (!items.length) {
        return (
            <Placeholder>
                <Text typography="title-5-semibold">{trls[TrlKeys.placeholder.title]}</Text>
                <VSpacing default={8} />
                <Text typography="paragraph-2-regular" style="secondary">
                    {trls[TrlKeys.placeholder.description]}
                </Text>
                <VSpacing default={32} />
                <Button
                    stretched
                    typography="subtitle-1-semibold"
                    Element={SPALink}
                    to="/search/resume"
                    mode="primary"
                    style="accent"
                >
                    {trls[TrlKeys.placeholder.button]}
                </Button>
            </Placeholder>
        );
    }

    return (
        <>
            {items.map((item) => {
                const { id, name, searchAllQuery } = item;

                return (
                    <CellWrapper key={id}>
                        <SPALink
                            to={`/search/resume?${deleteDeprecatedFilter(searchAllQuery)}&L_is_autosearch=true`}
                            onClick={handleLinkClick}
                        >
                            <Cell right={<ChevronRightOutlinedSize24 />}>
                                <CellText maxLines={1}>{name || trls[TrlKeys.noName]}</CellText>
                                <Subtitle items={getCellSubtitle(item)} />
                            </Cell>
                        </SPALink>
                    </CellWrapper>
                );
            })}

            <Footer>
                <Button
                    stretched
                    typography="subtitle-1-semibold"
                    Element={SPALink}
                    to="/resumesavedsearch"
                    style="neutral"
                    mode="tertiary"
                    onClick={handleAllItemsClick}
                    ref={allItemsButtonRef}
                >
                    {trls[TrlKeys.allSearches]}
                </Button>
            </Footer>
        </>
    );
};

export default translation(SavedSearches);
