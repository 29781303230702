import { useCallback } from 'react';

import createVacancyClick from '@hh.ru/analytics-js-events/build/xhh/employer/vacancies_widget/vacancies_widget_create_vacancy_button_click';
import { Text, Link } from '@hh.ru/magritte-ui';
import { PlusOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import styles from './header.less';

const TrlKeys = {
    createVacancy: 'employer.indexPage.widgets.myVacancies.createVacancy',
    vacancies: 'employer.indexPage.widgets.myVacancies.title',
};

const Header: TranslatedComponent = ({ trls }) => {
    const { vacancies, activeVacanciesAmount } = useSelector((state) => state.vacanciesWidget);

    const handleCreateVacancyClick = useCallback(() => {
        createVacancyClick({ activeVacanciesAmount, hhtmSourceLabel: 'header' });
    }, [activeVacanciesAmount]);

    return (
        <div className={styles.header}>
            <Text typography="title-5-semibold">{trls[TrlKeys.vacancies]}</Text>

            {vacancies.length > 0 && (
                <Link
                    to="/employer/vacancy/create"
                    data-qa="my-vacancies_create-vacancy"
                    Element={SPALink}
                    iconLeft={<PlusOutlinedSize16 />}
                    typography="label-3-regular"
                    onClick={handleCreateVacancyClick}
                >
                    {trls[TrlKeys.createVacancy]}
                </Link>
            )}
        </div>
    );
};

export default translation(Header);
