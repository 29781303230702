import { Button, Text } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import AdviceCard from 'src/components/EmployerAdvicesWidget/AdviceCard';
import { useHandleAnalyticsCardClick } from 'src/components/EmployerAdvicesWidget/useHandleAnalyticsCardClick';
import translation from 'src/components/translation';
import { ManagerUnreadResponsesAdvice } from 'src/models/employerAdvicesWidget/advices';

const TrlKeys = {
    highlighted: 'employer.index.advicesWidget.managerUnreadResponses.highlighted',
    text: 'employer.index.advicesWidget.managerUnreadResponses.text',
    button: 'employer.index.advicesWidget.managerUnreadResponses.button',
};

const ManagerUnreadResponses: TranslatedComponent<ManagerUnreadResponsesAdvice> = ({ trls, name }) => {
    const sendAnalytics = useHandleAnalyticsCardClick(name);

    return (
        <AdviceCard
            name={name}
            button={
                <Button
                    onClick={sendAnalytics}
                    Element={SPALink}
                    to="/employer/advices/MANAGER_UNREAD_RESPONSES"
                    size="small"
                    stretched
                    mode="secondary"
                    style="neutral"
                >
                    {trls[TrlKeys.button]}
                </Button>
            }
        >
            {formatToReactComponent(trls[TrlKeys.text], {
                '{highlighted}': (
                    <Text typography="title-5-semibold" Element="span" style="warning">
                        {trls[TrlKeys.highlighted]}
                    </Text>
                ),
            })}
        </AdviceCard>
    );
};

export default translation(ManagerUnreadResponses);
