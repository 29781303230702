import { useCallback, useMemo } from 'react';

import employerVacanciesResponsesHiddenResumesButtonClick, {
    HhtmSourceLabel,
} from '@hh.ru/analytics-js-events/build/xhh/employer/experiments/PORTFOLIO-28029/employer_vacancies_responses_hidden_resumes_button_click';
import employerVacanciesResponsesHiddenResumesTagClick from '@hh.ru/analytics-js-events/build/xhh/employer/experiments/PORTFOLIO-28029/employer_vacancies_responses_hidden_resumes_tag_click';
import { Button, Divider, HSpacing, Link, Tag, Text, VSpacing } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { LowActivityVacancy } from 'src/models/employerVacancies/vacancy.types';
import { LowActivityAdviceVacancy } from 'src/models/lowActivityVacancies';
import { MIDDLE_DOT } from 'src/utils/constants/symbols';

import styles from './index.less';

const TrlKeys = {
    parseResponses: 'vacancy.responses.hidden_extended.lowActivityVacancy.parseResponses',
};

interface LowActivityVacancyPromptProps {
    vacancy: LowActivityVacancy | LowActivityAdviceVacancy;
    isLast: boolean;
    isFirst?: boolean;
    hhtmSourceLabel: HhtmSourceLabel;
}

const LowActivityVacancyPrompt: TranslatedComponent<LowActivityVacancyPromptProps> = ({
    trls,
    isLast,
    isFirst,
    hhtmSourceLabel,
    vacancy,
}) => {
    const employerId = useSelector((state) => state.employerId);
    const sourceVacancyId = useSelector((state) => state.router.location.query.vacancyId);
    const vacancyResponsesLink = `/employer/vacancyresponses?vacancyId=${vacancy.vacancyId}`;
    const analyticsCommonProps = useMemo(
        () => ({
            targetVacancyId: vacancy.vacancyId.toString(),
            employerId,
            sourceVacancyId,
            hhtmSourceLabel,
        }),
        [employerId, hhtmSourceLabel, sourceVacancyId, vacancy.vacancyId]
    );
    const handleButtonClick = useCallback(() => {
        employerVacanciesResponsesHiddenResumesButtonClick(analyticsCommonProps);
    }, [analyticsCommonProps]);
    const handleTagClick = useCallback(() => {
        employerVacanciesResponsesHiddenResumesTagClick(analyticsCommonProps);
    }, [analyticsCommonProps]);
    const managerName =
        'employerManager' in vacancy && vacancy.employerManager?.firstName && vacancy.employerManager.lastName
            ? `${vacancy.employerManager.lastName} ${vacancy.employerManager.firstName}`
            : '';

    return (
        <>
            {!isFirst && (
                <>
                    <Divider />
                    <VSpacing default={16} />
                </>
            )}
            <div className={styles.wrapper}>
                <div className={styles.leftColumn}>
                    <Link
                        Element={SPALink}
                        to={`/vacancy/${vacancy.vacancyId}`}
                        typography="subtitle-2-semibold"
                        style="neutral"
                    >
                        <span className={styles.overflowText}>{vacancy.name}</span>
                    </Link>
                    <Text Element="span" typography="label-3-regular" style="secondary">
                        <span className={styles.overflowText}>
                            <span>{managerName || vacancy.typeTrl}</span>
                            {'areaName' in vacancy && (
                                <>
                                    {' '}
                                    {MIDDLE_DOT} {vacancy.areaName}
                                </>
                            )}
                        </span>
                    </Text>
                </div>
                <div className={styles.rightColumn}>
                    <HSpacing default={12} xs={0} />
                    <Link
                        Element={SPALink}
                        to={vacancyResponsesLink}
                        onClick={handleTagClick}
                        typography="label-3-regular"
                    >
                        <span>{vacancy.negotiations.responsesCount}</span>
                        <HSpacing default={12} />
                        <Tag style="positive" size="medium">
                            +{vacancy.negotiations.notProcessedResponsesCount}
                        </Tag>
                    </Link>
                    <HSpacing default={24} />
                    <Button
                        Element={SPALink}
                        size="small"
                        mode="secondary"
                        style="neutral"
                        to={vacancyResponsesLink}
                        onClick={handleButtonClick}
                    >
                        {trls[TrlKeys.parseResponses]}
                    </Button>
                </div>
            </div>
            {!isLast && <VSpacing default={16} />}
        </>
    );
};

export default translation(LowActivityVacancyPrompt);
