import { FC } from 'react';

import advertisingBannerClick from '@hh.ru/analytics-js-events/build/xhh/common/main/advertising_banner_click';

import AdsExternalBanner from 'src/components/Banner/AdsExternalBanner';
import { useSelector } from 'src/hooks/useSelector';
import { BannerPlace } from 'src/models/banners';

interface Props {
    isMobile?: boolean;
}

const WorkInCompanyBanner: FC<Props> = ({ isMobile }) => {
    // tempexp_32927_start
    const isAnonymousMagritteExp = useSelector((state) => state.isAnonymousMagritteExp);
    let bannerPlace = BannerPlace.INDEX_WORK_IN_COMPANY;
    if (isMobile) {
        bannerPlace = BannerPlace.INDEX_WORK_IN_COMPANY_MOBILE;
    }
    if (isAnonymousMagritteExp) {
        bannerPlace = BannerPlace.INDEX_WORK_IN_COMPANY_MAGRITTE_ANONYMOUS;
    }
    // tempexp_32927_end
    const workInCompanyBanners = useSelector((state) => state.banners[bannerPlace]);

    if (!workInCompanyBanners?.[0]) {
        return null;
    }

    return (
        <div className="work-in-company-banner" onClick={() => advertisingBannerClick({ type: 'workInCompanyBanner' })}>
            <AdsExternalBanner {...workInCompanyBanners[0]} />
        </div>
    );
};

export default WorkInCompanyBanner;
