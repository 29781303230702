import { Button, Text } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import paths from 'src/app/routePaths';
import AdviceCard from 'src/components/EmployerAdvicesWidget/AdviceCard';
import { useHandleAnalyticsCardClick } from 'src/components/EmployerAdvicesWidget/useHandleAnalyticsCardClick';
import translation from 'src/components/translation';
import { WaitPaymentAdvice } from 'src/models/employerAdvicesWidget/advices';

const TrlKeys = {
    text: 'employer.index.advicesWidget.waitPayment',
    highlighted: 'employer.index.advicesWidget.waitPayment.highlighted',
    button: 'employer.index.advicesWidget.waitPayment.button',
};

const WaitPayment: TranslatedComponent<WaitPaymentAdvice> = ({ trls, name }) => {
    const sendAnalytics = useHandleAnalyticsCardClick(name);

    return (
        <AdviceCard
            name={name}
            button={
                <Button
                    onClick={sendAnalytics}
                    Element={SPALink}
                    to={paths.employerUnpaidCarts}
                    size="small"
                    stretched
                    mode="secondary"
                    style="neutral"
                >
                    {trls[TrlKeys.button]}
                </Button>
            }
        >
            {formatToReactComponent(trls[TrlKeys.text], {
                '{highlighted}': (
                    <Text typography="title-5-semibold" Element="span" style="warning">
                        {trls[TrlKeys.highlighted]}
                    </Text>
                ),
            })}
        </AdviceCard>
    );
};

export default translation(WaitPayment);
